import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IToast } from '@/types/components';
import { IParamsApi } from '@/types/api/params.api';
import { IResponseApi } from '@/types/api/response.api';

const toast: IToast = {
  hasAction: undefined,
  error: false,
  content: '',
  isOpen: false,
  contentAction: 'undo',
  onAction: () => {},
};

// Define the initial state using that type
const initialState = {
  userSetting: null as IResponseApi.UserSetting | null,
  toast: toast,
  isVisibleBannerActiveDispute: true,
  isVisibleBannerDispute: true,
};

export const commonSettingSlice = createSlice({
  name: 'commonSetting',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleToast: (state, action: PayloadAction<IToast>) => {
      state.toast = action.payload;
    },
    handleUserSetting: (state, action: PayloadAction<IResponseApi.UserSetting>) => {
      state.userSetting = action.payload;
    },
    handleVisibleBannerActiveDispute: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerActiveDispute = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const isVisibleBannerActiveDisputeSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.isVisibleBannerActiveDispute,
);

export const toastSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.toast,
);

export const userSettingSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.userSetting,
);

export default commonSettingSlice;
