import { AlertInline } from '@/components/alertInline';
import CustomCard from '@/components/card';
import Loading from '@/components/loading';
import { COLORS } from '@/constants/colors';
import { CourierRule, Plan } from '@/constants/enum';
import { LINK } from '@/constants/link';
import { handleToastMuatation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isVisibleBannerCourierMappingSelector } from '@/redux/slice/settings.slice';
import { ISettingsPage } from '@/types/components';
import {
  Autocomplete,
  Banner,
  Button,
  ButtonGroup,
  FormLayout,
  Icon,
  Link,
  Modal,
  Select,
  Text,
  TextField,
} from '@shopify/polaris';
import { ArrowRightMinor, DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { CourierMappingStyled } from './styled';
import { userSettingSelector } from '@/redux/slice/commonSetting.slice';

const optionsRule = [
  { label: 'Courier Name', value: CourierRule.NAME },
  { label: 'Tracking number start with', value: CourierRule.START_WITH },
  { label: 'Tracking number end with', value: CourierRule.END_WITH },
];

const CourierMapping = () => {
  const dispatch = useDispatch();
  const isVisibleBanner = useSelector(isVisibleBannerCourierMappingSelector);
  const userSetting = useSelector(userSettingSelector);
  const fetchListPayPalCourier = apiCaller.useFetchCourierMappingQuery(undefined);
  const [deleteMappingById, deleteMappingStatus] = apiCaller.useDeleteCourierMappingMutation();
  const [saveMapping, saveMappingStatus] = apiCaller.useMutationCourierMappingMutation();
  const [state, setState] = useState<ISettingsPage.IStateCourierMappingSetting>({
    listMapping: [],
    id: undefined,
    isOpenModal: false,
    rule: CourierRule.NAME,
    yourCourier: '',
    paypalCourier: '',
    errText: {
      yourCourier: '',
      paypalCourier: '',
    },
    options: {
      yourCourier: [],
      paypalCourier: [],
    },
  });
  useEffect(() => {
    setState({
      ...state,
      listMapping: fetchListPayPalCourier.data?.courierMappingRules || [],
      options: {
        yourCourier: fetchListPayPalCourier.data?.supportedCarries.map((item) => {
          return {
            value: item.carrierName,
            label: item.carrierName,
          };
        }),
        paypalCourier: fetchListPayPalCourier.data?.supportedCarries.map((item) => {
          return {
            value: item.carrierName,
            label: item.carrierName,
          };
        }),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchListPayPalCourier]);

  const handleSaveMapping = () => {
    saveMapping({
      type: state.rule,
      ruleValue: state.yourCourier,
      paypalCarrier: state.paypalCourier,
      id: state.id,
    }).then((res) => {
      setState({ ...state, isOpenModal: false });
      dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
    });
  };

  const handleAddMappingMessage = () => {
    if (fetchListPayPalCourier.data && userSetting) {
      const plan = userSetting.plan;
      if (plan === Plan.FREE) {
        return 'This function is only available when using Basic plan or higher';
      }
      if (plan === Plan.BASIC && fetchListPayPalCourier.data.userCarriers.length > 1) {
        return 'Basic plan can only mapping up to 2 couriers. If you want to mapping more courrier.';
      }
      if (plan === Plan.PRO && fetchListPayPalCourier.data.userCarriers.length > 9) {
        return 'Pro plan can only mapping up to 10 couriers.  If you want to mapping more courrier.';
      }
    }
  };

  const addMapping = () => {
    setState({ ...state, isOpenModal: true, paypalCourier: '', yourCourier: '', rule: CourierRule.NAME, id: undefined });
  };

  const editMapping = (id: number) => {
    const courierSelected = state.listMapping.find((item) => item.id === id);
    if (courierSelected) {
      setState({
        ...state,
        isOpenModal: true,
        paypalCourier: courierSelected.paypalCarrier,
        yourCourier: courierSelected.ruleValue,
        rule: courierSelected.type,
        id,
      });
    }
  };

  const deleteMapping = (id: number) => {
    setState({ ...state, id });
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'This mapping will be permanently deleted',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: COLORS.primary,
    }).then((result) => {
      if (result.value) {
        deleteMappingById(id).then((res) => {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
        });
        setState({ ...state, id: undefined });
      }
    });
  };

  const handleCourier = useCallback(
    (type: 'yourCourier' | 'paypalCourier') => (value: string) => {
      const resultOptions = fetchListPayPalCourier.data?.supportedCarries?.filter((option) => option.carrierName.includes(value));
      setState({
        ...state,
        [type]: value,
        options: {
          ...state.options,
          [type]: resultOptions,
        },
      });
    },
    [fetchListPayPalCourier.data?.supportedCarries, state],
  );

  const updateSelection = useCallback(
    (type: 'yourCourier' | 'paypalCourier') => (selected: string[]) => {
      setState({
        ...state,
        [type]: selected[0],
      });
    },
    [state],
  );

  const yourCourierTextField = (
    <Autocomplete.TextField
      onChange={handleCourier('yourCourier')}
      value={state.yourCourier}
      label="Your Courier Name"
      error={state.errText.yourCourier}
      helpText={
        state.rule === CourierRule.NAME
          ? 'Example: Yunexpress'
          : state.rule === CourierRule.START_WITH
          ? 'Example: 1ZA'
          : 'Example: HK'
      }
      autoComplete="off"
    />
  );
  const paypalTextField = (
    <Autocomplete.TextField
      onChange={handleCourier('paypalCourier')}
      value={state.paypalCourier}
      label="PayPal Courier"
      error={state.errText.paypalCourier}
      helpText="Example: UPS"
      autoComplete="off"
    />
  );
  return (
    <CourierMappingStyled>
      {fetchListPayPalCourier.isLoading && <Loading />}
      {isVisibleBanner && (
        <Banner
          onDismiss={() => {
            dispatch(slice.settingsPageSlice.actions.handleVisibleBannerCourierMapping(false));
          }}
        >
          <Text as="h4" variant="bodyMd">
            The process of converting courier’s names from Shopify to PayPal-supported couriers is fully automated.
          </Text>
          <Text as="h4" variant="bodyMd">
            However, if you want to configure them yourself, you can add customized converting rules with the function below.
            <Link external url={LINK.guideAddMappingCourier}>
              View instruction
            </Link>
          </Text>
        </Banner>
      )}

      <div className="mt-16">
        <CustomCard
          title="Courier mapping rules"
          actions={{
            content: 'Add mapping rules',
            action: () => addMapping(),
            buttonProps: {
              primary: true,
              disabled:
                userSetting?.plan === Plan.FREE ||
                (userSetting?.plan === Plan.BASIC &&
                  fetchListPayPalCourier.data &&
                  fetchListPayPalCourier.data.userCarriers.length > 1) ||
                (userSetting?.plan === Plan.PRO &&
                  fetchListPayPalCourier.data &&
                  fetchListPayPalCourier.data.userCarriers.length > 9),
            },
          }}
        >
          {userSetting?.plan === Plan.FREE ||
          (userSetting?.plan === Plan.BASIC &&
            fetchListPayPalCourier.data &&
            fetchListPayPalCourier.data.userCarriers.length > 1) ||
          (userSetting?.plan === Plan.PRO &&
            fetchListPayPalCourier.data &&
            fetchListPayPalCourier.data.userCarriers.length > 9) ? (
            <div className="mb-16">
              <AlertInline content={handleAddMappingMessage() || ''} link="Upgrade higher Plan" />
            </div>
          ) : null}
          <div>
            <div className="w-100 ml-5">
              <FormLayout>
                <FormLayout.Group condensed>
                  <Text as="h5" variant="headingSm">
                    Mapping Type
                  </Text>
                  <Text as="h5" variant="headingSm">
                    Input
                  </Text>
                  <div />
                  <Text as="h5" variant="headingSm">
                    PayPal Courier
                  </Text>
                  <div />
                </FormLayout.Group>
                {state.listMapping.length > 0 &&
                  state.listMapping.map((item, index) => {
                    return (
                      <FormLayout.Group condensed key={index}>
                        {item.type === 'name'
                          ? 'Courier name'
                          : item.type === 'start'
                          ? 'Tracking number start with'
                          : 'Tracking number end with'}
                        {item.ruleValue}
                        <Icon source={ArrowRightMinor} />
                        {item.paypalCarrier}
                        <ButtonGroup>
                          <Button
                            onClick={() => editMapping(item.id)}
                            icon={() => {
                              return <Icon source={EditMinor} />;
                            }}
                          />
                          <Button
                            onClick={() => deleteMapping(item.id)}
                            loading={deleteMappingStatus.isLoading}
                            icon={() => {
                              return <Icon source={DeleteMinor} />;
                            }}
                          />
                        </ButtonGroup>
                      </FormLayout.Group>
                    );
                  })}
              </FormLayout>
            </div>
            <Modal
              open={state.isOpenModal}
              onClose={() => setState({ ...state, isOpenModal: false })}
              title="Courier Mapping"
              primaryAction={{
                content: 'Save',
                onAction: handleSaveMapping,
                loading: saveMappingStatus.isLoading,
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: () => setState({ ...state, isOpenModal: false }),
                },
              ]}
            >
              <Modal.Section>
                <Select
                  label="Mapping type"
                  options={optionsRule}
                  onChange={(value: CourierRule) => setState({ ...state, rule: value })}
                  value={state.rule}
                />
                {state.rule === CourierRule.NAME ? (
                  <Autocomplete
                    options={state.options.yourCourier || []}
                    selected={[state.yourCourier]}
                    onSelect={updateSelection('yourCourier')}
                    textField={yourCourierTextField}
                  />
                ) : (
                  <TextField
                    label={state.rule === CourierRule.START_WITH ? 'Tracking number start with' : 'Tracking number end with'}
                    value={state.yourCourier}
                    onChange={(value) => {
                      setState({ ...state, yourCourier: value });
                    }}
                    helpText={state.rule === CourierRule.START_WITH ? 'Example: 1ZA' : 'Example: HK'}
                    error={state.errText.yourCourier}
                    autoComplete="off"
                  />
                )}
                <Autocomplete
                  options={state.options.paypalCourier || []}
                  selected={[state.paypalCourier]}
                  onSelect={updateSelection('paypalCourier')}
                  textField={paypalTextField}
                />
              </Modal.Section>
            </Modal>
          </div>
        </CustomCard>
      </div>
    </CourierMappingStyled>
  );
};

export default CourierMapping;
