import config from '@/config';
import { PATH } from '@/constants/path';
import { IMenuComponent } from '@/types/components';
import { ActionList, Button, ButtonGroup, Popover } from '@shopify/polaris';
import {
  ChatMajor,
  NoteMinor,
  OnlineStoreMinor,
  OrderStatusMinor,
  QuestionMarkMinor,
  ReportMinor,
  SettingsMinor,
} from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';
import { slide as MenuNav } from 'react-burger-menu';
import { hotjar } from 'react-hotjar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HelpCenter from './components/HelpCenter/helpCenter/HelpCenter';
import { MenuStyled } from './styled';
import { useSelector } from 'react-redux';
import { userSettingSelector } from '@/redux/slice/commonSetting.slice';

const menu: Array<IMenuComponent.IMenuItem> = [
  {
    to: PATH.HOME,
    label: 'Dashboard',
    icon: ReportMinor,
    activeKey: [PATH.HOME.pathname, PATH.DEFAULT.pathname],
  },
  {
    to: PATH.ORDERS,
    label: 'Orders',
    icon: OrderStatusMinor,
    activeKey: [PATH.ORDERS.pathname],
  },
  // {
  //   to: PATH.DISPUTE,
  //   label: 'Dispute',
  //   icon: DisputeMinor,
  //   activeKey: [PATH.DISPUTE.pathname],
  // },
  {
    to: PATH.SETTINGS,
    label: 'Settings',
    icon: SettingsMinor,
    activeKey: [PATH.SETTINGS.pathname],
  },
  {
    label: 'Account plan',
    icon: OnlineStoreMinor,
  },
  {
    label: 'Reviews',
    icon: ChatMajor,
  },
  // {
  //   label: 'Help center',
  //   icon: QuestionMarkMinor,
  //   activeKey: [],
  // },
  // {
  //   to: PATH.AFFILIATE,
  //   label: 'Affiliate',
  //   icon: FavoriteMajor,
  //   activeKey: [PATH.AFFILIATE.pathname],
  // },
];

const Menu = () => {
  const { pathname } = useLocation();
  const userSetting = useSelector(userSettingSelector);
  const [state, setState] = useState<IMenuComponent.IState>({ isMenuMobileOpen: false, openFaqs: false, popoverActive: false });
  const navigate = useNavigate();
  //   const dataSettings = useSelector((state) => state.dataSettings);
  const MenuLinkMobile = ({ to, label }: IMenuComponent.IMenuItem) => {
    const handleClick = useCallback(() => {
      if (label === 'Account plan') {
        const link = `https://www.wix.com/apps/upgrade/${process.env.REACT_APP_APP_ID}?appInstanceId=${userSetting?.instanceId}`;
        window.open(link, '_blank');
      }
      if (label === 'Reviews') {
        const link = `https://www.wix.com/app-market/add-review/${process.env.REACT_APP_APP_ID}`;
        window.open(link, '_blank');
      }
      setState({
        ...state,
        isMenuMobileOpen: false,
      });
      hotjar.stateChange(`${to?.pathname}?${config.instanceId}`);
      navigate({ ...to });
    }, [label, to]);
    return (
      <Link onClick={handleClick} to={to ? to : ''}>
        {label}
      </Link>
    );
  };
  const MenuLink = ({ to, label, icon, activeKey }: IMenuComponent.IMenuItem) => {
    const navigate = useNavigate();
    const handleClick = () => {
      if (label === 'Account plan') {
        const link = `https://www.wix.com/apps/upgrade/${process.env.REACT_APP_APP_ID}?appInstanceId=${userSetting?.instanceId}`;
        window.open(link, '_blank');
      }
      if (label === 'Reviews') {
        const link = `https://www.wix.com/app-market/add-review/${process.env.REACT_APP_APP_ID}`;
        window.open(link, '_blank');
      }
      hotjar.stateChange(`${to?.pathname}?${config.instanceId}`);
      if (to) {
        navigate(
          { ...to },
          {
            state: {
              prePathName: pathname,
            },
          },
        );
      }

      setState({ ...state, popoverActive: false });
    };

    if (label === 'Help center') {
      return (
        <Popover active={state.popoverActive} activator={activator} autofocusTarget="first-node" onClose={togglePopoverActive}>
          <ActionList
            actionRole="menuitem"
            items={[
              {
                content: 'Document',
                icon: NoteMinor,
                url: 'https://docs.synctrack.io/',
                external: true,
              },
              {
                content: 'FAQs',
                icon: QuestionMarkMinor,
                onAction: () => setState({ ...state, openFaqs: !state.openFaqs }),
              },
            ]}
          />
        </Popover>
      );
    } else {
      return (
        <Button onClick={handleClick} icon={icon} primary={!!activeKey?.find((item) => item === pathname)}>
          {label}
        </Button>
      );
    }
  };

  const togglePopoverActive = useCallback(() => setState({ ...state, popoverActive: !state.popoverActive }), [state]);
  const activator = (
    <Button icon={QuestionMarkMinor} onClick={togglePopoverActive} disclosure>
      Help Center
    </Button>
  );
  return (
    <MenuStyled className="topnav-wrap p-3">
      <div className="desktop-nav">
        <ButtonGroup segmented>
          {menu.map((item) => {
            return <MenuLink activeKey={item.activeKey} key={item.label} to={item.to} label={item.label} icon={item.icon} />;
          })}
        </ButtonGroup>
      </div>
      <div className="mobile-nav">
        <MenuNav right={false} isOpen={state.isMenuMobileOpen}>
          {menu.map((item) => {
            return (
              <MenuLinkMobile activeKey={item.activeKey} to={item.to} label={item.label} icon={item.icon} key={item.label} />
            );
          })}
        </MenuNav>
      </div>
      <HelpCenter isOpenFaqs={state.openFaqs} handleOpenFaqs={() => setState({ ...state, openFaqs: false })} />
    </MenuStyled>
  );
};

export default Menu;
