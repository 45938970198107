export enum Plan {
  FREE = 'free',
  BASIC = 'basic',
  PRO = 'pro',
  PRO_PLUS = 'pro_plus',
  UNLIMITED = 'unlimited',
}

export enum OfferType {
  REFUND = 'REFUND',
  REFUND_WITH_RETURN = 'REFUND_WITH_RETURN',
  REFUND_WITH_REPLACEMENT = 'REFUND_WITH_REPLACEMENT',
  REPLACEMENT_WITHOUT_REFUND = 'REPLACEMENT_WITHOUT_REFUND',
}

export enum CountryCodePaypalRequired {
  REQUIRED = 1,
  OPTIONAL = 2,
  NOT_USE = 3,
}

export enum EvidenceType {
  FULFILLMENT = 'PROOF_OF_FULFILLMENT',
  REFUND = 'PROOF_OF_REFUND',
  OTHER = 'OTHER',
}

export enum BREAKPOINT {
  XS = 0,
  SM = 600,
  MD = 1040,
  LG = 1800,
  XL = 1920,
}

export enum DisputeStage {
  INQUIRY = 'INQUIRY',
  CHARGEBACK = 'CHARGEBACK',
  PRE_ARBITRATION = 'PRE_ARBITRATION',
  ARBITRATION = 'ARBITRATION',
}
export enum DisputeStateEnum {
  OPEN_INQUIRIES = 'OPEN_INQUIRIES',
  REQUIRED_ACTION = 'REQUIRED_ACTION',
  REQUIRED_OTHER_PARTY_ACTION = 'REQUIRED_OTHER_PARTY_ACTION',
  UNDER_PAYPAL_REVIEW = 'UNDER_PAYPAL_REVIEW',
  APPEALABLE = 'APPEALABLE',
  RESOLVED = 'RESOLVED',
}
export enum OptionsOfferType {
  OPEN_INQUIRIES = 'OPEN_INQUIRIES',
  REQUIRED_ACTION = 'REQUIRED_ACTION',
  REQUIRED_OTHER_PARTY_ACTION = 'REQUIRED_OTHER_PARTY_ACTION',
  UNDER_PAYPAL_REVIEW = 'UNDER_PAYPAL_REVIEW',
  APPEALABLE = 'APPEALABLE',
  RESOLVED = 'RESOLVED',
}

export enum NotifycationType {
  ACCOUNT_NOT_FOUND = 1,
  NEW_DISPUTE = 2,
  SYNC_ERROR = 3,
  NEW_VERSION = 4,
  NEW_FUNCTION = 5,
  PAYMENT_ACCOUNT_NOT_FOUND = 6,
  ORDER_PENDING = 7,
  INVALID_TRACKING_NUMBER = 8,
  OVER_QUOTA = 9,
  NO_TRANSACTION_ID = 10,
}

export enum CourierRule {
  NAME = 'name',
  START_WITH = 'start',
  END_WITH = 'end',
}

export enum CourierIgnoreRule {
  COURIER = 'courier',
  COURIER_START = 'courier_start',
  TRACKING = 'tracking',
  TRACKING_START = 'tracking_start',
}

export enum EmailConsentValue {
  ST_UPDATE = 'st_update',
  REPORT = 'report',
  PROMO = 'promo',
  PAYMENT_TIPS = 'payment_tips',
}

export enum EmailConsentPoisition {
  WELCOME = 'consent_welcome',
  OLD_ORDER = 'consent_old_order',
  SYNCED_ORDER = 'consent_synced_order',
}

export enum OrderStatusMapping {
  OtherGateWays = -2,
  NoTracking = -1,
  Pending = 0,
  Complete = 1,
  OverQuota = 2,
  NoInvalidAccount = 3,
  NoTransaction = 4,
  InvalidTracking = 5,
  MissingPaypalAccount = 6,
}
