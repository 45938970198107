import { AffiliateStyled } from './styled';
import TopBanner from './components/TopBanner';
import { memo } from 'react';
import Reasons from './components/Reasons';
import BottomBanner from './components/BottomBanner';

const AffiliateProgram = () => {
    return (
        <AffiliateStyled>
            <TopBanner />
            <Reasons />
            <BottomBanner />
        </AffiliateStyled>
    );
};

export default memo(AffiliateProgram);