import  styled  from 'styled-components';
export const StatisticalCardStyled = styled.div`
        position: relative;
        display: flex;
        .Polaris-Box {
            width: 100%;
        }
        .statistical-layout {
            display: flex;
            .quick-help-button {
                position: absolute;
                right: 30px;
                bottom: 20px;
                cursor: pointer;
                small {
                    text-decoration: underline;
                    opacity: 0.7;
                }
            }
            .statistical-layout-icon {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: #aee9d1;
                display: flex;
                align-items: center;
                margin: 0px 20px 0px 0px !important;
            }
            .title {
                font-size: 14px;
            }
            .value {
                font-size: 28px;
                line-height: 20px;
                margin: 0px;
                margin-top: 12px;
            }
        }
`