import { Spinner } from '@shopify/polaris';
import { memo } from 'react';
import { LoadingStyled } from './styled';
const Loading = (): JSX.Element => {
  return (
    <LoadingStyled id="i_loading_page">
      <div id="i_loading_page_2">
        <Spinner size="large" />
      </div>
    </LoadingStyled>
  );
};

export default memo(Loading);
