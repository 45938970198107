/* eslint-disable no-duplicate-case */
import CustomCard from '@/components/card';
import { handlePlanAndOrder } from '@/helpers';
import { Badge, Button, Columns } from '@shopify/polaris';
import { CredentialsStyled } from './styled';
import { userSettingSelector } from '@/redux/slice/commonSetting.slice';
import { useSelector } from 'react-redux';

interface Props {
  plan: string;
  stripeToken: number;
  paypalToken: number;
  subscription: string;
  syncStripe: boolean;
  quota: string;
  usage: number;
}

const Credentials = ({ ...props }: Props) => {
  const userSetting = useSelector(userSettingSelector);
  const getPlanAndOrder = handlePlanAndOrder(props.plan);
  const handleViewPlan = () => {
    const link = `https://www.wix.com/apps/upgrade/${process.env.REACT_APP_APP_ID}?appInstanceId=${userSetting?.instanceId}`;
    window.open(link, '_blank');
  };
  return (
    <CredentialsStyled className="mt-15 app-credentials">
      <Columns columns={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} gap={{ xl: '4', lg: '4', md: '4', sm: '4', xs: '4' }}>
        <CustomCard display="flex" title="Account Status">
          <div className="plan-card-content">
            <table className="w-100 app_instructions_page_add_user_table appStatusTable m-auto">
              <tbody>
                <tr>
                  <td>Your plan:</td>
                  <td className="right">
                    <strong>{getPlanAndOrder.plan}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Payment subscription:</td>
                  <td className="right">
                    <strong
                      style={{
                        textTransform: 'capitalize',
                      }}
                    >
                      {props.subscription || 'Monthly'}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>Paypal Integration:</td>
                  <td className="right account-integrated">
                    <Badge status={props.paypalToken === 0 ? 'critical' : 'success'} progress="complete">
                      {`${props.paypalToken > 1 ? ' Accounts' : ' Account'} Integrated`}
                    </Badge>
                  </td>
                </tr>
                {props.syncStripe ? (
                  <tr>
                    <td>Stripe Integration:</td>
                    <td className="right account-integrated">
                      <Badge status={props.stripeToken > 0 ? 'success' : 'critical'} progress="complete">
                        {props.stripeToken > 0 ? props.stripeToken + ' Token Connected' : 'Token Not added Yet'}
                      </Badge>
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td>The number of orders synced:</td>
                  <td className="right">
                    <strong>{props.quota} orders </strong>
                    per/month
                  </td>
                </tr>
                <tr>
                  <td>Used this month: </td>
                  <td className="right">
                    <strong>
                      {props.usage}/{props.quota} orders{' '}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <div>
              <Button primary onClick={handleViewPlan}>
                View all plans
              </Button>
            </div>
          </div>
        </CustomCard>
      </Columns>
    </CredentialsStyled>
  );
};

export default Credentials;
