import { PATH } from '@/constants/path';
import slice from '@/redux/slice';
import { orderTableOrderPageSelector } from '@/redux/slice/orderPage.slice';
import { IHomePage } from '@/types/components';
import { AlphaCard } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Progress } from 'reactstrap';

const TrackingStatusCardItem = ({ ...props }: IHomePage.ITrackingStatusCardItemProps): JSX.Element | null => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderTableOrderPage = useSelector(orderTableOrderPageSelector);
  const handleIndicatorFilter = () => {
    navigate(PATH.ORDERS);
    dispatch(
      slice.orderPageSlice.actions.handleFiltersOrderTable({
        ...orderTableOrderPage,
        paymentSelected: [props.passDataToOrders.payment],
        statusSelected: [props.passDataToOrders.status],
      }),
    );
  };
  return props.orderNumber > 0 ? (
    <AlphaCard padding={{ xl: '0' }}>
      <div className="card-body" onClick={handleIndicatorFilter}>
        <div>
          <div className="tracking-status-order">
            {props.orderNumber}
            <small className="fs-14 ml-10">{props.orderNumber > 1 ? 'orders' : 'order'}</small>
          </div>
          <small className="tracking-status-des">{props.description}</small>
        </div>
        <Progress className="progress-xs progress-white mt-3 mb-0" value={props.valueProgress} color={props.color} />
      </div>
    </AlphaCard>
  ) : null;
};

export default memo(TrackingStatusCardItem);
