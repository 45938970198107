import { PATH } from '@/constants/path';
import Home from '@/pages/home';
import OrderPage from '@/pages/order';
import Settings from '@/pages/settings';
import Welcome from '@/pages/welcome';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import Layout from '@/components/layout';
import HandleRequest from '@/helpers/HandlePaypalConnected';
import Affiliate from '@/pages/affiliate';
import WhatNew from '@/pages/whatNew';
interface IRouter {
  path: string;
  element: React.ReactNode;
}

const router: Array<IRouter> = [
  {
    path: PATH.DEFAULT.pathname,
    element: (
      <Layout title="Synctrack">
        <Home />
      </Layout>
    ),
  },
  {
    path: PATH.HOME.pathname,
    element: (
      <Layout title="Synctrack">
        <Home />
      </Layout>
    ),
  },
  {
    path: PATH.ORDERS.pathname,
    element: (
      <Layout title="Orders">
        <OrderPage />
      </Layout>
    ),
  },
  {
    path: PATH.SETTINGS.pathname,
    element: (
      <Layout title="Settings">
        <Settings />
      </Layout>
    ),
  },
  {
    path: PATH.AFFILIATE.pathname,
    element: (
      <Layout title="Affiliate">
        <Affiliate />
      </Layout>
    ),
  },
  {
    path: PATH.WELCOME.pathname,
    element: <Welcome />,
  },
  {
    path: PATH.WHATNEW.pathname,

    element: (
      <Layout title="WhatNew">
        <WhatNew />
      </Layout>
    ),
  },
  {
    path: PATH.HANDLE_REQUEST.pathname,
    element: <HandleRequest />,
  },
];
function RenderRouter(): JSX.Element {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <Routes>
      {router.map((item: IRouter) => (
        <Route key={item.path} path={item.path} element={item.element} />
      ))}
      <Route path="*" element={<Navigate to={PATH.HOME} replace />} />
    </Routes>
  );
}
export default RenderRouter;
