import styled from "styled-components";

export const CsvStyled = styled.div`
font-size: 14px;
position: relative;
.csvlink-btn {
    position: absolute;
    top: 4px;
    right: 4px;
}
.react-csv-input {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.csv-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 15px 0px;
}
`