import slice from '@/redux/slice';
import { orderTableOrderPageSelector } from '@/redux/slice/orderPage.slice';
import { IStatisticalCard } from '@/types/components';
import { AlphaCard, Icon } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StatisticalCardStyled } from './styled';
import { hotjar } from 'react-hotjar';
import config from '@/config';
const StatisticalCard = ({ ...props }: IStatisticalCard.IProps) => {
  const getOrderTable = useSelector(orderTableOrderPageSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigateToOrders = () => {
    hotjar.stateChange(`${props.to.pathname}?${config.instanceId}`);
    navigate({ ...props.to });
    dispatch(
      slice.orderPageSlice.actions.handleFiltersOrderTable({
        ...getOrderTable,
        paymentSelected: [props.passDataToOrders.payment],
        statusSelected: [props.passDataToOrders.status],
      }),
    );
  };
  const handleOpenQuickHelp = () => {
    dispatch(slice.homePageSlice.actions.handleOpenQuickHelp(true));
  };
  return (
    <StatisticalCardStyled>
      <AlphaCard>
        <div className="statistical-layout text-center">
          <div className="statistical-layout-icon">
            <Icon color="primary" source={props.icon} />
          </div>
          <div className="statistical-layout-text">
            <b className="title" onClick={handleNavigateToOrders}>
              {props.title}
            </b>
            <p className="value">{props.value}</p>
          </div>
          {props.title === 'Total Sync Error' && Number(props.value) > 0 && (
            <div className="quick-help-button" onClick={handleOpenQuickHelp}>
              <div>
                <Icon source={AlertMinor} color="critical" />
                <small>Quick help!</small>
              </div>
            </div>
          )}
        </div>
      </AlphaCard>
    </StatisticalCardStyled>
  );
};

export default memo(StatisticalCard);
