const linkStripe = 'https://docs.synctrack.io/feature-guides/how-to-create-stripe-token-to-integrate-synctrack-with-stripe';
const stripeDocument = 'https://docs.synctrack.io/feature-guides/how-to-create-stripe-token-to-integrate-synctrack-with-stripe';
const multiStoreGuide =
  'https://docs.synctrack.io/feature-guides/how-to-set-up-and-what-you-should-know-about-multi-store-feature#how-to-set-up-multi-store-connection';
const shoffiLogin = 'https://platform.shoffi.app/login';
const shoffiAffilate = 'https://platform.shoffi.app/signup/affiliate?app=Il_VwAn8snq';
const shoffiFaq = 'https://docs.synctrack.io/affiliate/faqs';
const syncStatus =
  'https://docs.synctrack.io/feature-guides/how-to-check-sync-status-add-tracking-auto-app#payment-filter-the-payment-you-would-like-to-show-the-order';
const connectMultiStoreVideo = 'https://www.youtube.com/watch?v=pokFbQ3YvHc';
const syntrackHomePage = 'https://docs.synctrack.io/';
const getStripeToken = 'https://docs.synctrack.io/feature-guides/how-to-create-stripe-token-to-integrate-synctrack-with-stripe';
const addTrackingNumberToOrder = 'https://help.shopify.com/en/manual/orders/status-tracking/add-tracking-numbers';
const guideAddMappingCourier = 'https://docs.synctrack.io/feature-guides/how-to-add-courier-mapping-rule';
const getDisputeAdmin = 'https://docs.synctrack.io/feature-guides/how-to-get-dispute-administration';
const guideShopifyOrderNotes = 'https://docs.synctrack.io/feature-guides/sync-info-to-shopify-order-note';
const introduceDispute =
  'https://www.facebook.com/permalink.php?story_fbid=pfbid02QLVSXhtB8i69DPaoZheG7kgVL57vuu2X2LoxpGr6KPW5YbpXk9f9rATGaf6BJ7eLl&id=100083215147793';
const paypalHomePage = 'https://developer.paypal.com/home/';
const paypalCredentials = 'https://developer.paypal.com/dashboard/applications/live';
const paypalCreateApp = 'https://developer.paypal.com/dashboard/applications/live/create';
export const LINK = {
  linkStripe,
  stripeDocument,
  multiStoreGuide,
  shoffiLogin,
  shoffiAffilate,
  shoffiFaq,
  syncStatus,
  connectMultiStoreVideo,
  syntrackHomePage,
  getStripeToken,
  addTrackingNumberToOrder,
  guideAddMappingCourier,
  getDisputeAdmin,
  guideShopifyOrderNotes,
  introduceDispute,
  paypalHomePage,
  paypalCredentials,
  paypalCreateApp,
};
