import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { orderTableOrderPageSelector, searchInputSelector, titleBtnDatePickerSelector } from '@/redux/slice/orderPage.slice';
import { IOrderTable } from '@/types/components';
import { ChoiceList, Filters } from '@shopify/polaris';
import _debounce from 'lodash/debounce';
import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDatePicker from '../datePicker';
import { userSettingSelector } from '@/redux/slice/commonSetting.slice';
const optionsPerPage = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '75', value: '75' },
  { label: '100', value: '100' },
];

const optionsStatus = [
  { label: 'Completed', value: '1' },
  { label: 'Pending', value: '0' },
  { label: 'Over quota', value: '2' },
  { label: 'No Account OR Invalid', value: '3' },
  { label: 'No Tracking number', value: '-1' },
  { label: 'No transaction id', value: '4' },
  { label: 'Missing PayPal account', value: '6' },
  { label: 'Invalid tracking number', value: '5' },
  { label: 'Error', value: 'total_err' },
  { label: 'All', value: 'all' },
];
const optionsPayment = [
  { label: 'Paypal', value: 'paypal' },
  { label: 'Stripe', value: 'stripe' },
  { label: 'Paypal & stripe', value: 'paypal_stripe' },
  { label: 'Other', value: 'other' },
  { label: 'All', value: 'all' },
];

const getLabelByValue = (options: Array<{ label: string; value: string }>, value: string) =>
  options.find((option) => option.value === value)?.label;

const OrderTableFilter = () => {
  const searchInput = useSelector(searchInputSelector);
  const userSetting = useSelector(userSettingSelector);
  const getOrderTable = useSelector(orderTableOrderPageSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  useEffect(() => {
    dispatch(
      slice.orderPageSlice.actions.handleFiltersOrderTable({
        ...getOrderTable,
        paymentSelected:
          userSetting?.syncStripe && getOrderTable.paymentSelected[0] === 'paypal'
            ? ['paypal_stripe']
            : getOrderTable.paymentSelected,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useDispatch();
  const handleOrderTable = (key: string) => (value: any) => {
    if (value.length !== 0) {
      dispatch(
        slice.orderPageSlice.actions.handleFiltersOrderTable({
          ...getOrderTable,
          [key]: value,
          currentPage: 1,
        }),
      );
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearch = useCallback(
    _debounce((value: IOrderTable.IState) => {
      return dispatch(slice.orderPageSlice.actions.handleFiltersOrderTable(value));
    }, 1000),
    [],
  );
  const onSaveDatePicker = (fromDate: Date, toDate: Date) => {
    dispatch(slice.orderPageSlice.actions.handleFiltersOrderTable({ ...getOrderTable, fromDate, toDate }));
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(slice.orderPageSlice.actions.handleTitleBtnDatePicker(title));
  };
  const filters = [
    {
      key: 'status',
      label: 'Sync status',
      filter: (
        <ChoiceList
          title="Sync status"
          titleHidden
          choices={optionsStatus}
          selected={getOrderTable.statusSelected}
          onChange={handleOrderTable('statusSelected')}
        />
      ),
      shortcut: true,
    },
    {
      key: 'payment',
      label: 'Payment',
      filter: (
        <ChoiceList
          title="Payment"
          titleHidden
          choices={optionsPayment}
          selected={getOrderTable.paymentSelected}
          onChange={handleOrderTable('paymentSelected')}
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={optionsPerPage}
          selected={getOrderTable.perPage}
          onChange={handleOrderTable('perPage')}
        />
      ),
      shortcut: true,
    },
  ];
  const appliedFilters = [
    {
      onRemove: () => handleOrderTable('paymentSelected')(['paypal']),
      key: 'payment',
      label: 'Payment: ' + getLabelByValue(optionsPayment, getOrderTable.paymentSelected[0]),
    },
    {
      onRemove: () => handleOrderTable('statusSelected')(['all']),
      key: 'status',
      label: 'Sync status: ' + getLabelByValue(optionsStatus, getOrderTable.statusSelected[0]),
    },
    {
      onRemove: () => handleOrderTable('perPage')(['25']),
      key: 'perPage',
      label: 'Record per page: ' + getLabelByValue(optionsPerPage, getOrderTable.perPage[0]),
    },
  ];
  return (
    <Filters
      appliedFilters={appliedFilters}
      queryValue={searchInput}
      filters={filters}
      onQueryChange={(value) => {
        const phoneRegEx = new RegExp('^[a-zA-Z0-9_.#.-]*$');
        if (!phoneRegEx.test(value)) {
          dispatch(
            slice.commonSettingSlice.actions.handleToast({
              isOpen: true,
              content: 'Incorrect format',
              error: true,
            }),
          );
        } else {
          dispatch(slice.orderPageSlice.actions.handleSearchInput(value));
          debounceSetSearch({
            ...getOrderTable,
            search: value,
            currentPage: 1,
          });
        }
      }}
      onQueryClear={() => {
        dispatch(slice.orderPageSlice.actions.handleSearchInput(''));
        debounceSetSearch({
          ...getOrderTable,
          search: '',
          currentPage: 1,
        });
      }}
      queryPlaceholder="Order name/Tracking number/Transaction ID"
      onClearAll={() => {}}
    >
      <CustomDatePicker
        titleBtn={titleBtn}
        setTitleBtn={onSaveTitleBtnDatePicker}
        onSave={onSaveDatePicker}
        start={getOrderTable.fromDate}
        end={getOrderTable.toDate}
      />
    </Filters>
  );
};

export default memo(OrderTableFilter);
