import { COLORS } from "@/constants/colors";
import styled from "styled-components";


export const TrackingStatusStyled = styled.div`
.mb-30 {
    margin-bottom : 30px
}
.tracking-status-des {
    font-size: 14px;
    color : #6c757d;
    font-weight: bold;
    &:hover {
        color : ${COLORS.primary}
    }
}
.tracking-status-order {
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: 400;
}
.fs-14 {
    font-size: 14px;
}
.ml-10 {
    margin-left: 8px;
}
.progress-xs {
    height: 6px !important;
}
.card-body{
    height: 100%;
    display : flex;
    flex-direction : column;
    justify-content : space-between;
  cursor: pointer;
  .tracking-status-order:hover {
  color:rgba(0, 128, 96, 1);
  }
}
`