import config from '@/config';
import { handleToastMuatation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { IOldOrderBanner } from '@/types/components';
import { AlphaCard, Button, Icon, Modal, TextField } from '@shopify/polaris';
import { CircleInformationMajor, ExchangeMajor, ReplayMinor } from '@shopify/polaris-icons';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonGroup } from 'reactstrap';
import { OldOrderBannerStyled } from './styled';
const OldOrderBanner = ({ ...props }: IOldOrderBanner.IProps): JSX.Element => {
  const dispatch = useDispatch();
  const [state, setState] = useState<IOldOrderBanner.IState>({
    isOpenModal: false,
    oldOrderRange: '30',
    validateMessage: '',
    submitDemo: 0,
    isOpenSubmitDemo: false,
  });
  const [fetchOldOrder, oldOrderStatus] = apiCaller.useProcessOldOrderMutation();
  const openModalGetOldStatus = () => {
    if (config.role !== 'view') {
      setState({
        ...state,
        isOpenModal: true,
      });
    }
  };
  const handleChangeOldOrderRange = async (value: string) => {
    if (value === '') {
      setState({
        ...state,
        validateMessage: 'Date range is required',
        oldOrderRange: value,
      });
    } else {
      const phoneRegEx = new RegExp('^[1-9]');
      if (!phoneRegEx.test(value)) {
        value = '0';
      }
      handleMaxOldOrderRange(Number(value));
    }
  };
  const handleGetOldOrder = () => {
    fetchOldOrder(Number(state.oldOrderRange)).then((res) => {
      dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
      setState({
        ...state,
        isOpenModal: false,
      });
    });
  };

  const handleMaxOldOrderRange = (value: number) => {
    let result = value.toString();
    if (value > 365) {
      result = '365';
    }
    setState({ ...state, oldOrderRange: result, validateMessage: '' });
  };

  return (
    <OldOrderBannerStyled className="w-100 ml-3 mr-3">
      {
        <AlphaCard>
          <div className="card-container">
            <div className="noti-content">
              <Icon source={CircleInformationMajor} color="highlight" />
              <div>
                Orders will be <strong>automatically synced</strong> to PayPal as soon as you mark the order as{' '}
                <strong>fullfilled</strong> and add the tracking number.
                <br />
                <strong>Do you want to add tracking code to PayPal/Stripe for previous orders? </strong>
              </div>
            </div>
            <div className="old-order-btn">
              <ButtonGroup>
                {state.submitDemo === 1 && props.isShowDemoOrder ? (
                  <div className="mr-20">
                    <Button
                      icon={ExchangeMajor}
                      onClick={() => {
                        setState({ ...state, isOpenSubmitDemo: true });
                      }}
                    >
                      Test Somes Orders
                    </Button>
                  </div>
                ) : null}
                <Button icon={ReplayMinor} primary onClick={openModalGetOldStatus}>
                  Process Old Orders
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </AlphaCard>
      }
      <Modal
        open={state.isOpenModal}
        onClose={() =>
          setState({
            ...state,
            isOpenModal: false,
          })
        }
        title="Would you like to process the old orders?"
        primaryAction={{
          disabled: state.oldOrderRange === '',
          content: 'OK',
          onAction: handleGetOldOrder,
          loading: oldOrderStatus.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () =>
              setState({
                ...state,
                isOpenModal: false,
              }),
          },
        ]}
      >
        <Modal.Section>
          <div style={{ lineHeight: '36px' }}>
            The range for getting old orders (max 365 days)
            <div className="ml-2 mr-4">
              <TextField
                label=""
                autoComplete="off"
                type="number"
                value={state.oldOrderRange}
                onChange={(value) => handleChangeOldOrderRange(value)}
                error={state.validateMessage}
                min={0}
                max={365}
              />
            </div>
          </div>
        </Modal.Section>
      </Modal>
    </OldOrderBannerStyled>
  );
};

export default memo(OldOrderBanner);
