import { AlertInline } from '@/components/alertInline';
import CustomCard from '@/components/card';
import Loading from '@/components/loading';
import OldOrderBanner from '@/components/oldOrderbanner';
import SettingToggle from '@/components/settingToggle';
import config from '@/config';
import { COLORS } from '@/constants/colors';
import { Plan } from '@/constants/enum';
import { LINK } from '@/constants/link';
import { handleToastMuatation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { orderTableOrderPageSelector } from '@/redux/slice/orderPage.slice';
import { isVisibleBannerStripeSelector } from '@/redux/slice/settings.slice';
import { ISettingsPage } from '@/types/components';
import { Badge, Banner, Button, Icon, Link, Modal, Text, TextField, Tooltip } from '@shopify/polaris';
import { DeleteMinor, EditMinor, PlusMinor } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { StripeSettingStyled } from './styled';
import { userSettingSelector } from '@/redux/slice/commonSetting.slice';
const StripeSetting = () => {
  const dispatch = useDispatch();
  const isVisibleBanner = useSelector(isVisibleBannerStripeSelector);
  const orderTable = useSelector(orderTableOrderPageSelector);
  const userSetting = useSelector(userSettingSelector);
  const [syncStripeMutation, syncStripeStatus] = apiCaller.useSyncStripeMutation();
  const fetchListStripeToken = apiCaller.useFetchListStripeQuery(undefined);
  const [saveStripeAccount, saveStripeStatus] = apiCaller.useMutationStripeMutation();
  const [fetchLazyDeleteStripeAccount, deleteStripeStatus] = apiCaller.useDeleteStripeMutation();
  const [state, setState] = useState<ISettingsPage.IStateStripeSetting>({
    id: undefined,
    token: '',
    isOpen: false,
    errStripeToken: '',
    type: 'add',
    isDisableSyncBtn: false,
  });

  const clearState = () => {
    setState({
      id: undefined,
      token: '',
      isOpen: false,
      errStripeToken: '',
      type: 'add',
      isDisableSyncBtn: false,
    });
  };

  const handleToggle = () => {
    if (config.role !== 'view') {
      const syncStripe = !userSetting?.syncStripe;
      syncStripeMutation(syncStripe).then((res) => {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
        if (userSetting) {
          dispatch(
            slice.commonSettingSlice.actions.handleUserSetting({
              ...userSetting,
              syncStripe,
            }),
          );
        }
        dispatch(
          slice.orderPageSlice.actions.handleFiltersOrderTable({
            ...orderTable,
            paymentSelected: syncStripe ? ['paypal_stripe'] : ['paypal'],
          }),
        );
      });
    }
  };

  const handleSaveStripeAccount = () => {
    saveStripeAccount({
      id: state.id,
      stripeToken: state.token,
    }).then((res) => {
      dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
      setState({ ...state, isOpen: false, token: '' });
    });
  };

  const handleModalAddStripeToken = () => {
    setState({ ...state, type: 'add', isOpen: true, id: undefined });
  };

  const editStripeToken = (id: number) => () => {
    const stripeSelected = fetchListStripeToken.data && fetchListStripeToken.data.result.find((item) => item.id === id);
    if (stripeSelected) {
      setState({ ...state, isOpen: true, token: stripeSelected?.token, id, type: 'edit' });
    }
  };

  const confirmDeleteStripeToken = (id: number) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'Stripe orders paid to this account will not be synced',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: COLORS.primary,
    }).then((result) => {
      if (result.value) {
        fetchLazyDeleteStripeAccount(id).then((res) => {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
        });
      }
    });
  };

  const changeStripeToken = (value: string) => {
    setState({ ...state, token: value, errStripeToken: value === '' ? 'Stripe token is required!' : '' });
  };

  const contentStatus = userSetting && userSetting.syncStripe ? 'Turn off sync' : 'Turn on sync';
  const Content = () => {
    return (
      <div>
        {userSetting && userSetting.plan === Plan.FREE && !userSetting.syncStripe ? (
          <AlertInline
            content="This function is only available when using Basic plan or higher"
            link="Upgrade to plan Basic or higher"
          />
        ) : null}
        <div className="mt-16">
          <Text as="h6" variant="bodyMd">
            {!userSetting?.syncStripe ? 'Turn on to add Stripe account(s) and sync tracking information to Stripe' : ''}
          </Text>
        </div>
      </div>
    );
  };
  return (
    <StripeSettingStyled>
      {fetchListStripeToken.isLoading && <Loading />}
      {isVisibleBanner && (
        <div className="mb-16">
          <Banner
            title="Your account is protected"
            onDismiss={() => {
              dispatch(slice.settingsPageSlice.actions.handleVisibleBannerStripe(false));
            }}
          >
            <span className="text-left" style={{ lineHeight: '26px', fontSize: '15px' }}>
              We only require <b>minimal access </b> from your account to add tracking info to Stripe. Rest assured that your
              account will <b>always be safe</b>
            </span>
          </Banner>
        </div>
      )}

      <CustomCard title="Stripe Settings">
        <SettingToggle
          settingToggleProps={{
            action: {
              content: contentStatus,
              onAction: handleToggle,
              disabled: userSetting?.plan === Plan.FREE && !userSetting?.syncStripe,
              loading: syncStripeStatus.isLoading,
            },
            enabled: userSetting?.syncStripe,
          }}
          title="Sync tracking information to Stripe:"
          content={<Content />}
        />
        {userSetting && userSetting.syncStripe ? (
          <div>
            {fetchListStripeToken.data && fetchListStripeToken.data.result.length > 0 ? (
              <div className=" w-100">
                {fetchListStripeToken.data &&
                  fetchListStripeToken.data.result.map((item) => {
                    return (
                      <div
                        key={item.token}
                        className="stripe-account-layout"
                        style={{
                          fontSize: '16px',
                          lineHeight: '34px',
                        }}
                      >
                        <div className="stripe-account-content">
                          <div className="stripe-account-content-left">
                            <Badge progress="complete" status={item.confirmed ? 'success' : 'critical'}>
                              {item.confirmed ? 'Confirmed' : 'Invalid Stripe Token'}
                            </Badge>
                            <div className="d-flex lh-34 stripe-token-container">
                              <b className="stripe-token">Stripe token:</b>
                              <p className="stripe-token-value">{item.token}</p>
                            </div>
                          </div>
                          <div>
                            <div className="stripe-account-control">
                              <Tooltip content="Edit Stripe Token">
                                <Button onClick={editStripeToken(item.id)} icon={EditMinor} />
                              </Tooltip>
                              <div className="delete-button">
                                <Tooltip content="Delete Stripe Token">
                                  <Button
                                    loading={deleteStripeStatus.isLoading}
                                    onClick={() => confirmDeleteStripeToken(item.id)}
                                    icon={() => <Icon source={DeleteMinor} color="base" />}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="mt-30">
                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '28px',
                  }}
                >
                  <b>Step 1:</b> Give us Stripe token to start processing Stripe integration. This is{' '}
                  <Link external url={LINK.linkStripe} removeUnderline>
                    the instruction
                  </Link>{' '}
                  on how to get an Stripe token.
                  <br />
                  <b>Step 2:</b> Choose <b>"Add Stripe token"</b> and enter the token into the text field.
                </div>
              </div>
            )}
            <div className="stripe-btn">
              <Button
                onClick={handleModalAddStripeToken}
                primary
                icon={PlusMinor}
                disabled={
                  userSetting &&
                  (userSetting.plan === Plan.FREE || userSetting.plan === Plan.BASIC) &&
                  fetchListStripeToken.data &&
                  fetchListStripeToken.data.result.length === 1
                }
              >
                Add Stripe Token
              </Button>
            </div>
            {userSetting &&
            (userSetting.plan === Plan.FREE || userSetting.plan === Plan.BASIC) &&
            fetchListStripeToken.data &&
            fetchListStripeToken.data.result.length === 1 ? (
              <AlertInline content="If you want to connect more Stripe accounts." link="Upgrade to plan Pro or higher" />
            ) : null}

            <div className="w-100 pd-0 mt-20">
              <OldOrderBanner isShowDemoOrder={false} />
            </div>
          </div>
        ) : null}
      </CustomCard>
      <Modal
        open={state.isOpen}
        onClose={clearState}
        title={state.type === 'add' ? 'Add Stripe token' : 'Edit stripe token'}
        primaryAction={{
          content: 'Save',
          disabled: state.token === '' ? true : false,
          onAction: handleSaveStripeAccount,
          loading: saveStripeStatus.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setState({ ...state, isOpen: false }),
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label="Stripe token:"
            value={state.token}
            onChange={(value) => changeStripeToken(value)}
            error={state.errStripeToken}
            type="text"
            autoComplete="off"
          />
          <div className="mt-3"></div>
          <Link url={LINK.linkStripe} external>
            How to get Stripe token?
          </Link>
        </Modal.Section>
      </Modal>
    </StripeSettingStyled>
  );
};

export default StripeSetting;
