import { Link } from '@shopify/polaris';

const Guide = () => {
  return (
    <div className="pl-2 pr-2 mt-3">
      <strong style={{ fontSize: '16px' }}>Following steps below to create PayPal key :</strong>
      <div
        className="pl-3 mt-2"
        style={{
          lineHeight: '28px',
          fontSize: '15px',
        }}
      >
        <div>
          1.{' '}
          <Link removeUnderline external url="https://developer.paypal.com/developer/applications">
            Login to Paypal Developer Dashboard.
          </Link>
          <br />
          2. If you don't Login, click on <strong>Log into Dashboard</strong> <br />
          3. On{' '}
          <Link removeUnderline external url="https://developer.paypal.com/developer/applications">
            <strong>My Apps & Credentials</strong> menu,
          </Link>{' '}
          Click <strong>Live</strong> box.
          <br />
          4. Click{' '}
          <Link removeUnderline external url="https://developer.paypal.com/developer/applications/create?env=live">
            <strong>Create App</strong>
          </Link>{' '}
          Click <strong>Live</strong> box.
          <br />
          5. Type <strong>App Name</strong> and click <strong>Create App</strong>
          <br />
          6. In the <strong>APP SETTINGS</strong> box, in the <strong>App feature options</strong> section. You have only selected{' '}
          <strong>Customer Disputes & Transaction Search</strong> to authorize us to send Tracking code
          <br />
          7. Copy <strong>Client ID</strong> & <strong>Secret</strong> and paste on Form
        </div>
      </div>
    </div>
  );
};

export default Guide;
