import styled from "styled-components";

export const OrderPageStyled = styled.div`
  margin-top: 16px;
    .mb-20 {
        margin-bottom: 20px;
    }
    a {
    text-decoration : none;
    &:hover {
      text-decoration : underline;
    }
    }
    
`