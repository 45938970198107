import { COLORS } from "@/constants/colors";
import styled from "styled-components";
const colors = { ...COLORS } as any
interface Props {
    status? : string
}

export const CardStyled = styled.div<Props>`
width: 100%;
.Polaris-Box {
    background-color: ${(props) => props.status !== undefined && colors[props.status]};
}
.custom-card-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dfe3e8;
    padding: 12px 20px 7px;
    .custom-card-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 36px;
}
}

.custom-card-content {
    padding: 20px;
    .Polaris-Box {
        height: 100%;
        .indicator-filter-card {
            height: 100%;
            display: flex;
            flex-direction : column;
            justify-content : space-between;
        }
    }
}
`