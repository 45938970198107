import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { apiCaller } from './query';
import commonSettingSlice from './slice/commonSetting.slice';
import disputePageSlice from './slice/dispute.slice';
import homePageSlice from './slice/homePage.slice';
import orderPageSlice from './slice/orderPage.slice';
import settingsPageSlice from './slice/settings.slice';

const settingsPersistConfig = {
  key: 'settings',
  blacklist: ['userSetting'],
  storage,
};

const commonSettingPersistConfig = {
  key: 'commonSetting',
  storage,
  blacklist: ['toast'],
};

const rootReducer = combineReducers({
  // Thêm Reducer tại đây. Sample : sample : sampleReducer
  commonSetting: persistReducer(commonSettingPersistConfig, commonSettingSlice.reducer),
  homePage: homePageSlice.reducer,
  order: orderPageSlice.reducer,
  settings: persistReducer(settingsPersistConfig, settingsPageSlice.reducer),
  dispute: disputePageSlice.reducer,
  apiCaller: apiCaller.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const customizedMiddleware = getDefaultMiddleware({
      serializableCheck: false,
    });
    return customizedMiddleware.concat(apiCaller.middleware);
  },
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
export default store;
