import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
// Define the initial state using that type
const initialState = {
  activeKey: 'paypal',
  isVisibleBannerPaypal: true,
  isVisibleBannerStripe: true,
  isVisibleBannerCourierMapping: true,
  isVisibleBannerStores: true,
  isVisibleBannerIgnoreSync: true,
};

const settingsPageSlice = createSlice({
  name: 'settingsPage',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleActiveKey: (state, action: PayloadAction<string>) => {
      state.activeKey = action.payload;
    },
    handleVisibleBannerPaypal: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerPaypal = action.payload;
    },
    handleVisibleBannerStripe: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerStripe = action.payload;
    },
    handleVisibleBannerCourierMapping: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerCourierMapping = action.payload;
    },
    handleVisibleBannerStores: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerStores = action.payload;
    },
    handleVisibleBannerIgnoreSync: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerIgnoreSync = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const activeKeySelector = createSelector(
  (state: RootState) => state.settings,
  (state) => state.activeKey,
);
export const isVisibleBannerPaypalSelector = createSelector(
  (state: RootState) => state.settings,
  (state) => state.isVisibleBannerPaypal,
);
export const isVisibleBannerStripeSelector = createSelector(
  (state: RootState) => state.settings,
  (state) => state.isVisibleBannerStripe,
);
export const isVisibleBannerCourierMappingSelector = createSelector(
  (state: RootState) => state.settings,
  (state) => state.isVisibleBannerCourierMapping,
);
export const isVisibleBannerStoresSelector = createSelector(
  (state: RootState) => state.settings,
  (state) => state.isVisibleBannerStores,
);
export const isVisibleBannerIgnoreSyncSelector = createSelector(
  (state: RootState) => state.settings,
  (state) => state.isVisibleBannerIgnoreSync,
);

export default settingsPageSlice;
