import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IOrderTable } from '@/types/components';
import { getSomeDaysAgo } from '@/helpers';
// Define the initial state using that type
const initialState = {
  searchInput: '',
  orderTable: {
    currentPage: 1,
    search: '',
    fromDate: getSomeDaysAgo(7),
    toDate: new Date(),
    statusSelected: ['all'],
    paymentSelected: ['paypal'],
    perPage: ['25'],
    sort: 'DESC',
    sortBy: 'orderNumber',
  },
  titleBtnDatePicker: 'Last 7 days',
};

const orderPageSlice = createSlice({
  name: 'orderPage',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleFiltersOrderTable: (state, action: PayloadAction<IOrderTable.IState>) => {
      state.orderTable = action.payload;
    },
    handleSearchInput: (state, action: PayloadAction<string>) => {
      state.searchInput = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const orderTableOrderPageSelector = createSelector(
  (state: RootState) => state.order,
  (state) => state.orderTable,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.order,
  (state) => state.titleBtnDatePicker,
);
export const searchInputSelector = createSelector(
  (state: RootState) => state.order,
  (state) => state.searchInput,
);
export default orderPageSlice;
