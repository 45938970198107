import  styled  from 'styled-components';
export const LoadingStyled = styled.div`
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
width: 100vw;
height: 100vh;
background-color: white;
opacity: 0.7;
display: flex;
justify-content: center;
align-items: center;
z-index: 10000;
`