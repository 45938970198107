import { ApexOptions } from 'apexcharts';
import ApexCharts from 'react-apexcharts';

interface Props {
  data: {
    categories: any;
    series: ApexOptions['series'];
  };
}

const Chart = ({ ...props }: Props) => {
  const options: ApexOptions = {
    tooltip: {
      y: {
        formatter(val: any) {
          return val;
        },
      },
    },

    chart: {
      id: 'shipments-chart',
      type: 'line',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true,
          customIcons: [],
        },
      },
    },
    colors: ['#58aa64', '#00a0ac', '#df7e00', '#2b4975', '#969696', '#ffc107', '#6b2700', '#dc3545', '#cccccc', '#f8bb26'],
    stroke: {
      width: 4,
    },
    xaxis: {
      categories: props.data.categories,
    },
  };
  const typeChart = 'line';
  return (
    <div id="chart" className="mixed-chart w-100">
      <div>
        <ApexCharts options={options} series={props.data.series} type={typeChart} width="100%" height="313px" />
      </div>
    </div>
  );
};

export default Chart;
