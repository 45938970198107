import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IResponseApi } from '@/types/api/response.api';
import config from '@/config';
import { IParamsApi } from '@/types/api/params.api';
const endPoint = process.env.REACT_APP_API_END_POINT;
const instanceId = config.instanceId;

export const apiCaller = createApi({
  reducerPath: 'apiCaller',
  refetchOnMountOrArgChange: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: endPoint,
    prepareHeaders: (headers) => {
      headers.set('Access-Control-Allow-Origin', `*`);
      headers.set('Authorization', `${config.instance ? config.instance : ''}`);
      return headers;
    },
  }),
  tagTypes: [
    'courierMapping',
    'stripe',
    'dataSettings',
    'paypal',
    'order',
    'dispute',
    'accountDispute',
    'listAccountDispute',
    'disputeDetail',
    'courierIgnore',
    'notification',
  ],
  endpoints: (builder) => ({
    fetchDataSetting: builder.query<IResponseApi.IDashBoard, IParamsApi.IDashBoard>({
      query: (input) => {
        return {
          url: 'user/dashboard',
          method: 'GET',
          params: { ...input, fromDate: input.fromDate.toISOString(), toDate: input.toDate.toISOString(), instanceId },
        };
      },
      providesTags: [{ type: 'dataSettings' }],
    }),

    processOldOrder: builder.mutation<IResponseApi.CommonResponse, number>({
      query: (processDays) => {
        return {
          url: 'order/process-orders',
          method: 'POST',
          body: { instanceId, processDays },
        };
      },
    }),

    fetchOrders: builder.query<IResponseApi.IListOrder, IParamsApi.IListOrder>({
      query: (input) => {
        return {
          url: 'order/search',
          method: 'GET',
          params: { ...input, fromDate: input.fromDate.toISOString(), toDate: input.toDate.toISOString(), instanceId },
        };
      },
      providesTags: [{ type: 'order' }],
    }),

    connectPaypalAccount: builder.query<IResponseApi.IConnectPaypalAccount, any>({
      query: () => {
        return {
          url: 'paypal/url-connect',
          method: 'GET',
        };
      },
    }),

    listPaypalAccount: builder.query<IResponseApi.IListPaypalAccount, string>({
      query: (input) => {
        return {
          url: 'paypal/accounts',
          method: 'GET',
          params: { instanceId },
        };
      },
      providesTags: [{ type: 'paypal' }],
    }),

    processCodePayPalReturn: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IProcessCodePayPalReturnParams>({
      query: (input) => {
        return {
          url: `paypal/code-return`,
          method: 'POST',
          body: { instanceId, ...input },
        };
      },
      invalidatesTags: [{ type: 'paypal' }, { type: 'dataSettings' }],
    }),

    removePaypalAccount: builder.mutation<IResponseApi.CommonResponse, number>({
      query: (id) => {
        return {
          url: `paypal/delete`,
          method: 'DELETE',
          params: { instanceId, id },
        };
      },
      invalidatesTags: [{ type: 'paypal' }, { type: 'dataSettings' }],
    }),

    setPrimaryPaypalAccount: builder.mutation<IResponseApi.CommonResponse, number>({
      query: (id) => {
        return {
          url: `paypal/set-primary`,
          method: 'PUT',
          body: { instanceId, id },
        };
      },
      invalidatesTags: [{ type: 'paypal' }, { type: 'dataSettings' }],
    }),

    notifyBuyerMutation: builder.mutation<IResponseApi.CommonResponse, boolean>({
      query: (paypalNotifyBuyer) => {
        return {
          url: `setting/notify-buyer/update`,
          method: 'PUT',
          body: { instanceId, paypalNotifyBuyer },
        };
      },
    }),

    fetchCourierMapping: builder.query<IResponseApi.IListCourierMapping, any>({
      query: (instanceId) => {
        return {
          url: `setting/courier-mapping/rules`,
          method: 'GET',
          params: { instanceId },
        };
      },
      providesTags: [{ type: 'courierMapping' }],
    }),

    mutationCourierMapping: builder.mutation<IResponseApi.CommonResponse, IParamsApi.ISaveMappingParams>({
      query: (input) => {
        return {
          url: `setting/courier-mapping/save`,
          method: 'POST',
          body: { instanceId, ...input },
        };
      },
      invalidatesTags: [{ type: 'courierMapping' }],
    }),

    deleteCourierMapping: builder.mutation<IResponseApi.CommonResponse, number>({
      query: (id) => {
        return {
          url: `setting/courier-mapping/delete`,
          method: 'DELETE',
          params: { instanceId, id },
        };
      },
      invalidatesTags: [{ type: 'courierMapping' }],
    }),

    fetchListStripe: builder.query<IResponseApi.IListStripe, any>({
      query: (input) => {
        return {
          url: `stripe/tokens`,
          method: 'GET',
          params: { instanceId },
        };
      },
      providesTags: [{ type: 'stripe' }],
    }),

    mutationStripe: builder.mutation<IResponseApi.CommonResponse, IParamsApi.ISaveStripeAccountParams>({
      query: (input) => {
        return {
          url: `stripe/connect`,
          method: 'POST',
          body: { instanceId, ...input },
        };
      },
      invalidatesTags: [{ type: 'stripe' }, { type: 'dataSettings' }],
    }),

    deleteStripe: builder.mutation<IResponseApi.CommonResponse, number>({
      query: (id) => {
        return {
          url: `stripe/remove`,
          method: 'DELETE',
          params: { instanceId, id },
        };
      },
      invalidatesTags: [{ type: 'stripe' }, { type: 'dataSettings' }],
    }),

    syncStripe: builder.mutation<IResponseApi.CommonResponse, boolean>({
      query: (syncStripe) => {
        return {
          url: `stripe/active-sync`,
          method: 'PUT',
          body: { instanceId, syncStripe },
        };
      },
    }),

    handleCsv: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IUploadCSV>({
      query: (input) => {
        return {
          url: `order/${input.action}`,
          method: input.method,
          body: { instanceId, orders: input.orders },
        };
      },
      invalidatesTags: [{ type: 'order' }],
    }),

    exportOrders: builder.query<IResponseApi.IExportOrders, IParamsApi.IExportListOrderParams>({
      query: (input) => {
        return {
          url: `order/export/get-link`,
          method: 'GET',
          params: { instanceId, ...input },
        };
      },
    }),
  }),
});
