import { Badge, SettingToggle } from "@shopify/polaris"
import { ISettingToggle } from '@/types/components'
import { memo } from "react"
import { CustomSettingToggleStyled } from "./styled"

const CustomSettingToggle = ({ ...props } : ISettingToggle.IProps) => {
    return (
      <CustomSettingToggleStyled>
        <SettingToggle {...props.settingToggleProps}>
            <strong style={{ display : 'block' , marginBottom : '16px' }}>
                {props.title}
                <Badge progress='complete' status={props.settingToggleProps.enabled ? 'success' : 'critical'}>
                  {props.settingToggleProps.enabled ? 'On' : 'Off'}
                </Badge>
            </strong>
              <div>
                  {props.content}
              </div>
        </SettingToggle>
      </CustomSettingToggleStyled>
      
    )
}

export default memo(CustomSettingToggle)