import RenderRouter from '@/routes';
import { AppProvider } from '@shopify/polaris';
import { useEffect } from 'react';
import i18n from './i18n/en.json';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Crisp } from 'crisp-sdk-web';
import { hotjar } from 'react-hotjar';

function App() {
  useEffect(() => {
    hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_ID) || 0, 6);
    if (hotjar.initialized()) {
      hotjar.identify('USER_ID', { userProperty: 'value' });
    }
    const script = document.createElement('script');
    script.src = `https://wx.synctrack.io/w/api/file/crisp`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppProvider i18n={i18n}>
      <RenderRouter />
    </AppProvider>
  );
}

export default App;
