import Loading from '@/components/loading';
import config from '@/config';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import { AlphaCard, Button, Text } from '@shopify/polaris';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StepFive from './components/StepFive';
import StepOne from './components/StepOne';
import StepFour from './components/StepFour';
import StepTwo from './components/StepTwo';
import StepThree from './components/StepThree';
import { WelcomeStyled } from './styled';
import { hotjar } from 'react-hotjar';
import { useDispatch } from 'react-redux';
import slice from '@/redux/slice';

export const welcomeContext = React.createContext<{ isChecked: boolean; setIsChecked: any }>({
  isChecked: false,
  setIsChecked: () => {},
});

const Welcome = (): JSX.Element => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [state, setState] = useState({
  //   step: 1,
  //   isDisable: false,
  // });
  // const [isChecked, setIsChecked] = useState(false);
  // const dataSettings = apiCaller.useFetchDataSettingQuery(config.shop);
  // const [fetchDataSettings, { isLoading }] = apiCaller.useLazyFetchDataSettingQuery();
  // const [updateWelcomeStatus] = apiCaller.useUpdateWelcomStatusMutation();
  // const [updateWelcomeStep] = apiCaller.useUpdateWelcomStepMutation();
  // const [lazyProcessOldOrders] = apiCaller.useLazyFetchProcessGetOldOrdersQuery();
  // const [processCodePaypalReturn] = apiCaller.useProcessCodePayPalReturnMutation();
  // const [clickReviewMutation] = apiCaller.useClickReviewMutation();
  // const handleChangeStep = (value: number) => () => {
  //   if (value === 2) {
  //     clickReviewMutation({
  //       type: 'click_get_started',
  //       note: 'click_get_started',
  //     });
  //   }

  //   setState({ ...state, step: value });
  //   updateWelcomeStep({ step: value });
  //   if (value === 5 && isChecked) {
  //     lazyProcessOldOrders({
  //       oldOrderRange: 0,
  //       path: 'welcome',
  //     });
  //   }
  // };
  // const stepItems = useMemo(
  //   () => [
  //     {
  //       key: 1,
  //       title: 'Welcome',
  //       content: <StepOne />,
  //     },
  //     {
  //       key: 2,
  //       title: 'Connect to Paypal',
  //       content: <StepTwo />,
  //     },
  //     {
  //       key: 3,
  //       title: 'Email consent',
  //       content: <StepThree onSkip={handleChangeStep(4)} email={dataSettings.data?.subscriptionEmailMarketing} />,
  //     },
  //     {
  //       key: 4,
  //       title: 'Process old orders',
  //       content: <StepFour />,
  //     },
  //     {
  //       key: 5,
  //       title: 'Finish',
  //       content: <StepFive />,
  //     },
  //   ],
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [dataSettings.data],
  // );

  // useEffect(() => {
  //   window.onmessage = function (e) {
  //     if (e.data.type === 'paypalConnected') {
  //       processCodePaypalReturn({ code: e.data.code, path: 'welcome' }).then(async (res) => {
  //         dispatch(
  //           slice.commonSettingSlice.actions.handleToast({
  //             content: 'Account connected',
  //             isOpen: true,
  //             error: false,
  //           }),
  //         );
  //         setState({ ...state, step: 3 });
  //       });
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   const getWelcomeStep = async () => {
  //     fetchDataSettings(config.shop).then((res) => {
  //       if (res.isSuccess) {
  //         setState({
  //           ...state,
  //           step: res.data.welcomeStep === 0 ? 1 : res.data.welcomeStep,
  //         });
  //         if (res.data.welcomeStep > 5) {
  //           navigate(PATH.HOME);
  //         }
  //       }
  //     });
  //   };
  //   getWelcomeStep();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // return (
  //   <welcomeContext.Provider value={{ isChecked, setIsChecked }}>
  //     <WelcomeStyled>
  //       {isLoading && <Loading />}
  //       <AlphaCard padding={'8'}>
  //         <div className="welcome-container">
  //           {/* <input type="hidden" name="_token" defaultValue="8ihPhbYCUafn2bz9h7AmOG0xBPtvx719wUkl9JmZ" />
  //           <div className="steps-progress" style={{ marginLeft: '12.5%', marginRight: '12.5%' }}>
  //                   <div className="progress-indicator" style={{ width: 0 }} />
  //                 </div> */}
  //           <ul>
  //             <div className="welcome-bridge" />
  //             {stepItems.map((item) => {
  //               return (
  //                 <li
  //                   key={item.key}
  //                   onClick={handleChangeStep(item.key)}
  //                   className={state.step > item.key ? 'completed' : state.step === item.key ? 'active' : ''}
  //                 >
  //                   <div className="step-container" data-toggle="tab" aria-expanded="false">
  //                     <div className="step-number">{item.key}</div>
  //                     <Text as="h3" variant="headingMd">
  //                       {item.title}
  //                     </Text>
  //                   </div>
  //                 </li>
  //               );
  //             })}
  //           </ul>
  //           <div className="tab-content d-block">
  //             {/* <div className="tab-panel">{step === 0 ? <Step0 changePoll={changePoll} /> : null}</div> */}
  //             <div className="tab-panel">{stepItems.find((item) => item.key === state.step)?.content}</div>
  //           </div>
  //           {state.step !== 3 ? (
  //             <div className="text-center mt-20">
  //               {state.step < 5 ? (
  //                 <Button disabled={state.isDisable} size="large" onClick={handleChangeStep(state.step + 1)} fullWidth primary>
  //                   {state.step === 1 ? 'Get Started' : state.step === 0 ? 'Submit & Get Started' : 'Next'}
  //                 </Button>
  //               ) : null}
  //               <div className="mt-20">
  //                 <Button
  //                   fullWidth
  //                   size="large"
  //                   onClick={() => {
  //                     hotjar.stateChange(`${PATH.HOME.pathname}?${config.shop}`);
  //                     navigate(PATH.HOME, {
  //                       state: {
  //                         isNavigateFromWelcome: true,
  //                       },
  //                     });
  //                     updateWelcomeStatus(config.shop);
  //                   }}
  //                 >
  //                   Skip & Finish
  //                 </Button>
  //               </div>
  //             </div>
  //           ) : null}
  //         </div>
  //       </AlphaCard>
  //     </WelcomeStyled>
  //   </welcomeContext.Provider>
  // );
  return <></>;
};
export default Welcome;
