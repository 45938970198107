import { LINK } from '@/constants/link';
import { Columns, FooterHelp, Frame, Icon, Page } from '@shopify/polaris';
import { CircleAlertMajor } from '@shopify/polaris-icons';
import { memo, useEffect } from 'react';
import { LayoutStyled } from './styled';
import { useLocation } from 'react-router-dom';
import { PATH } from '@/constants/path';
import Menu from '../menu';
import CustomHeader from './header';
import Toast from '../toast';
import { apiCaller } from '@/redux/query';
import { useDispatch, useSelector } from 'react-redux';
import { orderTableHomePageSelector } from '@/redux/slice/homePage.slice';
import { useMediaQuery } from 'react-responsive';
import slice from '@/redux/slice';
function Layout({ ...props }: any) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const { pathname } = useLocation();
  const orderTable = useSelector(orderTableHomePageSelector);
  const fetchDataDashboard = apiCaller.useFetchDataSettingQuery({
    fromDate: orderTable.fromDate,
    toDate: orderTable.toDate,
    device: isMobile ? 'mobile' : 'desktop',
  });
  useEffect(() => {
    if (fetchDataDashboard.data && fetchDataDashboard.data.result) {
      dispatch(slice.commonSettingSlice.actions.handleUserSetting(fetchDataDashboard.data.result.userSetting));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataDashboard.data]);
  return (
    <LayoutStyled fluid>
      <Columns columns={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
        <CustomHeader title={props.title} />
        <Frame topBar={pathname !== PATH.WELCOME.pathname && <Menu />}>
          <Toast />
          <Page fullWidth>{props.children}</Page>
        </Frame>
      </Columns>
      <Columns columns={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
        <div className="app-footer">
          <div>
            <FooterHelp>
              <div className="layout-footer">
                <Icon source={CircleAlertMajor} color="highlight" />
                Need help? Please
                <a rel="noopener noreferrer" target="_blank" href={LINK.syntrackHomePage}>
                  view our docs
                </a>
              </div>
            </FooterHelp>
          </div>
        </div>
      </Columns>
    </LayoutStyled>
  );
}

export default memo(Layout);
