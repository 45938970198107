import styled from 'styled-components';

export const CourierMappingStyled = styled.div`
  .Polaris-Banner {
    padding: 16px 16px 14px 16px;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .add-btn {
    display: flex;
    justify-content: flex-end;
  }
  .Polaris-FormLayout {
    overflow-x: auto;
    overflow-y: hidden;
    .Polaris-FormLayout--condensed {
      min-width: 950px;
    }
  }
  .Polaris-ButtonGroup {
    width: 100%;
    justify-content: center;
  }
  .Polaris-FormLayout__Item {
    width: fit-content;
  }
  .mt-16 {
    margin-top: 16px;
  }
  @media (max-width: 1425px) {
    .Polaris-ButtonGroup {
      justify-content: flex-end;
    }
  }
  @media (max-width: 740px) {
    font-size: 12px !important;
  }
`;
