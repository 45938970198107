import CustomCard from '@/components/card';
import { PATH } from '@/constants/path';
import slice from '@/redux/slice';
import { orderTableOrderPageSelector } from '@/redux/slice/orderPage.slice';
import { IHomePage } from '@/types/components';
import { AlphaCard, Columns } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IndicatorFilterStyled } from './styled';
import { hotjar } from 'react-hotjar';
import config from '@/config';
const IndicatorFilter = ({ ...props }: IHomePage.IIncatorFilterProps) => {
  const dispatch = useDispatch();
  const orderTableOrderPage = useSelector(orderTableOrderPageSelector);
  const navigate = useNavigate();
  const configFilters = [
    {
      title: 'PayPal orders',
      data: [
        {
          value: props.dataSummary?.orderPaypalSynced,
          status: '1',
        },
        { value: props.dataSummary?.orderPaypalErr, status: 'total_err' },
      ],
      payment: 'paypal',
    },
    {
      title: 'Stripe orders',
      data: [
        { value: props.dataSummary?.orderStripeSynced, status: '1' },
        { value: props.dataSummary?.orderStripeErr, status: 'total_err' },
      ],
      payment: 'stripe',
    },
  ];
  const filters = ['Synced Orders', 'Sync Error'];
  const handleIndicatorFilter = (payment: string, status: string) => {
    hotjar.stateChange(`${PATH.ORDERS.pathname}?${config.instanceId}`);
    navigate(PATH.ORDERS);
    dispatch(
      slice.orderPageSlice.actions.handleFiltersOrderTable({
        ...orderTableOrderPage,
        paymentSelected: [payment],
        statusSelected: [status],
      }),
    );
  };
  return (
    <IndicatorFilterStyled className="mt-16">
      <Columns columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={{ xl: '4', lg: '4', md: '4', sm: '4', xs: '4' }}>
        {configFilters.map((item, index) => (
          <CustomCard title={item.title} key={index}>
            <Columns key={index} columns={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
              {item.data.map((value, index) => (
                <AlphaCard key={index} padding={'4'}>
                  <div className="indicator-filter-card">
                    <b
                      className="indicator-filter-card-title"
                      onClick={() => handleIndicatorFilter(item.payment, item.data[index].status)}
                    >
                      {filters[index]}
                    </b>
                    <span className="indicator-filter-card-value">{value.value}</span>
                  </div>
                </AlphaCard>
              ))}
            </Columns>
          </CustomCard>
        ))}
      </Columns>
    </IndicatorFilterStyled>
  );
};

export default IndicatorFilter;
