import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Loading from '../loading';
import { handleToastMuatation } from '@/helpers';

const PaypalButton = (): JSX.Element => {
  const dispatch = useDispatch();
  const [fetchLazyConnectToPaypal, { isFetching }] = apiCaller.useLazyConnectPaypalAccountQuery();
  const [mutationProcessCodePaypalReturn, processCodePaypalReturnStatus] = apiCaller.useProcessCodePayPalReturnMutation();
  const handleConnectToPayPal = () => {
    fetchLazyConnectToPaypal(undefined).then((res) => {
      if (res.isSuccess) {
        window.open(res.data.url, 'connect paypal', 'width=500,height=500');
      } else {
        dispatch(
          slice.commonSettingSlice.actions.handleToast({
            isOpen: true,
            content: 'something happened',
            error: true,
            contentAction: 'Try again',
            onAction: handleConnectToPayPal,
            hasAction: true,
          }),
        );
      }
    });
  };
  useEffect(() => {
    window.onmessage = function (e) {
      if (e.data.type === 'paypalConnected') {
        mutationProcessCodePaypalReturn({ code: e.data.code, path: 'paypal' }).then((res) => {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="d-flex">
      {(isFetching || processCodePaypalReturnStatus.isLoading) && <Loading />}
      <button className="connect-to-paypal ml-2" onClick={handleConnectToPayPal}>
        <svg
          id="CWPPSVG_PPTM"
          className="PPTM"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="18px"
          height="18px"
          viewBox="0 0 18 18"
          focusable="false"
        >
          <path
            id="Fill-8"
            className="PPTM-btm"
            fill="#009cde"
            d="M13.6023044,4.67342223 C13.8196336,3.28812419 13.6008532,2.34542498 12.8514484,1.49160831 C12.0263956,0.551629995 10.5359309,0.148937623 8.62894883,0.148937623 L3.09322219,0.148937623 C2.70355347,0.148937623 2.37175455,0.43245482 2.3109822,0.817370681 L0.00598653054,15.4327457 C-0.0395473836,15.7211605 0.183405526,15.9818221 0.475294243,15.9818221 L3.89269613,15.9818221 L3.65686311,17.4774071 C3.61713431,17.7297247 3.81196867,17.957917 4.06739398,17.957917 L6.94782221,17.957917 C7.28887304,17.957917 7.57912907,17.7097715 7.63228221,17.3731061 L7.66058217,17.226722 L8.20317953,13.7860604 L8.23819166,13.5959605 C8.29134479,13.2591138 8.58160082,13.0111496 8.92247024,13.0111496 L9.35331903,13.0111496 C12.1439493,13.0111496 14.329033,11.8774436 14.9674148,8.59894727 C15.2342689,7.22906764 15.0963973,6.08556642 14.3908938,5.28163282 C14.1773742,5.03856625 13.9119713,4.83740146 13.6023044,4.67342223"
          ></path>
          <path
            id="Fill-9"
            className="PPTM-top"
            fill="#009cde"
            d="M13.6023044,4.67384766 C13.8196336,3.28841937 13.6008532,2.34563151 12.8514484,1.49173456 C12.0263956,0.55166786 10.5359309,0.148937623 8.62894883,0.148937623 L3.09322219,0.148937623 C2.70355347,0.148937623 2.37175455,0.432481479 2.3109822,0.817433533 L0.00598653054,15.4341828 C-0.0395473836,15.7226247 0.183405526,15.9833109 0.475294243,15.9833109 L3.89269613,15.9833109 L4.75112833,10.5399219 L4.72446105,10.7106288 C4.78523341,10.3256767 5.11431118,10.0419515 5.50397989,10.0419515 L7.12832518,10.0419515 C10.3182389,10.0419515 12.815892,8.7459583 13.5457045,4.99802736 C13.5672923,4.88718584 13.5857961,4.7796097 13.6023044,4.67384766"
            style={{ fillOpacity: '0.699999988079071' }}
          ></path>
          <path
            id="Fill-10"
            className="PPTM-top"
            fill="#009cde"
            d="M5.67323544,4.69235148 C5.70988026,4.46069089 5.85845507,4.27111742 6.05855031,4.17515152 C6.14961814,4.13161312 6.25120775,4.10730418 6.35769543,4.10730418 L10.6968416,4.10730418 C11.2109576,4.10730418 11.6902429,4.14104644 12.128348,4.21161494 C12.2537024,4.23175145 12.3754285,4.25497193 12.4938892,4.28127638 C12.6121686,4.30739943 12.7271825,4.33678785 12.8383869,4.36944165 C12.8940797,4.38558714 12.9486841,4.40263969 13.0025629,4.42059928 C13.2177152,4.49189341 13.4179919,4.57624907 13.6023044,4.67384766 C13.8196336,3.28841937 13.6008532,2.34563151 12.8514484,1.49173456 C12.0263956,0.55166786 10.5359309,0.148937623 8.62894883,0.148937623 L3.09322219,0.148937623 C2.70355347,0.148937623 2.37175455,0.432481479 2.3109822,0.817433533 L0.00598653054,15.4341828 C-0.0395473836,15.7226247 0.183405526,15.9833109 0.475294243,15.9833109 L3.89269613,15.9833109 L5.67323544,4.69235148"
          ></path>
        </svg>
        <b>Connect via PayPal login</b>
      </button>
    </div>
  );
};

export default memo(PaypalButton);
