import styled from 'styled-components';
interface Props {
  srcDisputeBanner: string;
}

export const CredentialsStyled = styled.div`
  margin-top: 16px;
  .Polaris-Box {
    width: 100%;
  }
  .app_instructions_page_add_user_table {
    color: #333;
    margin: 10px 20px;
  }

  .app_instructions_page_add_user_table th,
  .app_instructions_page_add_user_table td {
    padding: 12px 15px;
    text-align: left;
  }

  .app_instructions_page_add_user_table tr:nth-child(odd) {
    background: rgb(250, 251, 252);
  }
  .plan-card-header,
  .chart-header {
    padding: 12px 20px 7px;
    font-weight: bold;
    border-bottom: 1px solid #dfe3e8;
  }
  .chart {
    .chart-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .mixed-chart {
    margin-top: 30px;
  }
  @media screen and (max-width: 1200px) {
    .mb-mt-24 {
      margin-top: 24px;
    }
  }
`;

export const DisputeStyled = styled.div<Props>`
  .dispute-banner {
    background: #fff;
    width: 100%;
    background-image: url(${(props) => props.srcDisputeBanner});
    padding: 20px;
    background-size: cover;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.12);
    border: 1px solid #ddd;
    height: 100%;
  }

  .lastest img {
    width: 50%;
  }
  .img-des {
    text-align: center;
    height: 100%;
    display: flex;
  }
  .img-des img {
    width: 100%;
    margin: auto;
  }

  .lastest {
    text-align: right;
  }
  .title {
    font-size: 26px;
    line-height: 40px;
  }
  .description {
    font-size: 16px;
    line-height: 32px;
  }
  .button img {
    width: 180px;
    cursor: pointer;
  }
  .button img:hover {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.12);
  }

  button {
    color: #397fbe;
    font-weight: 500;
  }

  .dispute-right-content {
    .Polaris-Button__Text {
      color: white;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mt-20 {
    margin-top: 20px;
  }
  .swiper-index3 {
    .swiper-pagination-bullet {
      background-color: #fff;
      border: 1px solid #fff !important;
      opacity: 0.6;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
  @media screen and (max-width: 900px) {
    .lastest,
    .title,
    .description,
    .button {
      text-align: center;
    }
    .lastest {
      display: none;
    }
    .img-des img {
      width: 70%;
    }
  }
`;
