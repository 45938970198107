import { CONSTAN } from '@/constants/constan';
import { BREAKPOINT } from '@/constants/enum';
import { Container } from 'reactstrap';
import styled from 'styled-components';
export const LayoutStyled = styled(Container)`
  font-size: 16px;
  padding: 0;
  .Polaris-Frame {
    padding: 0 12px;
  }
  .Polaris-Page--fullWidth {
    padding: 0px 42px;
  }
  .Polaris-Frame__Main {
    padding-top: 68px;
  }
  .layout-header {
    position: sticky;
    top: 68px;
    z-index: 399;
    background-color: #f6f6f7;
    padding: 0 54px;
  }
  .hide {
    display: none;
  }
  .Polaris-Page__Content {
    padding-top: 0px;
  }
  .layout-footer {
    display: flex;
    .Polaris-Icon {
      margin-right: 8px;
      height: 16px;
      width: 16px;
      transform: rotate(180deg);
    }
    a {
      text-decoration: none;
      margin-left: 4px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .top-left {
    line-height: 36px;
    height: 62px;
    display: flex;
    align-items: center;
    .Polaris-Badge {
      height: 22px;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .Polaris-Button--iconOnly {
    height: 36px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .mr-16 {
    margin-right: 16px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .p-vertical-20 {
    padding: 20px 0px;
  }
  .fz-20 {
    font-size: 20px;
  }
  .align-center {
    align-items: center;
  }
  .j-c-s-b {
    justify-content: space-between;
  }
  @media (max-width: ${BREAKPOINT.MD}px) {
    .Polaris-Frame__Main {
      padding-top: 40px;
    }
    .layout-header {
      top: 40px;
    }
  }

  /* Banner */
  .noti-btn-container {
    position: relative;
  }
  .father-day-banner {
    position: absolute;
    width: 330px;
    left: -290px;
    bottom: -289px;
  }
`;
