/* eslint-disable jsx-a11y/iframe-has-title */
import slice from '@/redux/slice';
import { activeKeySelector } from '@/redux/slice/settings.slice';
import { ISettingsPage } from '@/types/components';
import { Icon } from '@shopify/polaris';
import { AffiliateMajor, PaymentsMajor, TapChipMajor } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Col, Row } from 'reactstrap';
import CourierMapping from './component/courier';
import PaypalSetting from './component/paypal';
import StripeSetting from './component/stripe';
import { SettingsPageStyled } from './styled';
const tabs: Array<ISettingsPage.ITabs> = [
  {
    key: 'paypal',
    title: 'PayPal Settings',
    icon: PaymentsMajor,
    renderItem: <PaypalSetting />,
  },
  {
    key: 'stripe',
    title: 'Stripe Settings',
    icon: TapChipMajor,
    renderItem: <StripeSetting />,
  },
  {
    key: 'courier',
    title: 'Courier Mapping',
    icon: AffiliateMajor,
    renderItem: <CourierMapping />,
  },
  // {
  //   key: 'ignoreSync',
  //   title: 'Ignore Sync',
  //   icon: LockMinor,
  //   renderItem: <SyncFilter />,
  // },
  // {
  //   key: 'digitalProduct',
  //   title: 'Digital Product',
  //   icon: DigitalMediaReceiverMajor,
  //   renderItem: <DigitalProduct />,
  // },
  // {
  //   key: 'notes',
  //   title: 'Shopify Order Notes',
  //   icon: AddNoteMajor,
  //   renderItem: <OrderNotes />,
  // },
  // {
  //   key: 'integrations',
  //   title: 'Integrations',
  //   icon: AppExtensionMinor,
  //   renderItem: <Integration />,
  // },
];

const SettingsPage = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const dispatch = useDispatch();
  const activeKey = useSelector(activeKeySelector);
  const changeTab = (key: string) => {
    dispatch(slice.settingsPageSlice.actions.handleActiveKey(key));
    if (isMobile) {
      window.scrollTo(0, 735);
    }
  };
  return (
    <SettingsPageStyled>
      <Row>
        <Col xs="12" sm="12" md="3">
          <div className="w-100">
            {tabs.map((item: ISettingsPage.ITabs) => {
              return (
                <div
                  key={item.key}
                  className={activeKey === item.key ? 'active settings-tab-layout' : 'settings-tab-layout'}
                  onClick={() => changeTab(item.key)}
                >
                  <div className="tab-title">
                    <Icon color="base" source={item.icon} />
                    <p className="ml-3">{item.title}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
        <Col xs="12" sm="12" md="9" className="mt-16">
          <Row>
            <Col md={12}>{tabs.find((item: ISettingsPage.ITabs) => item.key === activeKey)?.renderItem}</Col>
          </Row>
        </Col>
      </Row>
    </SettingsPageStyled>
  );
};

export default memo(SettingsPage);
