import { OrderPageStyled } from './styled';
import OldOrderBanner from '@/components/oldOrderbanner';
import { memo } from 'react';
import OrderTable from '@/components/orderTable';
import UploadCSV from './csv';
const OrderPage = () => {
  return (
    <OrderPageStyled>
      <div className="mb-20">
        <OldOrderBanner isShowDemoOrder />
      </div>
      <OrderTable title="List orders" />
      <div className="mb-20" />
      <div>
        <UploadCSV
          method="POST"
          action="upload/manual-order"
          title="Upload orders by CSV file"
          type="order"
          description="Download Template and enter order information if you want to add tracking information to PayPal for orders from other platforms. Then upload the updated template file"
        />
        <div className="mb-20" />
        <UploadCSV
          method="PUT"
          action="upload/tracking-number"
          title="Upload Tracking Number By CSV"
          type="tracking"
          description="Download the template and enter the information, then upload the file. You can use this feature to get your last mile tracking number."
        />
      </div>
    </OrderPageStyled>
  );
};

export default memo(OrderPage);
