import { COLORS } from '@/constants/colors';
import { Row } from 'reactstrap';
import styled from 'styled-components';

export const PaypalSettingStyled = styled(Row)`
  .paypal-account-layout {
    padding: 10px 15px;
    border: 2px solid #eee;
    display: flex;
    justify-content: space-between;
  }
  .group-action {
    display: none;
  }
  .paypal-account-layout:hover {
    border: 2px solid rgb(187 229 179);
    .group-action {
      display: block;
    }
    .delete-button .Polaris-Button:hover {
      border-color: #ff1744;
      svg {
        fill: #ff1744;
      }
    }

    .other-button .Polaris-Button:hover {
      border-color: ${COLORS.primary};
      svg {
        fill: ${COLORS.primary};
      }
    }
  }
  .mb-16 {
      margin-bottom: 16px;
    }
    .mt-16 {
      margin-top: 16px;
    }

  .connect-to-paypal {
    padding: 0.625em 2em;
    max-width: 375px;
    font-size: 16px;
    background-image: -webkit-linear-gradient(#0070ba 20%, #0070ba) !important;
    background-image: linear-gradient(#0070ba 20%, #0070ba) !important;
    background-repeat: no-repeat !important;
    border-color: #0070ba;
    position: relative;
    white-space: nowrap;
    line-height: 1.6;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    width: max-content;
    display: block;
    svg {
      margin: 0px 9px 0px 0px;
      fill: #a8cde5;
      vertical-align: middle;
    }
    .PPTM-btm {
      fill: #a8cde5;
    }
    .PPTM-top {
      fill: #ffffff;
    }
    b {
      color: #fff;
    }
  }
  .connect-to-paypal:hover,
  .connect-to-paypal:active {
    background-image: -webkit-linear-gradient(#003087 20%, #003087) !important;
    background-image: linear-gradient(#003087 20%, #003087) !important;
    background-repeat: no-repeat !important;
  }
  .connect-to-paypal:hover b {
    text-decoration: underline;
  }
  .Polaris-Heading {
    font-weight: bold;
  }
  .badge-container {
    display: flex;
    height: 20px;
    .strong {
      width: fit-content;
      display: flex;
      .Polaris-Text--root {
        font-weight: bold;
      }
    }
  }
  .Polaris-Text--headingMd {
    font-weight: bold;
  }
  .paypal-video {
    .Polaris-Icon {
      display: none;
    }
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-15 {
    margin-top: 15px;
  }
  @media screen and (max-width: 1200px) {
    .mb-mt-20 {
      margin-top: 20px;
    }
  }
`;
