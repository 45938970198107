import { IHomePage } from '@/types/components';
import { AlphaCard, Columns } from '@shopify/polaris';
import { memo, useCallback } from 'react';
import { Progress } from 'reactstrap';
import TrackingStatusCardItem from './CardItem';
import { TrackingStatusStyled } from './styled';

interface Props {
  data: {
    allOrderFulfilled: number;
    orderPaypalStripe: number;
    orderPaidPaypal: number;
    orderStripe: number;
    orderErr: number;
    orderSubmitted: number;
    orderStripeSynced: number;
    orderPaypalSynced: number;
    totalErr: number;
    orderStripeErr: number;
    orderPaypalErr: number;
    orderPaypalPending: number;
    orderPaidByOther: number;
    orderNoTracking: number;
    orderNoTransactionId: number;
    orderUnconfirmAccount: number;
    orderInvalidTrackingNumber: number;
    orderInvalidAccount: number;
    orderOverPlan: number;
    orderPending: number;
  };
  paypalConfirmed: boolean;
}

const TrackingStatus = ({ ...props }: Props) => {
  const showGraph = !(
    props.data.orderPaypalStripe === 0 &&
    props.data.orderSubmitted === 0 &&
    props.data.allOrderFulfilled === 0
  );

  const calculatorPercent = useCallback(
    (numerator: number): number => {
      if (props.data.orderPaypalStripe > 0) {
        return Math.round((numerator / props.data.orderPaypalStripe) * 100);
      }
      return 0;
    },
    [props],
  );

  const dataAnalys: IHomePage.IDataAnalys = {
    success: calculatorPercent(props.data.orderSubmitted || 0),
    orderPending: calculatorPercent(props.data.orderPending || 0),
    noTracking: calculatorPercent(props.data.orderNoTracking || 0),
    noTransaction: calculatorPercent(props.data.orderNoTransactionId || 0),
    unconfirmAcc: calculatorPercent(props.data.orderUnconfirmAccount || 0),
    invalidTrackingNumber: calculatorPercent(props.data.orderInvalidTrackingNumber || 0),
    invalidAccount: calculatorPercent(props.data.orderInvalidAccount || 0),
    overQuota: calculatorPercent(props.data.orderOverPlan || 0),
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listTrackingStatus: Array<IHomePage.ITrackingStatusCardItemProps> = [
    {
      orderNumber: props.data.orderSubmitted,
      description: ' Add tracking successfully',
      valueProgress: dataAnalys.success,
      passDataToOrders: {
        payment: 'paypal_stripe',
        status: '1',
        type: 'filter',
      },
      color: 'success',
    },
    {
      orderNumber: props.data.orderPending,
      description: 'Pending',
      valueProgress: dataAnalys.orderPending,
      passDataToOrders: {
        payment: 'paypal_stripe',
        status: '0',
        type: 'filter',
      },
      color: 'info',
    },
    {
      orderNumber: props.data.orderNoTracking,
      description: 'No tracking code/cancelled',
      valueProgress: dataAnalys.noTracking,
      passDataToOrders: {
        payment: 'paypal_stripe',
        status: '-1',
        type: 'filter',
      },
      color: 'custom-1',
    },

    {
      orderNumber: props.data.orderInvalidTrackingNumber,
      description: ' Invalid Tracking Number',
      valueProgress: dataAnalys.invalidTrackingNumber,
      passDataToOrders: {
        payment: 'paypal_stripe',
        status: '5',
        type: 'filter',
      },
      color: 'custom-2',
    },

    {
      orderNumber: props.data.orderInvalidAccount,
      description: 'Missing PayPal account',
      valueProgress: dataAnalys.invalidAccount,
      passDataToOrders: {
        payment: 'paypal_stripe',
        status: '6',
        type: 'filter',
      },
      color: 'custom-3',
    },

    {
      orderNumber: props.data.orderNoTransactionId,
      description: 'No transaction id',
      valueProgress: dataAnalys.noTransaction,
      passDataToOrders: {
        payment: 'paypal_stripe',
        status: '4',
        type: 'filter',
      },
      color: 'custom',
    },

    {
      orderNumber: props.data.orderUnconfirmAccount,
      description: 'No account or Invalid',
      valueProgress: dataAnalys.unconfirmAcc,
      passDataToOrders: {
        payment: 'paypal_stripe',
        status: '3',
        type: 'filter',
      },
      color: 'custom-4',
    },

    {
      orderNumber: props.data.orderOverPlan,
      description: 'Over subscription plan',
      valueProgress: dataAnalys.overQuota,
      passDataToOrders: {
        payment: 'paypal_stripe',
        status: '2',
        type: 'filter',
      },
      color: 'warning',
    },
  ];

  const haveListTracking = useCallback((): boolean => {
    return !!listTrackingStatus.find((item) => item.orderNumber > 0);
  }, [listTrackingStatus]);

  return (
    <TrackingStatusStyled>
      {showGraph && props.paypalConfirmed && haveListTracking() && (
        <div className="mt-16">
          <Columns columns={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
            <AlphaCard>
              <div className="tracking-statistical-layout">
                <div className="d-flex mt-3 mb-5">
                  {listTrackingStatus.map((item, index) => {
                    return (
                      <TrackingStatusCardItem
                        orderNumber={item.orderNumber}
                        description={item.description}
                        valueProgress={item.valueProgress}
                        color={item.color}
                        passDataToOrders={item.passDataToOrders}
                        key={index}
                      />
                    );
                  })}
                </div>
                <Progress multi>
                  {dataAnalys.success > 0 ? (
                    <Progress bar color="success" value={dataAnalys.success}>
                      {dataAnalys.success}%
                    </Progress>
                  ) : null}
                  {dataAnalys.orderPending > 0 ? (
                    <Progress bar color="info" value={dataAnalys.orderPending}>
                      {dataAnalys.orderPending}%
                    </Progress>
                  ) : null}
                  {dataAnalys.noTracking > 0 ? (
                    <Progress bar color="custom-1" value={dataAnalys.noTracking}>
                      {dataAnalys.noTracking}%
                    </Progress>
                  ) : null}
                  {dataAnalys.invalidTrackingNumber > 0 ? (
                    <Progress bar color="custom-2" value={dataAnalys.invalidTrackingNumber}>
                      {dataAnalys.invalidTrackingNumber}%
                    </Progress>
                  ) : null}
                  {dataAnalys.invalidAccount > 0 ? (
                    <Progress bar color="custom-3" value={dataAnalys.invalidAccount}>
                      {dataAnalys.invalidAccount}%
                    </Progress>
                  ) : null}
                  {dataAnalys.noTransaction > 0 ? (
                    <Progress bar color="custom" value={dataAnalys.noTransaction}>
                      {dataAnalys.noTransaction}%
                    </Progress>
                  ) : null}

                  {dataAnalys.unconfirmAcc > 0 ? (
                    <Progress bar color="custom-4" value={dataAnalys.unconfirmAcc}>
                      {dataAnalys.unconfirmAcc}%
                    </Progress>
                  ) : null}
                  {dataAnalys.overQuota > 0 ? (
                    <Progress bar color="warning" value={dataAnalys.overQuota}>
                      {dataAnalys.overQuota}%
                    </Progress>
                  ) : null}
                </Progress>
              </div>
            </AlphaCard>
          </Columns>
        </div>
      )}
    </TrackingStatusStyled>
  );
};

export default memo(TrackingStatus);
