export const configCsv = {
  tracking: [
    {
      titleNotifycation: 'Missing Order Number',
      label: 'Order Number',
      key: 'orderNumber',
    },
    {
      titleNotifycation: 'Missing Tracking Number',
      label: 'Tracking Number',
      key: 'trackingNumber',
    },
    {
      titleNotifycation: 'Missing Tracking Company',
      label: 'Tracking Company',
      key: 'trackingCompany',
    },
  ],
  order: [
    {
      titleNotifycation: 'Missing Transaction Id',
      label: 'Transaction Id',
      key: 'transactionId',
    },
    {
      titleNotifycation: 'Missing Tracking Number',
      label: 'Tracking Number',
      key: 'trackingNumber',
    },
    {
      titleNotifycation: 'Missing Tracking Company',
      label: 'Tracking Company',
      key: 'trackingCompany',
    },
    {
      titleNotifycation: 'Missing tracking link',
      label: 'Tracking link',
      key: 'trackingLink',
      optional: true,
    },
  ],
};
