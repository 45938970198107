import { Row, Col } from 'reactstrap';
import bannerimage from '@/assets/affiliate/bannerimage.png';
import { LINK } from '@/constants/link';
import { apiCaller } from '@/redux/query';

const TopBanner = () => {
  // const [clickReviewMutation] = apiCaller.useClickReviewMutation()
  const handleClickMode = (note: string) => {
    // clickReviewMutation({
    // 	type: "click_affiliate_top",
    // 	note: note,
    // })
  };
  return (
    <div className="st-affiliate-top-banner">
      <Row>
        <Col lg={7}>
          <div>
            <h1>Refer friends</h1>
            <h2> {'&'} Get reward </h2>
            <h6>Spread the love and earn 20% recurring commissions for every new referral.</h6>
            <div>
              <a href={LINK.shoffiAffilate} target="_blank" rel="noreferrer">
                <button className="affiliate-button-primary" onClick={() => handleClickMode('btn_join_now')}>
                  Join now
                </button>
              </a>
              <a href={LINK.shoffiLogin} target="_blank" rel="noreferrer">
                <button className="affiliate-button-secondary" onClick={() => handleClickMode('btn_login')}>
                  Login
                </button>
              </a>
            </div>
          </div>
        </Col>
        <Col lg={5}>
          <div className="st-affiliate-top-banner-img">
            <img src={bannerimage} alt="synctrack affiliate" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TopBanner;
