import quickhelp from "@/assets/whatnew/quickhelp.png";
import synctrackblog from "@/assets/whatnew/synctrackblog.png";
import dashboardimprove from "@/assets/whatnew/dashboardimprove.png";
import couriermaping from "@/assets/whatnew/couriermaping.png";
import learningamazing from "@/assets/whatnew/learningamazing.png";
import dispute from "@/assets/whatnew/dispute.png";
import integrationthird from "@/assets/whatnew/integrationthird.png";
import syncinfo from "@/assets/whatnew/syncinfo.png";
import multistore from "@/assets/whatnew/multistore.png";
import connectstripe from "@/assets/whatnew/connectstripe.png";
import affiliate from "@/assets/whatnew/affiliate.png";
import blackfridaypingme from "@/assets/whatnew/blackfridaypingme.png";
import blackFriday from "@/assets/whatnew/blackFriday.pdf";
import { IWhatNew } from "@/types/components";
import { PATH } from "@/constants/path";

const whatnewConfig : Array<IWhatNew.IWhatNewItem> = [
	{
		filter: "Announcement",
		date: "October 6, 2022",
		title: "Take advantage of the biggest sales week by reaching the Black Friday Campaign Guide. Check it out!",
		img: blackfridaypingme,
		button: "Read more",
		file: blackFriday,
	},
	{
		filter: "Announcement",
		date: "September 5, 2022",
		title: "Synctrack Affiliate program is now released. Start to refer and get more money!",
		img: affiliate,
		href: "https://synctrack.io/affiliate/",
		button: "Check now",
	},
	{
		filter: "Improvement",
		date: "May 24, 2022",
		title: "Quick help: User can be awared of sync error instantly",
		img: quickhelp,
		href: "https://docs.synctrack.io/feature-guides/quick-help",
		button: "Read more",
	},
	{
		filter: "Announcement",
		date: "May 23, 2022",
		title: "Synctrack blog is now published - let’s discover now",
		img: synctrackblog,
		href: "https://blog.synctrack.io/",
		button: "Check now",
	},
	{
		filter: "Improvement",
		date: "April 28, 2022",
		title: "Dashboard improved: Click titles of order indicators to filter",
		img: dashboardimprove,
		href: "https://docs.synctrack.io/feature-guides/filter-orders-based-on-indicator-titles",
		button: "Read more",
	},
	{
		filter: "New feature",
		date: "April 20, 2022",
		title: "Courier mapping rule: Synchronize the courier name from Shopify to </br> PayPal according to your preferences",
		img: couriermaping,
		href: "https://docs.synctrack.io/feature-guides/how-to-add-courier-mapping-rule",
		button: "Read more",
	},
	{
		filter: "Announcement",
		date: "April 23, 2022",
		title: "Success story: Amazing Leaning - youtuber review",
		img: learningamazing,
		href: "https://www.youtube.com/watch?v=HDiqERu0I2Y",
		button: "Read more",
	},
	{
		filter: "New feature",
		date: "April 13, 2022",
		title: "Dispute administration: Manage all conditions of disputes sellers get ",
		img: dispute,
		href: "https://docs.synctrack.io/feature-guides/how-to-get-dispute-administration",
		button: "Read more",
	},
	{
		filter: "Improvement",
		date: "April 3, 2022",
		title: "Integration with third-party checkouts and gateways is now displayed",
		img: integrationthird,
		button: "Check now",
		href: "",
		tab: PATH.SETTINGS,
		activeKey : 'integrations'
	},
	{
		filter: "New feature",
		date: "March 25, 2022",
		title: " Sync Info to Shopify Order note",
		img: syncinfo,
		href: "https://docs.synctrack.io/feature-guides/sync-info-to-shopify-order-note-available-for-pro-and-above-plan",
		button: "Read more",
	},
	{
		filter: "Improvement",
		date: "Feb 25, 2022",
		title: "Multi-store feature is now opened for users to freely set up",
		img: multistore,
		href: "https://docs.synctrack.io/feature-guides/how-to-set-up-and-what-you-should-know-about-multi-store-feature",
		button: "Read more",
	},
	{
		filter: "New feature",
		date: "Feb 19, 2022",
		title: "Connect with Stripe: Let Synctrack sync tracking info to Stripe",
		img: connectstripe,
		href: "https://docs.synctrack.io/feature-guides/how-to-connect-the-app-with-your-stripe-account-add-tracking-auto-app",
		button: "Read more",
	},
];

export default whatnewConfig;
