const getUrlParameter = (sParam: string): string | null => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(sParam);
};

const getUrlParameterJson = (): string => {
  var search = window.location.search.substring(1);
  if (search !== '') {
    var urlParams = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
      return key === '' ? value : decodeURIComponent(value);
    });
    return JSON.stringify(urlParams);
  }
  return '';
};

const getUrlParameterValue = function getUrlParameterValue() {
  var search = window.location.search.substring(1);
  return search;
};

function lsTest() {
  var test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
const config = {
  instance: getUrlParameter('instance'),
  instanceId: getUrlParameter('instanceId') || undefined,
  code: getUrlParameter('code'),
  scope: getUrlParameter('scope'),
  role: getUrlParameter('role'),
  checkLocalStorage: lsTest(),
  rootLink: process.env.REACT_APP_ROOT_LINK,
  helpSupportUrl: 'https://apps.omegatheme.com/helpdesk/server/processDataHelp.php',
  urlParams: getUrlParameterJson(),
  urlParamsValue: getUrlParameterValue(),
  pathName: process.env.REACT_APP_PATH_NAME,
  mode: 'live',
  apiKey: process.env.REACT_APP_API_KEY,
  redirectUri: process.env.REACT_APP_ROOT_LINK + 'get_code.php',
  appName: 'Synctrack - Add Tracking Info',
  dashboardNoti: 'Make sure you know how these changes affect your store.',
};
export default config;
