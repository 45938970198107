import config from '@/config';
import slice from '@/redux/slice';
import { IWhatNew } from '@/types/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import whatnewConfig from './config';
import { WhatNewStyled } from './styled';

const WhatNew = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState('All');
  const navigate = useNavigate();
  const filter = [
    {
      filter: 'All',
      color: '#005CB1',
      text: '#fff',
    },
    {
      filter: 'New feature',
      color: '#007F5F',
      text: '#fff',
      gradient: ['#0055A5', '#bcd0e6'],
    },
    {
      filter: 'Improvement',
      color: '#FFD233',
      text: '#000',
      gradient: ['#EFDA90', '#007F5F'],
    },
    {
      filter: 'Announcement',
      color: '#863BE8',
      text: '#fff',
      gradient: ['#5EFCE8', '#736EFE'],
    },
  ];

  const getItem = (item: IWhatNew.IWhatNewItem, index: number) => {
    if (show === 'All') {
      return <div key={index}>{cardMarkup(item)}</div>;
    } else {
      if (item.filter === show) {
        return <div key={index}>{cardMarkup(item)}</div>;
      }
    }
  };

  const getStyle = (value: string) => {
    let obj = filter.find((obj) => obj.filter === value);
    if (obj) {
      let style = {
        backgroundColor: obj.color,
        color: obj.text,
      };
      return style;
    } else {
      let style2 = {
        backgroundColor: filter[0].color,
        color: filter[0].text,
      };
      return style2;
    }
  };

  const getRandom = (list: Array<string>) => {
    return list[Math.floor(Math.random() * list.length)];
  };

  const getGradient = (value: string) => {
    let obj = filter.find((obj) => obj.filter === value);
    let position = getRandom(['left', 'top', 'right', 'bottom', 'right', 'left', 'bottom', 'top']);
    if (obj) {
      let style = {
        backgroundImage: `linear-gradient(to ${position}, ${obj.gradient && obj.gradient[0]}, ${
          obj.gradient && obj.gradient[1]
        })`,
        borderRadius: '10px',
      };
      return style;
    } else {
      let style = {
        backgroundColor: `linear-gradient(to ${position}, #0055A5, #FFFFFF)`,
      };
      return style;
    }
  };

  const cardMarkup = (item: IWhatNew.IWhatNewItem): JSX.Element => {
    return (
      <Card className="mt-5">
        <div className="what-new-card-top">
          <span className="what-new-badge" style={getStyle(item.filter)}>
            {item.filter}
          </span>
          <span>{item.date}</span>
        </div>
        <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
        <div className="what-new-background" style={getGradient(item.filter)}>
          <img src={item.img} alt={item.title} />
        </div>
        <div className="text-center what-new-button">
          {item.tab && (
            <span className="">
              <span
                onClick={() => {
                  navigate((config.pathName || '') + item.tab?.pathname + config.urlParamsValue);
                  if (item.activeKey) {
                    dispatch(slice.settingsPageSlice.actions.handleActiveKey(item.activeKey));
                  }
                }}
              >
                {item.button}
              </span>
            </span>
          )}
          {item.href && (
            <span className="what-new-button">
              <a href={item.href} target="_blank" rel="noreferrer">
                {item.button}
              </a>
            </span>
          )}
          {item.file && (
            <span className="what-new-button">
              <a href={item.file} download="BLACK_FRIDAY_CAMPAIGN_GUIDE.pdf" target="_blank" rel="noreferrer">
                {item.button}
              </a>
            </span>
          )}
        </div>
      </Card>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [show]);

  return (
    <WhatNewStyled className="what-new-wrapper">
      <Container>
        <Row>
          <Col md={11} className="">
            {whatnewConfig.map((item, index) => getItem(item, index))}
          </Col>
          <Col md={1} className="what-new-filter-wrap">
            <div className="what-new-filter">
              {filter.map((item, index) => (
                <div
                  key={index}
                  className={show === item.filter ? `what-new-filter-inner active-badge` : 'what-new-filter-inner'}
                  onClick={() => {
                    setShow(item.filter);
                  }}
                >
                  <div className="circle-point" style={{ backgroundColor: filter[index].color }}></div>
                  <span>{item.filter}</span>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </WhatNewStyled>
  );
};

export default WhatNew;
