import { AlphaCard, Button, ButtonProps } from '@shopify/polaris';
import { memo } from 'react';
import { CardStyled } from './styled';

interface Props {
  title: string;
  actions?: {
    content: string;
    action: any;
    buttonProps: ButtonProps;
  };
  children: any;
  display?: string;
  status?: string;
}

const CustomCard = ({ ...props }: Props) => {
  return (
    <CardStyled status={props.status} style={{ display: props.display || 'block' }}>
      <AlphaCard padding={{ xl: '0' }}>
        <div className="custom-card-header">
          <div className="custom-card-title">{props.title}</div>
          {props.actions && (
            <Button {...props.actions.buttonProps} onClick={props.actions.action}>
              {' '}
              {props.actions?.content}{' '}
            </Button>
          )}
        </div>
        <div className="custom-card-content">{props.children}</div>
      </AlphaCard>
    </CardStyled>
  );
};

export default memo(CustomCard);
