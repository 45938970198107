import styled from "styled-components";

export const OrderTableStyled = styled.div`
.Polaris-ResourceList__ResourceListWrapper {
  overflow-x: auto;
}
.Polaris-Button__Text {
  color : #202223;
}
  .Polaris-ResourceList {
    min-width: 1470px;
  }
  .Polaris-ResourceItem__Button[id=-1] {
    background-color: red;
  }
    .order-table-title {
    font-weight: bold;
    padding : 12px 20px 7px;
    border-bottom: 1px solid #dfe3e8;
    font-size: 10px;
    }
    .resource-list-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 25px;
  gap: 25px;
  padding: 30px;
  min-height: 508px;
  background: rgba(0, 0, 0, 0.02);
  border: 8px solid #fff;
  border-radius: 16px;
  margin-top: -8px;
}

.resource-list-empty .resource-list-empty__icon .Polaris-Icon {
  height: 100px;
  width: 100px;
}

.resource-list-empty .resour-list-empty__text {
  font-size: 18px;
}
.vertical-center {
  line-height: 26px;
  align-items : center;
  .Polaris-Text--root {
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-truncate {
    font-size : 14px;
    margin: 0;
    a {
      text-decoration : none;
    }
  }
  .Polaris-Icon {
    margin: 0;
  }
  .break-line {
    white-space: pre-line;
    line-break: auto;
  }
}
.font-weight-bold {
    font-weight: bold;
}
.Polaris-Tag__TagText {
  font-size: 14px;
}
.export-order-document {
  text-decoration : none;
  &:hover {
    text-decoration : underline;
  }
}
.p-16 {
  padding: 16px;
}
`