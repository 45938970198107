import styled from 'styled-components';

export const HomePageStyled = styled.div`
  .Polaris-Select {
    width: auto;
  }
  .DayPickerInput input {
    width: 150px;
  }
  .receive-email-input {
    width: 230px;
    height: 36px;
    padding: 8px 16px;
    &:focus-visible {
      border: none;
    }
  }

  .dashboard-filter-bar {
    display: flex;
    position: absolute;
    right: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: transparent;
    .Polaris-TextField {
      min-width: 150px;
    }
  }

  .statistical-layout {
    b:hover {
      cursor: pointer;
      color: rgba(0, 128, 96, 1);
    }
    .card-body:hover {
      cursor: pointer;
    }
    .card-body:hover .text-muted {
      color: rgba(0, 128, 96, 1) !important;
    }
    .card-body:hover .text-muted {
      color: rgba(0, 128, 96, 1) !important;
    }
    .title {
      margin-top: 5px;
      font-size: 14px;
    }
    .value {
      font-size: 28px;
      font-weight: 400;
      margin-top: 15px;
      color: #3c3c3c;
    }
  }
  .statistical-layout-row {
    margin-bottom: 30px;
  }
  .statistical-layout-row .statistical-layout {
    display: inline-flex;
  }

  .statistical-layout-text {
    text-align: left;
  }

  .statistical-layout-icon {
    width: 60px;
    height: 60px;
    display: flex;
  }

  .statistical-layout-icon .Polaris-Icon {
    width: 30px;
    height: 30px;
  }

  .statistical-layout-icon .Polaris-Icon svg {
    fill: #007f5f;
  }

  .active-plan-status .Polaris-Badge {
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 20px;
  }

  .app-credentials .Polaris-Layout {
    width: 120%;
  }

  .app_status_table {
    font-size: 16px;
  }

  .tracking-statistical-layout .Polaris-Box {
    margin-top: 0 !important;
    width: 50%;
    overflow-x: hidden;
  }

  .tracking-statistical-layout .Polaris-Card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: rgb(255, 255, 255);
    background-clip: border-box;
    border: 1px solid #eaeaea;
    border-radius: 2.5px;
  }

  .tracking-statistical-layout .progress-xs {
    height: 4px;
  }

  .card-body {
    padding: 15px;
  }

  .progress {
    height: 20px;
  }

  .progress-bar {
    height: 18px;
    font-size: 14px;
  }

  .plan-layout {
    display: flex;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.12) !important;
    height: 100%;
    padding: 20px;
  }

  .plan-layout-table {
    width: 100%;
    font-size: 16px;
    line-height: 32px;
  }

  .plan-layout-table th {
    position: relative;
    padding: 15px;
    color: #333;
    border: 1px solid rgb(221, 221, 221);
  }

  img.best-seller {
    right: -10px;
    position: absolute;
    width: 75px;
    top: 0;
  }

  .plan-layout-table td {
    padding: 10px;
    font-size: 16px;
    text-align: center;
    background: rgb(248, 248, 248);
    border: 1px solid rgb(221, 221, 221);
    font-weight: 500;
  }

  .plan-layout-table td.active {
    background-color: #5563c1;
    color: #fff;
    border: 1px solid rgb(221, 221, 221);
  }

  .chart-layout {
    border-top: 1px solid rgb(223, 227, 232);
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.12) !important;
    height: 100%;
    display: flex;
    width: 100%;
    padding: 30px 30px;
    align-items: center;
  }

  .plan-layout-table .plan-layout-table-price {
    font-size: 32px;
  }

  .plan-layout-table .plan-layout-table-noti {
    font-size: 14px;
    font-weight: 300;
  }
  .jc-e {
    justify-content: end;
  }

  .mb-16 {
    margin-bottom: 16px;
  }
  .mr-4 {
    margin-right: 4px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  @media screen and (max-width: 768px) {
    .app_status_table_view_plan {
      display: none;
    }
  }

  .chart-not-submitted .apexcharts-legend {
    display: none;
  }

  .tracking-statistical-layout .progress-bar {
    background-color: #00a0ac !important;
  }

  .tracking-statistical-layout .progress-bar.bg-danger {
    background-color: #dc3545 !important;
  }

  .tracking-statistical-layout .bg-success {
    background-color: #007f5f !important;
  }

  .tracking-statistical-layout .bg-custom-1 {
    background-color: rgb(179, 179, 179) !important;
  }

  .tracking-statistical-layout .bg-custom-3 {
    background-color: #dc3545 !important;
  }

  .tracking-statistical-layout .bg-custom-2 {
    background-color: rgb(107, 107, 107) !important;
  }

  .tracking-statistical-layout .bg-custom-4 {
    background-color: rgb(107, 39, 0) !important;
  }

  .tracking-statistical-layout .bg-warning {
    background-color: rgb(255, 193, 7) !important;
  }

  .tracking-statistical-layout .progress-bar.bg-custom {
    background-color: rgb(150, 150, 150) !important;
  }

  .tracking-statistical-layout .progress-bar.bg-other {
    background-color: rgb(5, 26, 160) !important;
  }

  .apexcharts-pie-label,
  .apexcharts-datalabel,
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    font-size: 16px;
    font-weight: 700;
  }

  .account-integrated .Polaris-Badge {
    font-weight: bold;
    padding: 5px 10px;
  }

  .app-credentials .chart .Polaris-Card__Section {
    padding-top: 0;
    padding-bottom: 0;
  }

  .bussiness-man {
    position: absolute;
    right: 25px;
    top: 10px;
    width: 120px;
    border-radius: 50%;
  }

  .iframe-pollly iframe {
    min-height: 380px !important;
    height: 380px !important;
    margin-top: 0 !important;
  }
  .feature-requests .iframe-pollly iframe {
    min-height: 600px !important;
  }

  /* feedback */
  .feedback-card .feedback-card-list-icon {
    text-align: center;
  }
  .feedback-card .confetti {
    z-index: 0;
  }

  .feedback-card .feedback-card-list-icon img {
    margin-right: 5px;
    filter: hue-rotate(180deg);
    opacity: 0.8;
    cursor: pointer;
  }

  .feedback-card .feedback-card-list-icon img.active,
  .feedback-card .feedback-card-list-icon img:hover {
    filter: hue-rotate(0);
    opacity: 1;
  }

  .feedback-card-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .feedback-card-list-content {
    text-align: center;
    cursor: pointer;
    justify-content: center;
    width: max-content;
    margin: auto;
  }

  .feedback-card-list-content span {
    background: #eee;
    border: 1px solid #ddd;
    padding: 5px;
    margin-right: 10px;
    border-radius: 8px;
  }

  .feedback-card-list-content span:hover,
  .feedback-card-list-content span.active {
    background: #008060;
    border: 1px solid #008060;
    color: #fff;
  }

  .feedback-card-link {
    font-size: 16px;
    line-height: 24px;
  }
`;
