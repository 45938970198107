/* eslint-disable no-lone-blocks */
import CustomCard from '@/components/card';
import { convertToCamelCase, handleToastMuatation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { ICsv } from '@/types/components';
import { Icon } from '@shopify/polaris';
import { PageDownMajor } from '@shopify/polaris-icons';
import { memo } from 'react';
import { CSVLink } from 'react-csv';
import CSVReader from 'react-csv-reader';
import { useDispatch } from 'react-redux';
import { configCsv } from './config';
import { CsvStyled } from './styled';
const UploadCSV = ({ ...props }: ICsv.IProps) => {
  const dispatch = useDispatch();
  const [uploadCsv] = apiCaller.useHandleCsvMutation();
  const uploadOrderCSV = async (orders: any) => {
    const input = {
      action: props.action,
      orders,
      method: props.method,
    };
    uploadCsv(input).then((res) => {
      dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
    });
  };

  const handleFileCsvUpload = async (data: any) => {
    if (data.length === 0) {
      dispatch(
        slice.commonSettingSlice.actions.handleToast({
          isOpen: true,
          content: 'Unsupported format',
          error: true,
        }),
      );
    } else {
      const { length } = configCsv[props.type];
      var dataExactly = true;
      for (let i = 0; i < length; i++) {
        const findItemIncorrect = data.find((item: any) => {
          if ('optional' in configCsv[props.type][i]) return false;
          else return item[configCsv[props.type][i].key] === undefined;
        });

        if (findItemIncorrect) {
          dispatch(
            slice.commonSettingSlice.actions.handleToast({
              isOpen: true,
              content: configCsv[props.type][i].titleNotifycation,
              error: true,
            }),
          );
          dataExactly = false;
          break;
        }
      }
      if (dataExactly) {
        await uploadOrderCSV(data);
      }
    }
  };

  const papaparseOptions = {
    header: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => convertToCamelCase(header),
    transform: (a: any) => (!a ? undefined : String(a)),
  };

  return (
    <CsvStyled>
      <CustomCard title={props.title}>
        <div className="csvlink-btn">
          <CSVLink
            data={[]}
            filename={'listOrders_' + Date.now() + '.csv'}
            className="Polaris-Button Polaris-Button--newDesignLanguage"
            headers={configCsv[props.type]}
          >
            <Icon source={PageDownMajor} />
            <span className="ml-2">Download Template</span>
          </CSVLink>
        </div>
        <div className="text-center">
          <CSVReader
            cssClass="react-csv-input"
            label={props.description}
            onFileLoaded={handleFileCsvUpload}
            parserOptions={papaparseOptions}
            onError={() => console.log('error')}
          />
        </div>
      </CustomCard>
    </CsvStyled>
  );
};

export default memo(UploadCSV);
