import { Modal } from "@shopify/polaris";
import styled from "styled-components";

export const AnalysisStyled =styled.div`
.Polaris-Layout {
    margin: 0 !important;
}
.Polaris-Layout__Section {
    margin-left: 0;
    max-width: 100vw;
}
.Polaris-Layout__Section--secondary {
    margin-left: 16px;
}
`

export const IndicatorFilterStyled = styled.div`
.indicator-filter-card {
    text-align: center;
}
.indicator-filter-card-title:hover {
    cursor: pointer;
    color: rgba(0, 110, 82, 1);
}

.indicator-filter-card-value {
    font-weight: 500;
    font-size: 20px;
}
.indicator-card-title {
    padding: 12px 20px 7px;
    border-bottom: 1px solid #dfe3e8;
    font-weight: bold;
}

.indicator-card-content {
    padding: 20px;
}
.mb-30 {
    margin-bottom: 30px ;
}
@media screen and (max-width : 1200px) {
    .mb-mt-20 {
        margin-top: 20px ;
    }
}
`

export const QuickHelpStyled = styled(Modal)`
    .modal-plan-table {
        .Polaris-Box {
            background-color: #008060;
        }
    }
`

export const QuickHelpItemStyled = styled.div`
    margin-bottom: 30px;
    .quick-help-heading {
        user-select: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .heading-title {
        display: flex;
        align-items: center;
        p {
            margin: 0;
        }
        .Polaris-Icon {
            margin: 0;
            margin-right: 10px;
        }
    }
    }
    .solution-title {
        display: flex;
        align-items: center;
        p {
            margin: 0;
        }
        .Polaris-Icon {
            margin: 0;
            margin-right: 10px;
        }
    }
    .bold {
        font-weight: bold;
    }

    /* old css */
    .quick-help-container p {
    /* font-size: 1.2rem; */
}

.quick-help-heading, .quick-help-heading-solution {
    margin: 5px 0;
}

.quick-help-heading, .quick-help-heading > h2, .quick-help-heading-solution, .quick-help-heading-solution> h2{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.quick-help-heading h2 > span, .quick-help-heading-solution h2 > span {
    margin-right: 10px;
}

.quick-help-line {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .5);
    margin: 10px 0;
}

.quick-help-content .underline {
    text-decoration: underline;
}
.quick-help-inner button {
    margin-top: 10px;
}
`