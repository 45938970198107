import { memo } from 'react';
import { Col, Row } from 'reactstrap';
import Card from '@/components/card'
const reasonConfig = [
    {
        title: "20% Recurring Commission",
        description: `<span>You will get a 20% commission for each new transaction as long as the referral is active.</span>`,
        icon:    <svg width="52" height="60" viewBox="0 0 52 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43.1961 0.519708L43.2845 27.0245L28.0507 3.5797C27.4257 2.61578 26.3466 2.03971 25.1673 2.03971C24.5275 2.03971 23.8995 2.21368 23.357 2.54449L22.9561 2.78974L22.9914 1.53782C23.018 0.66517 23.7698 -0.024973 24.6749 0.00069333" fill="#006EBA"/>
        <path d="M44.7794 32.5399L50.1836 40.8587L50.101 43.739C50.0774 44.6145 49.3227 45.3018 48.4205 45.279L44.0246 45.1621L43.3465 45.1421L23.3924 44.6059C22.4873 44.5803 21.7768 43.8531 21.8004 42.9776L22.8971 4.84598L24.2976 3.99329C24.5688 3.82788 24.8695 3.75085 25.1673 3.75085C25.7128 3.75085 26.2435 4.01325 26.553 4.49235L43.2964 30.2613L44.0364 31.3992L44.7794 32.5285V32.5399V32.5399Z" fill="#006EBA"/>
        <path d="M51.2923 2.29922L50.2722 37.7815L44.7677 29.3059L44.6704 0.539673L49.7031 0.6737C50.6053 0.699366 51.3188 1.42656 51.2923 2.29922Z" fill="#006EBA"/>
        <path d="M44.8206 45.1591L44.0246 45.162H43.3465V45.142L43.2964 30.2612L44.0364 31.3991L44.7794 32.5284V32.5398L44.8206 45.1591Z" fill="white"/>
        <path d="M51.7489 43.2685L50.1834 40.8587L44.7792 32.5399V32.5285L44.0362 31.3992L43.2962 30.2613L26.5528 4.49235C26.2432 4.01325 25.7125 3.75085 25.1671 3.75085C24.8693 3.75085 24.5686 3.82788 24.2974 3.99329L22.8969 4.84598L0.76409 18.298C0.000482349 18.7657 -0.229482 19.741 0.25109 20.4825L25.4443 59.2586C25.7568 59.7377 26.2875 60.0001 26.8329 60.0001C27.1307 60.0001 27.4314 59.923 27.6997 59.7576L51.2359 45.453C51.9995 44.9853 52.2295 44.0099 51.7489 43.2685ZM9.1225 27.7631L5.01258 21.4949L6.00616 20.8846L10.119 27.1529L9.1225 27.7631ZM10.4581 18.6345L13.8132 16.5926C14.0727 16.4357 14.4117 16.5099 14.5739 16.7609L18.3831 22.6213C18.5452 22.8694 18.4657 23.2002 18.2091 23.3571L14.851 25.399C14.5945 25.5558 14.2525 25.4788 14.0904 25.2307L10.2841 19.3702C10.122 19.1193 10.1986 18.7913 10.4581 18.6345ZM15.3847 37.4222L11.2748 31.154L12.2713 30.5437L16.3812 36.8119L15.3847 37.4222ZM21.3904 46.4625L17.2804 40.1942L18.274 39.5839L22.3869 45.8494L21.3904 46.4625ZM27.2516 55.5113L23.1387 49.243L24.1352 48.6327L28.2451 54.901L27.2516 55.5113Z" fill="#F3B634"/>
        <path d="M4.36374 48.1821C5.51495 48.1821 6.44819 47.2794 6.44819 46.1659C6.44819 45.0524 5.51495 44.1497 4.36374 44.1497C3.21254 44.1497 2.2793 45.0524 2.2793 46.1659C2.2793 47.2794 3.21254 48.1821 4.36374 48.1821Z" fill="#006EBA"/>
        <path d="M11.6018 2.53024C12.1766 2.53024 12.6425 2.07952 12.6425 1.52354C12.6425 0.967565 12.1766 0.516846 11.6018 0.516846C11.027 0.516846 10.561 0.967565 10.561 1.52354C10.561 2.07952 11.027 2.53024 11.6018 2.53024Z" fill="#006EBA"/>
    </svg>
    },
    {
        title: "Easy to start ",
        description: `<span>Sign-up takes less than 1 minute and is <b>completely free</b>.</span>`,
        icon:  <svg width="52" height="60" viewBox="0 0 52 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0L16.5863 43.0236L27.3214 34.7351L34.5486 36.8163L36.0601 23.952L43.6914 14.9567L0 0ZM7.26526 6.74365L32.7662 22.7451L32.4358 31.8046L7.26526 6.74365Z" fill="#006EBA"/>
        <path d="M37.2461 39.9841C38.5104 41.4563 39.6845 43.0123 40.83 44.5935C41.9708 46.1774 43.0783 47.7921 44.1525 49.4375C45.2267 51.0801 46.27 52.7535 47.2777 54.4603C48.2806 56.17 49.2574 57.902 50.1391 59.7206C48.8724 58.2484 47.7007 56.6952 46.5552 55.114C45.4145 53.5301 44.307 51.9154 43.2327 50.27C42.1585 48.6246 41.1152 46.9513 40.1099 45.2472C39.1046 43.5319 38.1302 41.7999 37.2461 39.9841Z" fill="#F56AA1"/>
        <path d="M26.3184 40.2635C27.5827 41.7358 28.7568 43.2918 29.9023 44.8729C31.043 46.4569 32.1505 48.0715 33.2248 49.7169C34.299 51.3596 35.3423 53.0329 36.35 54.7398C37.3529 56.4494 38.3297 58.1814 39.2114 60C37.9447 58.5278 36.773 56.9746 35.6275 55.3935C34.4867 53.8095 33.3792 52.1948 32.305 50.5494C31.2308 48.904 30.1875 47.2307 29.1798 45.5266C28.1792 43.8114 27.2025 42.0794 26.3184 40.2635Z" fill="#F56AA1"/>
        <path d="M39.1069 23.9688C40.3713 25.441 41.5453 26.997 42.6908 28.5781C43.8316 30.1621 44.9391 31.7767 46.0133 33.4221C47.0876 35.0648 48.1309 36.7381 49.1386 38.445C50.1415 40.1546 51.1183 41.8866 52 43.7052C50.7333 42.233 49.5616 40.6798 48.4161 39.0986C47.2753 37.5147 46.1678 35.9 45.096 34.2546C44.0217 32.6092 42.9784 30.9359 41.9707 29.2318C40.9678 27.5166 39.991 25.7846 39.1069 23.9688Z" fill="#F56AA1"/>
    </svg>
    },
    {
        title: "30 cookie days",
        description: `<span>If someone clicks your link and signs up within 30 days, you get the credit.</span>`,
        icon:  <svg width="52" height="60" viewBox="0 0 52 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3585 32.8274C12.3261 33.8545 11.3059 35.8977 11.4777 38.5193L7.64374 40.44L5.42606 35.6455C5.05018 34.8335 4.65014 34.5516 4.18298 34.5516C3.95745 34.5516 3.71582 34.6147 3.45808 34.7222C2.24184 35.2042 0.888683 34.7037 0.378564 33.5838C0.209419 33.2093 0.166458 32.8311 0.249688 32.4566C0.373191 31.9041 0.757121 31.3961 1.30483 31.0586C1.59748 30.8806 2.08881 30.5803 2.2499 29.8461C2.4083 29.1268 2.12639 28.5187 1.99215 28.2257C1.35584 26.85 0.719537 25.4743 0.0751752 24.0801L0 23.9132L2.58282 22.6191C3.19764 22.3151 3.80979 22.0073 4.42193 21.6958C4.66894 21.5697 5.18174 21.3102 5.3831 20.6019C5.57641 19.9159 5.3482 19.293 5.2596 19.0557C5.09851 18.6218 5.07435 18.1991 5.18712 17.8023C5.34284 17.2535 5.76436 16.7715 6.33623 16.486C7.49339 15.9038 8.85997 16.3488 9.5097 17.5131C9.9715 18.34 10.6159 18.5996 11.3273 18.2436C12.3261 17.743 13.3222 17.2461 14.3183 16.7456L16.2272 15.7926L18.4395 20.5686C19.0033 21.7848 19.5993 21.833 20.4397 21.4993C21.6183 21.0358 22.9876 21.5438 23.4897 22.6377C23.6615 23.0122 23.7071 23.3978 23.6212 23.7798C23.4977 24.3323 23.1219 24.8328 22.5849 25.1592C22.2654 25.352 21.7848 25.6449 21.621 26.3754C21.4626 27.0948 21.7418 27.6992 21.8761 27.9847C22.4936 29.327 23.1138 30.6619 23.742 32.0228L23.879 32.3194L20.2303 34.1438C18.4395 32.0969 16.0419 31.9745 14.3585 32.8274Z" fill="#F3B634"/>
        <path d="M33.1122 51.5493L33.2679 51.8868L29.6192 53.7111C27.8284 51.6606 25.4362 51.5382 23.7474 52.3911C21.715 53.4182 20.6975 55.4576 20.8666 58.0829L17.0327 60L14.815 55.2092C14.4391 54.3971 14.0391 54.1153 13.5719 54.1153C13.3464 54.1153 13.1047 54.1783 12.8443 54.2822C11.6308 54.7679 10.2776 54.2673 9.76748 53.1475C9.59834 52.773 9.55269 52.3948 9.63861 52.0202C9.75942 51.4677 10.146 50.9597 10.6937 50.6223C10.9864 50.4443 11.4777 50.144 11.6388 49.4098C11.7972 48.6904 11.5153 48.0823 11.3811 47.7894L9.38623 43.4732L11.9476 42.1939C12.5705 41.8824 13.1907 41.5709 13.8109 41.2595C14.0552 41.1334 14.5707 40.8738 14.772 40.1656C14.9653 39.4796 14.7344 38.8566 14.6485 38.6193C14.4847 38.1818 14.4633 37.7628 14.576 37.366C14.7318 36.8209 15.1533 36.3389 15.7251 36.0497C16.8823 35.4712 18.2489 35.9125 18.8986 37.0805C19.3604 37.9074 20.0048 38.1633 20.7163 37.8073C21.6989 37.3178 22.6816 36.8247 23.6642 36.3352L25.6161 35.36L27.8284 40.136C28.3922 41.3522 28.9882 41.3967 29.8286 41.0667C31.0072 40.5995 32.3765 41.1112 32.8786 42.205C33.0504 42.5796 33.0961 42.9652 33.0102 43.3471C32.8866 43.8996 32.5108 44.4002 31.9738 44.7265C31.6543 44.9194 31.1737 45.2085 31.0099 45.9427C30.8515 46.6621 31.1308 47.2628 31.265 47.5521C31.8798 48.8796 32.4893 50.1996 33.1122 51.5493Z" fill="#006EBA"/>
        <path d="M46.6731 45.9688C46.4824 46.6622 46.7187 47.2777 46.8449 47.6041C46.9952 47.9934 47.0167 48.3827 46.9093 48.7646C46.7563 49.3245 46.3482 49.8178 45.7898 50.107C44.6702 50.6854 43.2553 50.285 42.6297 49.2096C41.9826 48.0935 41.4027 48.112 40.6187 48.5199C39.3408 49.1874 38.0628 49.8511 36.7606 50.5297L35.9069 50.9784L34.1966 47.4409C35.1283 46.41 35.4934 45.2976 35.633 44.4781C35.8424 43.2397 35.6572 41.9085 35.1282 40.8257C34.3658 39.2646 32.8032 37.5663 29.6458 38.015L27.9839 34.5777L32.2984 32.3268C32.6018 32.1711 33.1119 31.9041 33.3079 31.1848C33.4986 30.4877 33.2596 29.8721 33.1442 29.5755C32.9884 29.1713 32.9643 28.7671 33.0717 28.3778C33.2247 27.8215 33.6355 27.3321 34.202 27.0429C35.3323 26.4644 36.7204 26.8686 37.362 27.9625C38.0037 29.0563 38.6105 29.0304 39.381 28.6299L44.0741 26.1826L45.4729 29.0786C45.7844 29.7275 46.0958 30.369 46.4073 31.0105C46.9899 32.2045 47.6906 32.1043 48.3001 31.8485C49.5485 31.3257 50.9017 31.7817 51.4494 32.9127C51.6293 33.2835 51.683 33.658 51.6078 34.0251C51.495 34.5813 51.095 35.1116 50.5151 35.4787C50.3433 35.5862 49.817 35.9163 49.6559 36.6949C49.5861 37.0287 49.5646 37.5441 49.8465 38.13C50.3298 39.1274 50.8131 40.1286 51.2964 41.126L51.9998 42.5796L47.7014 44.8267C47.3658 44.9972 46.8664 45.2606 46.6731 45.9688Z" fill="#F3B634"/>
        <path d="M40.7722 17.2276C40.6943 17.5502 40.6513 18.0619 40.9064 18.6589C41.293 19.5525 41.6769 20.4462 42.0609 21.3435C42.273 21.8367 42.4851 22.3336 42.6972 22.8268L42.375 23.0196L38.1867 25.2036C38.1705 25.211 38.1598 25.2147 38.1464 25.2221C37 23.3459 34.8548 22.6673 33.0157 23.6129C31.9525 24.1579 31.1792 25.1406 30.8382 26.3828C30.6208 27.1726 30.5993 28.0106 30.7684 28.8153C29.324 29.4827 27.8742 30.1539 26.3948 30.8399L24.8537 27.2542C25.8498 26.2567 26.2633 25.1406 26.4351 24.3137C26.6821 23.1012 26.5532 21.7811 26.0753 20.6835C25.38 19.0816 23.8872 17.3018 20.7164 17.591L19.2129 14.0943C20.1311 13.6678 21.0466 13.2451 21.9622 12.8224L23.6483 12.0437C23.9597 11.8991 24.4752 11.6618 24.6926 10.961C24.9021 10.2824 24.6953 9.65576 24.5933 9.35912C24.4591 8.95494 24.4537 8.55816 24.5745 8.17623C24.7544 7.6089 25.192 7.12688 25.7854 6.8599C26.9372 6.33335 28.3011 6.79685 28.8917 7.91297C29.4931 9.05505 30.1026 9.05504 30.8893 8.69165L35.679 6.47424L36.9167 9.35541C37.2067 10.0303 37.494 10.7088 37.7893 11.38C38.3263 12.6222 39.0351 12.5592 39.658 12.3293C40.9225 11.8658 42.2488 12.3775 42.7428 13.5195C42.9012 13.8904 42.9361 14.2611 42.8502 14.6171C42.7106 15.1882 42.2837 15.711 41.6716 16.0521C41.4944 16.156 40.9601 16.46 40.7722 17.2276Z" fill="#006EBA"/>
        <path d="M16.1813 3.50038C16.8812 3.50038 17.4486 2.7168 17.4486 1.75019C17.4486 0.783581 16.8812 0 16.1813 0C15.4814 0 14.9141 0.783581 14.9141 1.75019C14.9141 2.7168 15.4814 3.50038 16.1813 3.50038Z" fill="#006EBA"/>
        <path d="M33.7487 5.25429C34.4486 5.25429 35.0159 4.47071 35.0159 3.5041C35.0159 2.53749 34.4486 1.75391 33.7487 1.75391C33.0488 1.75391 32.4814 2.53749 32.4814 3.5041C32.4814 4.47071 33.0488 5.25429 33.7487 5.25429Z" fill="#F3B634"/>
    </svg>
    },
    {
        title: "Intuitive Dashboard",
        description: `<span>Easy-to-use dashboards to launch, manage and monitor campaigns</span>`,
        icon:  <svg width="52" height="60" viewBox="0 0 52 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.5583 21.6815L33.6105 24.3293L37.675 28.6895L32.1018 33.6976L28.6576 30.3167L27.5906 29.1694L17.7569 18.6197L16.6874 17.4724L12.1489 12.3961L17.7742 7.34167L22.5435 12.4594L36.4169 0L47.4789 11.8724L39.1241 19.3772L36.5583 21.6815Z" fill="#F3B634"/>
        <path d="M40.1587 55.8833H43.8585L42.8734 58.5286L39.6104 57.357L40.1587 55.8833Z" fill="#F3B634"/>
        <path d="M51.9998 34.0362L44.4936 54.1782H40.7939L48.037 34.745L34.5557 19.4015L37.1884 17.1727L51.9998 34.0362Z" fill="#F3B634"/>
        <path d="M51.8437 55.8834H30.6377V60H51.8437V55.8834Z" fill="#F3B634"/>
        <path d="M26.4912 30.1559C25.2405 31.1473 23.645 31.7416 21.913 31.7416C17.8832 31.7416 14.6152 28.5336 14.6152 24.5753C14.6152 22.6461 15.3944 20.8948 16.6599 19.6062L26.4912 30.1559Z" fill="#006EBA"/>
        <path d="M22.0077 37.0177C21.3749 37.8361 20.6925 38.601 19.9928 39.3488C19.6479 39.7264 19.2831 40.0869 18.9283 40.4571L17.834 41.5362C17.097 42.2475 16.3352 42.9344 15.5585 43.6067C14.7819 44.2765 13.9854 44.9293 13.1367 45.531C13.767 44.7126 14.4494 43.9477 15.1516 43.1999C15.8538 42.4521 16.571 41.7238 17.3129 41.0149L18.437 39.9651C18.8216 39.6241 19.1963 39.2757 19.5883 38.9444C20.365 38.2721 21.1615 37.6194 22.0077 37.0177Z" fill="#F56AA1"/>
        <path d="M13.2211 32.8451C12.5883 33.6635 11.9059 34.4284 11.2062 35.1762C10.8613 35.5538 10.4965 35.9143 10.1417 36.2845L9.04735 37.3636C8.31038 38.0749 7.54861 38.7618 6.77194 39.4341C5.99526 40.1039 5.19873 40.7567 4.3501 41.3584C4.98037 40.5399 5.66275 39.7751 6.36498 39.0273C7.06722 38.2795 7.78433 37.5511 8.52626 36.8423L9.65034 35.7925C10.035 35.4515 10.4096 35.1031 10.8017 34.7718C11.5784 34.0995 12.3749 33.4467 13.2211 32.8451Z" fill="#F56AA1"/>
        <path d="M8.87097 24.3927C8.23821 25.2111 7.55583 25.976 6.85608 26.7238C6.51117 27.1014 6.14639 27.4619 5.79155 27.8321L4.69728 28.9112C3.96031 29.6225 3.19851 30.3094 2.42184 30.9817C1.64516 31.6516 0.848635 32.3044 0 32.906C0.630273 32.0876 1.31265 31.3227 2.01489 30.5749C2.71712 29.8271 3.43423 29.0988 4.17617 28.3899L5.30024 27.3401C5.68486 26.9991 6.05955 26.6507 6.45161 26.3195C7.22829 25.6447 8.02481 24.9944 8.87097 24.3927Z" fill="#F56AA1"/>
    </svg>
    }
]

const Reasons = () => {
    return (
        <div className="st-affiliate-reasons">
            <Card
                title="Reasons to become a Synctrack affiliate"
            >
                    <Row>
                        {reasonConfig.map((item, index) => (
                            <Col md={6} key={index} className="st-affiliate-reasons-item">
                                {item.icon}
                                <div>
                                    <p className='heading'>{item.title}</p>
                                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                </div>
                            </Col>
                        ))}
                    </Row>
            </Card>
        </div>
    );
};

export default memo(Reasons);