import Card from '@/components/card';
import StatisticalCard from '@/components/statisticalCard';
import { BREAKPOINT } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { IStatisticalCard } from '@/types/components';
import { Columns, Layout } from '@shopify/polaris';
import { MarkFulfilledMinor, OrdersMajor, TapChipMajor } from '@shopify/polaris-icons';
import { ApexOptions } from 'apexcharts';
import { useMediaQuery } from 'react-responsive';
import TrackingStatus from '../trackingStatus';
import Chart from './Chart';
import IndicatorFilter from './IndicatorFilter';
import QuickHelp from './quickHelp';
import { AnalysisStyled } from './styled';
import { IResponseApi } from '@/types/api/response.api';

interface Props {
  userSetting: IResponseApi.UserSetting;
  paypalConfirmed: boolean;
  dataChart: {
    categories: any;
    series: ApexOptions['series'];
  };
  syncStripe: boolean;
  data: {
    allOrderFulfilled: number;
    orderPaypalStripe: number;
    orderPaidPaypal: number;
    orderStripe: number;
    orderErr: number;
    orderSubmitted: number;
    orderStripeSynced: number;
    orderPaypalSynced: number;
    totalErr: number;
    orderStripeErr: number;
    orderPaypalErr: number;
    orderPaypalPending: number;
    orderPaidByOther: number;
    orderNoTracking: number;
    orderNoTransactionId: number;
    orderUnconfirmAccount: number;
    orderInvalidTrackingNumber: number;
    orderInvalidAccount: number;
    orderOverPlan: number;
    orderPending: number;
  };
}

const Analysis = ({ data, syncStripe, dataChart, paypalConfirmed, userSetting }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const breakPointChart = useMediaQuery({ maxWidth: BREAKPOINT.MD });
  const dataStatisticalCard: Array<IStatisticalCard.IProps> = [
    {
      title: 'Total Fulfilled Orders',
      value: data.allOrderFulfilled,
      icon: OrdersMajor,
      to: PATH.ORDERS,
      passDataToOrders: {
        payment: 'all',
        status: 'all',
        type: 'filter',
      },
    },
    {
      title: 'Total Synced Orders',
      value: data.orderSubmitted,
      icon: MarkFulfilledMinor,
      to: PATH.ORDERS,
      passDataToOrders: {
        payment: 'all',
        status: '1',
        type: 'filter',
      },
    },
    {
      title: 'Total Sync Error',
      value: data.totalErr,
      icon: TapChipMajor,
      to: PATH.ORDERS,
      passDataToOrders: {
        payment: 'all',
        status: 'total_err',
        type: 'filter',
      },
    },
    {
      title: 'Other gateways orders',
      value: data.orderPaidByOther,
      icon: TapChipMajor,
      to: PATH.ORDERS,
      passDataToOrders: {
        payment: 'other',
        status: 'all',
        type: 'filter',
      },
    },
  ];

  return (
    <AnalysisStyled>
      <Layout>
        <Layout.Section>
          <Card title={isMobile ? 'Last 7 days' : 'Last 14 days'}>
            <div className="chart-content">
              <Chart data={dataChart} />
            </div>
          </Card>
        </Layout.Section>
        <Layout.Section fullWidth={breakPointChart} secondary={!breakPointChart}>
          <Columns gap={{ xl: '2', lg: '2', md: '2', sm: '2', xs: '2' }}>
            {dataStatisticalCard.map((item) => {
              return <StatisticalCard {...item} key={item.title} />;
            })}
          </Columns>
        </Layout.Section>
      </Layout>
      <TrackingStatus data={data} paypalConfirmed={paypalConfirmed} />
      {syncStripe ? <IndicatorFilter dataSummary={data} /> : null}
      <QuickHelp userSetting={userSetting} dataSummary={data} />
    </AnalysisStyled>
  );
};

export default Analysis;
