import { LINK } from '@/constants/link';
import { apiCaller } from '@/redux/query';
import { AlphaCard, Text } from '@shopify/polaris';
import { Col, Row } from 'reactstrap';
import bottombanner from '../../../assets/affiliate/bottombanner.png';

const BottomBanner = () => {
  // const [clickReviewMutation] = apiCaller.useClickReviewMutation()
  const handleClickMode = (note: string) => {
    // clickReviewMutation({
    // 	type: "click_affiliate_bottom",
    // 	note: note,
    // })
  };
  return (
    <div className="st-affiliate-bottom-banner mt-20">
      <AlphaCard>
        <Row>
          <Col md={6}>
            <div>
              <img className="bottom-banner-img" src={bottombanner} alt="bottom-banner" />
            </div>
          </Col>
          <Col md={6}>
            <div className="bottom-right-content">
              <Text variant="heading3xl" as="h3">
                Ready to make money?
              </Text>
              <div>
                <a href={LINK.shoffiAffilate} target="_blank" rel="noreferrer">
                  <button className="affiliate-button-primary" onClick={() => handleClickMode('btn_join_now')}>
                    Join now
                    <svg
                      className="ml-10 mb-5"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3428 1.83057L1 14.1698"
                        stroke="currentColor"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.3429 13.8849V1.83057H1.28857"
                        stroke="currentColor"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </a>
                <a href={LINK.shoffiFaq} target="_blank" rel="noreferrer">
                  <button className="affiliate-button-third">
                    Read our FAQs
                    <svg
                      className="ml-10 mb-5"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3428 1.83057L1 14.1698"
                        stroke="currentColor"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.3429 13.8849V1.83057H1.28857"
                        stroke="currentColor"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </AlphaCard>
    </div>
  );
};

export default BottomBanner;
