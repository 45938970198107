import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const WhatNewStyled = styled.div`
  position: relative;
  min-height: 100px;
  .card {
    padding: 30px 50px;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    p {
      color: #00489a;
      font-size: 1.5em;
      margin: 20px 0;
      line-height: 1.4em;
      font-weight: bold;
    }
  }
  .what-new-filter-wrap {
    display: flex;
    justify-content: flex-start;
  }
  .what-new-menu {
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
    svg {
      margin-bottom: -14px;
    }
    a {
      color: #fff;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .what-new-badge {
    padding: 2px 10px;
    border-radius: 10px;
    margin-right: 10px;
  }

  .what-new-button {
    margin-top: 15px;
  }

  .what-new-button > span {
    color: #007bff;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 30px;
    border: 1px solid #cdd7df;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .what-new-filter {
    margin-top: 30px;
    position: fixed;
  }

  .circle-point {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .what-new-filter-inner {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;
    min-width: 130px;
    span {
      color: #00489a;
    }
  }

  .active-badge {
    /* background-color: lightgrey; */
    font-weight: bold;
  }

  .what-new-background {
    padding: 50px 70px;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: ${BREAKPOINT.MD}px) {
    .what-new-filter {
      display: none;
    }
  }
`;
