import { Plan } from '@/constants/enum';
import { IToast } from '@/types/components';
import currencyFormatter from 'currency-formatter';

export const handlePlanAndOrder = (plan?: string): { plan: string } => {
  switch (plan) {
    case Plan.FREE:
      return {
        plan: 'Free',
      };
    case Plan.BASIC:
      return {
        plan: 'Basic',
      };
    case Plan.PRO:
      return {
        plan: 'Pro',
      };
    case Plan.PRO_PLUS:
      return {
        plan: 'Pro Plus',
      };
    case Plan.UNLIMITED:
      return {
        plan: 'Unlimited',
      };
    default:
      return {
        plan: 'Free',
      };
  }
};

export const handleToastMuatation = (res: any): IToast => {
  if ('data' in res) {
    if (res.data.state !== 1) {
      return {
        isOpen: true,
        content: res.data.msg || 'Failed',
        error: true,
      };
    } else {
      return {
        isOpen: true,
        content: res.data.msg || 'Saved',
        error: false,
      };
    }
  } else {
    return {
      isOpen: true,
      content: res.error.data.message.toString() || 'something happened',
      error: false,
    };
  }
};

export const convertUrlOrder = (shop: string, orderId: string) => {
  const shopName = shop.replace('.myshopify.com', '');
  return `https://admin.shopify.com/store/${shopName}/orders/${orderId}`;
};

export const convertAmount = (amount: string, disputeCurrencyCode: string) => {
  const value = currencyFormatter.format(Number(amount), { code: disputeCurrencyCode });
  return value;
};
export const convertCurrency = (disputeCurrencyCode: string) => {
  const value = currencyFormatter.findCurrency(disputeCurrencyCode)?.symbol;
  return value;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const getSomeDaysAgo = (day: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - day);
  return date;
};
export const getLastMonth = () => {
  const firtDate = new Date();
  firtDate.setDate(1);
  firtDate.setMonth(firtDate.getMonth() - 1);
  const lastDate = new Date();
  lastDate.setDate(0);
  return {
    start: firtDate,
    end: lastDate,
  };
};

export const getLastSomeMonths = (months: number) => {
  const date = new Date();
  date.setMonth(date.getMonth() - months);
  return date;
};

export const snakeToCamel = (str: string) =>
  str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export const convertToCamelCase = (str: string): string =>
  str
    .replace(/"/g, '')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    .replace(/(\s|\b)(\w)/g, (m, space, chr) => chr.toLowerCase());
