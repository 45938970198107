import CustomCard from '@/components/card';
import Loading from '@/components/loading';
import PaypalButton from '@/components/paypalButton';
import SettingToggle from '@/components/settingToggle';
import { COLORS } from '@/constants/colors';
import { handleToastMuatation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isVisibleBannerPaypalSelector } from '@/redux/slice/settings.slice';
import { ISettingsPage } from '@/types/components';
import { Badge, Banner, Button, ButtonGroup, Icon, Link, Modal, Text, TextField, Tooltip } from '@shopify/polaris';
import { DeleteMinor, EditMinor, FavoriteMajor } from '@shopify/polaris-icons';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import Swal from 'sweetalert2';
import Guide from './components/Guide';
import { PaypalSettingStyled } from './styled';
import { userSettingSelector } from '@/redux/slice/commonSetting.slice';
const RenderAccountStatus = (confirmStatus: boolean, payerId: string) => {
  if (confirmStatus) {
    return (
      <Badge status={payerId == null ? 'critical' : 'success'} progress="complete">
        Confirmed
      </Badge>
    );
  } else {
    return (
      <Badge status="warning" progress="complete">
        Invalid Paypal Account
      </Badge>
    );
  }
};

const PaypalSetting = (): JSX.Element => {
  const dispatch = useDispatch();
  const userSettings = useSelector(userSettingSelector);
  const isVisibleBanner = useSelector(isVisibleBannerPaypalSelector);
  const fetchAccountPaypal = apiCaller.useListPaypalAccountQuery('');
  const [mutationNotifyBuyerUpdate, updateNotifyBuyerStatus] = apiCaller.useNotifyBuyerMutationMutation();
  const [mutationDeletePaypalAccount, deletePaypalAccountStatus] = apiCaller.useRemovePaypalAccountMutation();
  const [mutationSetPrimaryAccountPaypal, setPrimaryAccountPaypalStatus] = apiCaller.useSetPrimaryPaypalAccountMutation();
  const [state, setState] = useState<ISettingsPage.IStatePaypalSetting>({
    modalStatus: false,
    modalTitle: 'New Paypal Account',
    modalType: 'add',
    clientId: '',
    secrect: '',
    email: '',
    errClientId: '',
    errSecret: '',
    errEmail: '',
    idSelected: 0,
  });

  const setPrimaryPaypalAccount = (id: number) => () => {
    mutationSetPrimaryAccountPaypal(id).then((res) => {
      dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
    });
  };
  const openModalEditPaypalAccount = (id: number) => () => {
    const paypalAccount = fetchAccountPaypal.data?.result.find((item) => item.id === id);
    if (paypalAccount) {
      setState({
        ...state,
        idSelected: id,
        email: paypalAccount.email,
        clientId: paypalAccount.clientId,
        secrect: paypalAccount.secret,
        errEmail: '',
        errClientId: '',
        errSecret: '',
        modalStatus: true,
        modalType: 'edit',
      });
    }
  };

  const confirmDeletePaypalAccount = (id: number) => () => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'This Paypal account will be permanently removed from our system until you add it again',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: COLORS.primary,
    }).then((result) => {
      if (result.value) {
        mutationDeletePaypalAccount(id).then((res) => {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
        });
      }
    });
  };
  const notifyBuyerActionStatus = userSettings?.paypalNotifyBuyer ? 'Turn off' : 'Turn on';

  const savePaypalAccount = () => {
    // mutationUpdateAccountPaypal({
    //   id: state.idSelected,
    //   clientId: state.clientId,
    //   secrect: state.secrect,
    //   email: state.email,
    // }).then((res) => {
    //   setState({ ...state, modalStatus: false });
    //   dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
    // });
  };

  const handleNotifyBuyer = () => {
    mutationNotifyBuyerUpdate(!userSettings?.paypalNotifyBuyer).then((res) => {
      dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMuatation(res)));
      if (userSettings) {
        dispatch(
          slice.commonSettingSlice.actions.handleUserSetting({
            ...userSettings,
            paypalNotifyBuyer: !userSettings.paypalNotifyBuyer,
          }),
        );
      }
    });
  };

  return (
    <PaypalSettingStyled>
      {fetchAccountPaypal.isLoading && <Loading />}
      <Col xs="12" lg="12" sm="12" xl="8">
        {isVisibleBanner && (
          <div className="mb-16">
            <Banner
              title="Your account is protected"
              onDismiss={() => {
                dispatch(slice.settingsPageSlice.actions.handleVisibleBannerPaypal(false));
              }}
            >
              <span className="text-left" style={{ lineHeight: '26px', fontSize: '15px' }}>
                We only require <b>minimal access </b> from your account to add tracking info to Paypal. Rest assured that your
                account will <b>always be safe</b>
              </span>
            </Banner>
          </div>
        )}
        <CustomCard title="Connecting App with Paypal">
          {fetchAccountPaypal.data?.result.length === 0 ? (
            <Banner title="You are not connected to your PayPal account." status="warning">
              In order to make the app work properly, please click "Connect With PayPal" below.{' '}
              <Link external url="https://www.youtube.com/watch?v=939S7HqgJi4&feature=emb_title">
                (How to get it?)
              </Link>
              <br />
              <br />
              <b>
                Note: Disable pop-up blocking on your browser so that the connecting pop-up can appear or try with incognito
                browser
              </b>
            </Banner>
          ) : (
            <div className="pl-2">
              <p style={{ fontSize: '16px' }}>
                Once Paypal account is connected, our app will auto-sync the tracking number of fulfilled/completed orders to your
                Paypal account
              </p>
              {fetchAccountPaypal.data?.result.map((item, index) => {
                return (
                  <div
                    key={index + 1}
                    className="mt-4 pl-4 pr-4 pt-3 pb-3 paypal-account-layout"
                    style={{
                      fontSize: '16px',
                      lineHeight: '34px',
                    }}
                  >
                    <div>
                      {item.primaryAccount ? (
                        <div className="badge-container">
                          <div className="strong">
                            <Badge status="success">Primary Account</Badge>{' '}
                          </div>
                          {RenderAccountStatus(item.confirmStatus, item.payerId)}
                          <br />
                        </div>
                      ) : (
                        <div className="badge-container">
                          <div className="strong">
                            <Badge>Second account</Badge>
                          </div>
                          {RenderAccountStatus(item.confirmStatus, item.payerId)}
                          <br />
                        </div>
                      )}
                      {item.email !== null && item.email !== '' ? (
                        <div>
                          <strong>Account: </strong> {item.email}
                          <br />
                        </div>
                      ) : null}
                      <strong>{item.payerId === null ? 'Client Id: ' : 'PayPal Merchant ID: '}</strong>{' '}
                      {item.payerId === null ? item.clientId : item.payerId}
                    </div>
                    <div className="group-action">
                      <ButtonGroup>
                        {!item.primaryAccount && (
                          <div className="other-button">
                            <Tooltip content="Mark as primary Account">
                              <Button
                                loading={setPrimaryAccountPaypalStatus.isLoading}
                                onClick={setPrimaryPaypalAccount(item.id)}
                                icon={() => <Icon source={FavoriteMajor} color="base" />}
                              ></Button>
                            </Tooltip>
                          </div>
                        )}
                        {item.payerId === null ? (
                          <div className="other-button">
                            <Tooltip content="Edit Account">
                              <Button
                                onClick={openModalEditPaypalAccount(item.id)}
                                icon={() => <Icon source={EditMinor} color="base" />}
                              ></Button>
                            </Tooltip>
                          </div>
                        ) : null}
                        <div className="delete-button">
                          <Tooltip content="Delete Account">
                            <Button
                              loading={deletePaypalAccountStatus.isLoading}
                              onClick={confirmDeletePaypalAccount(item.id)}
                              icon={() => <Icon source={DeleteMinor} color="base" />}
                            />
                          </Tooltip>
                        </div>
                      </ButtonGroup>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="mt-4 ml-2">
            <PaypalButton />
            {fetchAccountPaypal.data?.result.length !== 0 && (
              <div className="mt-16">
                {fetchAccountPaypal.data?.result.length === 1 ? (
                  <Banner
                    title="Note: Do you regularly change your PayPal account to reduce the spike on one account?"
                    status="info"
                  >
                    <Text as="h5" variant="bodyMd">
                      Please connect to the respective PayPal accounts in the application so that tracking information is uploaded
                      to the correct PayPal account
                    </Text>
                  </Banner>
                ) : (
                  <Banner status="info" title="Note">
                    <Text as="h5" variant="bodyMd">
                      - Orders that do not have the PayPal accounts received from Shopify , the tracking information will be added
                      to the <b>primary account.</b>
                      <br /> -The order will be marked as <b>"Missing PayPal account"</b> in case it has PayPal account received
                      from Shopify but does not match any PayPal account already connected to the app.
                    </Text>
                  </Banner>
                )}
              </div>
            )}
          </div>

          <Modal
            open={state.modalStatus}
            onClose={() => {
              setState({ ...state, modalStatus: false });
            }}
            title={state.modalType === 'add' ? 'Add new account' : 'Edit Account'}
            primaryAction={{
              content: 'Save',
              onAction: savePaypalAccount,
              // loading: updateNotifyBuyerStatus.isLoading,
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: () => setState({ ...state, modalStatus: false }),
              },
            ]}
          >
            <Modal.Section>
              <TextField
                autoComplete="off"
                label="Account Email*:"
                value={state.email}
                onChange={(value: string) => {
                  setState({ ...state, email: value });
                }}
                error={state.errEmail}
                type="email"
              />
              <TextField
                autoComplete="off"
                label="Client ID*:"
                value={state.clientId}
                onChange={(value: string) => {
                  setState({ ...state, clientId: value });
                }}
                error={state.errClientId}
              />
              <TextField
                autoComplete="off"
                label="Secret* :"
                type="password"
                value={state.secrect}
                onChange={(value: string) => {
                  setState({ ...state, secrect: value });
                }}
                error={state.errSecret}
              />
              <Guide />
            </Modal.Section>
          </Modal>
        </CustomCard>
        <div className="mt-20" />
        <SettingToggle
          settingToggleProps={{
            action: {
              content: notifyBuyerActionStatus,
              onAction: handleNotifyBuyer,
              loading: updateNotifyBuyerStatus.isLoading,
            },
            enabled: userSettings?.paypalNotifyBuyer,
          }}
          title="Notify buyer:"
          content="Sends an email notification to the buyer via PayPal support email. The email contains the tracking information
                uploaded: "
        />
      </Col>
      <Col xs="12" lg="12" sm="12" xl="4" className="mb-mt-20">
        <CustomCard title="Step by step video to connect to your account">
          <div className="paypal-video">
            <div className="mt-15">
              <iframe
                src="https://www.youtube.com/embed/939S7HqgJi4"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="Video Tutorial"
                width="100%"
                height="300"
              />
            </div>
          </div>
        </CustomCard>
      </Col>
    </PaypalSettingStyled>
  );
};

export default memo(PaypalSetting);
