import CustomDatePicker from '@/components/datePicker';
import OldOrderBanner from '@/components/oldOrderbanner';
import OrderTable from '@/components/orderTable';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { orderTableHomePageSelector, titleBtnDatePickerSelector } from '@/redux/slice/homePage.slice';
import { Banner, Button, Columns, Text } from '@shopify/polaris';
import { RefreshMinor } from '@shopify/polaris-icons';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Analysis from './components/analysis';
import Credentials from './components/credentials';
import { HomePageStyled } from './styled';
import { useMediaQuery } from 'react-responsive';
import { hotjar } from 'react-hotjar';
import config from '@/config';
import { PATH } from '@/constants/path';
import { useNavigate } from 'react-router-dom';

const Home = (): JSX.Element => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const orderTable = useSelector(orderTableHomePageSelector);
  const titleBtnDatePicker = useSelector(titleBtnDatePickerSelector);
  const fetchDataDashboard = apiCaller.useFetchDataSettingQuery({
    fromDate: orderTable.fromDate,
    toDate: orderTable.toDate,
    device: isMobile ? 'mobile' : 'desktop',
  });
  const dispatch = useDispatch();
  const onSaveDatePicker = (fromDate: Date, toDate: Date) => {
    dispatch(slice.homePageSlice.actions.handleFiltersOrderTable({ ...orderTable, fromDate, toDate }));
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(slice.homePageSlice.actions.handleTitleBtnDatePicker(title));
  };

  useEffect(() => {
    if (fetchDataDashboard.data && fetchDataDashboard.data.result) {
      dispatch(slice.commonSettingSlice.actions.handleUserSetting(fetchDataDashboard.data.result.userSetting));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataDashboard.data]);
  return (
    <HomePageStyled>
      {fetchDataDashboard.data &&
        fetchDataDashboard.data.result &&
        fetchDataDashboard.data.result.userSummary.paypalAccount === 0 && (
          <div className="mt-16">
            <Banner
              status="warning"
              title="You need to connect payment account(s)."
              action={{
                content: 'Go to settings',
                onAction() {
                  hotjar.stateChange(`${PATH.SETTINGS.pathname}?${config.instance}`);
                  navigate(PATH.SETTINGS);
                  dispatch(slice.settingsPageSlice.actions.handleActiveKey('paypal'));
                },
              }}
            >
              <Text as="h5" variant="bodyMd">
                Please go to Settings page to connect your payment account(s) in order to start syncing tracking information
                automatically.
              </Text>
            </Banner>
          </div>
        )}

      <div className="mt-16">
        <OldOrderBanner isShowDemoOrder={false} />
      </div>
      <div className="mt-16" />
      <Columns columns={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
        <div className="d-flex jc-e">
          <div className="mr-4">
            <Button
              onClick={async () => {
                fetchDataDashboard.refetch();
              }}
              icon={RefreshMinor}
            />
          </div>
          <CustomDatePicker
            setTitleBtn={onSaveTitleBtnDatePicker}
            titleBtn={titleBtnDatePicker}
            start={orderTable.fromDate}
            end={orderTable.toDate}
            onSave={onSaveDatePicker}
          />
        </div>
      </Columns>
      {fetchDataDashboard.data && fetchDataDashboard.data.result ? (
        <Analysis
          userSetting={fetchDataDashboard.data.result.userSetting}
          paypalConfirmed={fetchDataDashboard.data.result.userSummary.paypalConfirmed}
          dataChart={fetchDataDashboard.data.result.orderChart}
          data={fetchDataDashboard.data.result.orderSummary}
          syncStripe={fetchDataDashboard.data.result.userSetting.syncStripe}
        />
      ) : null}
      {fetchDataDashboard.data && fetchDataDashboard.data.result ? (
        <Credentials
          paypalToken={fetchDataDashboard.data.result.userSummary.paypalAccount}
          plan={fetchDataDashboard.data.result.userSetting.plan}
          quota={fetchDataDashboard.data.result.userSetting.quota}
          stripeToken={fetchDataDashboard.data.result.userSummary.stripeToken}
          subscription={fetchDataDashboard.data.result.userSetting.subscription}
          syncStripe={fetchDataDashboard.data.result.userSetting.syncStripe}
          usage={fetchDataDashboard.data.result.userSummary.usage}
        />
      ) : null}

      <div className="mt-16">{fetchDataDashboard.data ? <OrderTable title="Latest orders" type="home" /> : null}</div>
    </HomePageStyled>
  );
};

export default memo(Home);
