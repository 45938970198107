import { userSettingSelector } from '@/redux/slice/commonSetting.slice';
import { Badge, Divider } from '@shopify/polaris';
import { memo } from 'react';
import { useSelector } from 'react-redux';
interface Props {
  title: string;
}

const Header = ({ title }: Props) => {
  const userSetting = useSelector(userSettingSelector);

  return (
    <div className="layout-header top-68">
      <div className="top-left d-flex j-c-s-b p-vertical-20">
        <div className="d-flex">
          <span className="fz-20 mr-8 align-center d-flex">
            <b className="mr-8">{title}</b>
          </span>
          <div>
            <Badge status="success" progress="complete">
              {`Active Plan: ${userSetting?.plan.toUpperCase() || 'FREE'}`}
            </Badge>
          </div>
        </div>
      </div>
      <Divider borderStyle="divider-on-dark" />
    </div>
  );
};
export default memo(Header);
