import { getLastMonth, getLastSomeMonths, getSomeDaysAgo } from '@/helpers';
import { Box, Button, DatePicker, Icon, OptionList, Popover, TextField, useBreakpoints } from '@shopify/polaris';
import { ArrowRightMinor, CalendarMinor } from '@shopify/polaris-icons';
import moment from 'moment';
import { memo, useCallback, useState } from 'react';
const ranges = [
  {
    title: 'Custom',
    alias: 'Custom',
    period: {
      end: new Date(),
      start: new Date(),
    },
  },
  {
    title: 'Today',
    alias: 'today',
    period: {
      end: new Date(),
      start: new Date(),
    },
  },
  {
    title: 'Yesterday',
    alias: 'yesterday',
    period: {
      end: new Date(),
      start: getSomeDaysAgo(1),
    },
  },
  {
    title: 'Last 7 days',
    alias: 'last7days',
    period: {
      end: new Date(),
      start: getSomeDaysAgo(7),
    },
  },
  {
    title: 'Last 30 days',
    alias: 'last30days',
    period: {
      end: new Date(),
      start: getSomeDaysAgo(30),
    },
  },
  {
    title: 'Last month',
    alias: 'lastmonth',
    period: {
      end: getLastMonth().end,
      start: getLastMonth().start,
    },
  },
  {
    title: 'Last 2 months',
    alias: 'last2months',
    period: {
      end: new Date(),
      start: getLastSomeMonths(2),
    },
  },
];
// This example is for guidance purposes. Copying it will come with caveats.
function CustomDatePicker({
  onSave,
  start,
  end,
  titleBtn,
  setTitleBtn,
}: {
  onSave?: Function;
  start: Date;
  end: Date;
  titleBtn: string;
  setTitleBtn: Function;
}) {
  const now = new Date();
  const { mdDown } = useBreakpoints();
  const [selected, setSelected] = useState(ranges[3]);
  const [popoverActive, setPopoverActive] = useState(false);
  const [selectedDates, setSelectedDates] = useState({ start, end });
  const [{ month, year }, setDate] = useState({ month: now.getMonth(), year: now.getFullYear() });
  const handleMonthChange = useCallback((month: number, year: number) => setDate({ month, year }), []);

  return (
    <Popover
      active={popoverActive}
      autofocusTarget="none"
      preferredAlignment="left"
      preferredPosition="below"
      fluidContent
      sectioned={false}
      fullHeight
      activator={
        <Button icon={CalendarMinor} onClick={() => setPopoverActive(!popoverActive)}>
          {titleBtn}
        </Button>
      }
      onClose={() => {
        setSelectedDates({ start, end });
        setPopoverActive(false);
      }}
    >
      <div className="d-flex">
        <OptionList
          options={ranges.map((range) => ({
            value: range.alias,
            label: range.title,
          }))}
          selected={[selected.alias]}
          onChange={(value) => {
            const range = ranges.find((range) => range.alias === value[0]) || ranges[0];
            setSelected(range);
            setSelectedDates({ ...range.period });
            handleMonthChange(range.period.start.getMonth(), range.period.start.getFullYear());
          }}
        />
        <Box padding={{ xs: '5' }} maxWidth={mdDown ? '320px' : '516px'}>
          <div className="d-flex">
            <div>
              <TextField
                role="combobox"
                label={'Since'}
                labelHidden
                prefix={<Icon source={CalendarMinor} />}
                value={moment(selectedDates.start).format('DD/MM/YYYY')}
                readOnly
                autoComplete="off"
              />
            </div>
            <Icon source={ArrowRightMinor} />
            <div>
              <TextField
                role="combobox"
                label={'Until'}
                labelHidden
                prefix={<Icon source={CalendarMinor} />}
                value={moment(selectedDates.end).format('DD/MM/YYYY')}
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <DatePicker
            month={month}
            year={year}
            selected={{
              start: selectedDates.start,
              end: selectedDates.end,
            }}
            onMonthChange={handleMonthChange}
            onChange={(value) => {
              setSelectedDates(value);
              setSelected(ranges[0]);
            }}
            multiMonth
            allowRange
            disableDatesAfter={now}
            disableDatesBefore={getLastSomeMonths(2)}
          />
          <div className="date-picker-btn-group">
            <Button
              onClick={() => {
                setPopoverActive(false);
                setSelectedDates({ start, end });
              }}
            >
              Cancel
            </Button>
            <div className="ml-8">
              <Button
                primary
                onClick={() => {
                  setPopoverActive(false);
                  onSave && onSave(selectedDates.start, selectedDates.end);
                  setTitleBtn(
                    selected.title === 'Custom'
                      ? `${moment(selectedDates.start).format('DD/MM/YYYY')} - ${moment(selectedDates.end).format('DD/MM/YYYY')}`
                      : selected.title,
                  );
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </Popover>
  );
}
export default memo(CustomDatePicker);
