/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react';

import { Spinner } from '@shopify/polaris';
import config from '@/config';

const HandleRequest = (): JSX.Element => {
  useEffect(() => {
    window.opener.postMessage({
      type: 'paypalConnected',
      success: true,
      code: config.code,
    });
    window.close();
  }, []);
  return (
    <div id="i_loading_page_2">
      <Spinner size="large" />
    </div>
  );
};

export default HandleRequest;
