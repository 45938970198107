import styled from 'styled-components';
export const OldOrderBannerStyled = styled.div`
  .noti-content {
    display: flex;
    align-items: flex-start;
    .Polaris-Icon  {
      margin: 0;
      margin-right: 8px;
    }
  }
  .card-container {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  .mr-20 {
    margin-right: 20px;
  }
  .old-order-btn {
    display: flex;
    justify-content: flex-end;
  }
`;
