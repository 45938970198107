import { SearchMajor, SortAscendingMajor, SortDescendingMajor, SortMinor } from '@shopify/polaris-icons';
import parse from 'html-react-parser';
import moment from 'moment';
import { Row } from 'reactstrap';
import CustomCard from '@/components/card';
import { AlphaCard, Badge, Icon, Pagination, ResourceItem, ResourceList, Text, Tooltip } from '@shopify/polaris';
import { Link as LinkRouter } from 'react-router-dom';

import { IOrderTable } from '@/types/components';
import ExportOrders from './ExportOrders';
import { useDispatch, useSelector } from 'react-redux';
import slice from '@/redux/slice';
import OrderTableFilter from './Filters';
import { OrderTableStyled } from './styled';
import { PATH } from '@/constants/path';
import { orderTableHomePageSelector } from '@/redux/slice/homePage.slice';
import { orderTableOrderPageSelector } from '@/redux/slice/orderPage.slice';
import { IResponseApi } from '@/types/api/response.api';
import { apiCaller } from '@/redux/query';
import { memo } from 'react';
import { userSettingSelector } from '@/redux/slice/commonSetting.slice';
import { OrderStatusMapping } from '../../constants/enum';
const headerTable = {
  id: 'header',
  shop: '',
  order_id: '',
  name: '',
  processed_at: new Date(),
  payment_gateway_names: '',
  add_tracking_status: '',
  add_tracking_at: 0,
  created_at: new Date(),
  last_updated_at: new Date(),
  transaction_id: '',
  note_attributes: '',
  add_tracking_retry: '',
  tracking_number: '',
  tracking_company: '',
  carrier: '',
  carrier_other_name: '',
  add_tracking_account: null,
  pay_pal_account_id: '',
  source: '',
};
const setWidthColumns = {
  order: '80px',
  store: '150px',
  payment: '130px',
  trackingNumber: '160px',
  carrier: '150px',
  transactionId: '100px',
  syncStatus: '200px',
  accPaidSynced: '160px',
  date: '150px',
  syncedAt: '150px',
};

const OrderTable = ({ ...props }: IOrderTable.IProps) => {
  const orderTable: IOrderTable.IState = useSelector(
    props.type === 'home' ? orderTableHomePageSelector : orderTableOrderPageSelector,
  );
  const userSetting = useSelector(userSettingSelector);
  const { data, isFetching } =
    props.type === 'home'
      ? apiCaller.useFetchOrdersQuery({
          fromDate: orderTable.fromDate,
          toDate: orderTable.toDate,
          perPage: 10,
          payment: userSetting?.syncStripe ? 'paypal_stripe' : 'paypal',
          sort: orderTable.sort,
          sortBy: orderTable.sortBy,
        })
      : apiCaller.useFetchOrdersQuery({
          search: orderTable.search,
          status: orderTable.statusSelected[0],
          fromDate: orderTable.fromDate,
          toDate: orderTable.toDate,
          payment: orderTable.paymentSelected[0],
          page: orderTable.currentPage,
          perPage: parseInt(orderTable.perPage[0]),
          sort: orderTable.sort,
          sortBy: orderTable.sortBy,
        });
  const dispatch = useDispatch();
  const emptyStateMarkup = (
    <div className="resource-list-empty">
      <div className="resource-list-empty__icon">
        <Icon color="base" source={SearchMajor} />
      </div>
      <div className="resour-list-empty__text">
        <Text as={'h1'} variant={'headingMd'}>
          No orders
        </Text>
      </div>
    </div>
  );

  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };

  const processTransactionId = (transactionId: string, paymentName: string) => {
    var res = transactionId?.split(' ') || [];
    let dataReturn = '';
    let paymentURL = 'https://www.paypal.com/activity/payment/';
    if (paymentName.includes('stripe') || paymentName.includes('Stripe')) {
      paymentURL = 'https://dashboard.stripe.com/payments/';
    }
    for (let i = 0; i < res.length; i++) {
      if (res[i] === '') {
        dataReturn = '---';
      } else {
        if (i !== res.length - 1) {
          dataReturn +=
            // eslint-disable-next-line no-useless-concat
            '<a target="_blank" class="Polaris-Link" target="" href="' + paymentURL + res[i] + '">' + res[i] + '</a>' + '<br/>';
        } else {
          dataReturn += '<a target="_blank" class="Polaris-Link" target="" href="' + paymentURL + res[i] + '">' + res[i] + '</a>';
        }
      }
    }
    return dataReturn;
  };

  const updateSort = (pattern: string) => {
    if (props.type === 'home') {
      dispatch(
        slice.homePageSlice.actions.handleFiltersOrderTable({
          ...orderTable,
          sortBy: pattern,
          sort: orderTable.sort === 'ASC' ? 'DESC' : 'ASC',
        }),
      );
    } else {
      dispatch(
        slice.orderPageSlice.actions.handleFiltersOrderTable({
          ...orderTable,
          sortBy: pattern,
          sort: orderTable.sort === 'ASC' ? 'DESC' : 'ASC',
        }),
      );
    }
  };
  const renderItem = (order: IResponseApi.IOrder) => {
    return (
      <ResourceItem onClick={() => {}} id={order.id.toString()} key={order.id}>
        {order.id === 'header' ? (
          <div className="table-header">
            <Row className="row-orders">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  className="vertical-center d-flex"
                  style={{ width: setWidthColumns.order }}
                  onClick={() => updateSort('orderNumber')}
                >
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate font-weight-bold text-left">Order</p>
                  </Text>
                  <Icon
                    source={
                      // eslint-disable-next-line no-nested-ternary
                      orderTable.sortBy === 'orderNumber'
                        ? orderTable.sort === 'ASC'
                          ? SortAscendingMajor
                          : SortDescendingMajor
                        : SortMinor
                    }
                    color={orderTable.sortBy === 'orderNumber' ? 'primary' : 'base'}
                  />
                </div>
                <div className="vertical-center d-flex" style={{ width: setWidthColumns.payment }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate font-weight-bold text-center">Payment</p>
                  </Text>
                </div>{' '}
                <div className="vertical-center d-flex" style={{ width: setWidthColumns.trackingNumber }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate font-weight-bold text-center">Tracking Number</p>
                  </Text>
                </div>
                <div className="vertical-center d-flex" style={{ width: setWidthColumns.carrier }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate font-weight-bold text-center">Carrier</p>
                  </Text>
                </div>
                <div className="vertical-center d-flex" style={{ width: setWidthColumns.transactionId }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate font-weight-bold text-left">Transaction Id</p>
                  </Text>
                </div>
                <div className="vertical-center" style={{ width: setWidthColumns.syncStatus }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate font-weight-bold text-center">Sync Status</p>
                  </Text>
                </div>
                <div className="vertical-center" style={{ width: setWidthColumns.accPaidSynced }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate font-weight-bold text-left">synced</p>
                  </Text>
                </div>
                <div
                  className="vertical-center  d-flex"
                  style={{ width: setWidthColumns.date }}
                  onClick={() => updateSort('processedAt')}
                >
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate text-left font-weight-bold">Date</p>
                  </Text>
                  <Icon
                    source={
                      // eslint-disable-next-line no-nested-ternary
                      orderTable.sortBy === 'processedAt'
                        ? orderTable.sort === 'ASC'
                          ? SortAscendingMajor
                          : SortDescendingMajor
                        : SortMinor
                    }
                    color={orderTable.sortBy === 'processedAt' ? 'primary' : 'base'}
                  />
                </div>
                <div
                  className="vertical-center d-flex"
                  style={{ width: setWidthColumns.syncedAt, marginLeft: '20px' }}
                  onClick={() => updateSort('addTrackingAt')}
                >
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate font-weight-bold text-center">Synced at</p>
                  </Text>
                  <Icon
                    source={
                      // eslint-disable-next-line no-nested-ternary
                      orderTable.sortBy === 'addTrackingAt'
                        ? orderTable.sort === 'ASC'
                          ? SortAscendingMajor
                          : SortDescendingMajor
                        : SortMinor
                    }
                    color={orderTable.sortBy === 'addTrackingAt' ? 'primary' : 'base'}
                  />
                </div>
              </div>
            </Row>
          </div>
        ) : (
          <div>
            <Row>
              <div className="vertical-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="vertical-center" style={{ width: setWidthColumns.order }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate text-left">{order.orderNumber}</p>
                  </Text>
                </div>
                <div className="vertical-center" style={{ width: setWidthColumns.payment }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate text-left">
                      <Badge
                        status={
                          order.addTrackingStatus !== OrderStatusMapping.OtherGateWays &&
                          order.paymentGatewayName !== 'manual_checked'
                            ? 'info'
                            : undefined
                        }
                      >
                        {order.paymentGatewayName}
                      </Badge>
                    </p>
                  </Text>
                </div>
                <div className="vertical-center" style={{ width: setWidthColumns.trackingNumber }}>
                  <Tooltip content={order.trackingNumber}>
                    <Text variant="headingMd" as="h4">
                      <p className="text-truncate text-left">{order.trackingNumber || '---'}</p>
                    </Text>
                  </Tooltip>
                </div>
                <div className="vertical-center" style={{ width: setWidthColumns.carrier }}>
                  <Tooltip content={order.trackingCompany}>
                    <Text variant="headingMd" as="h4">
                      <p className="text-truncate text-left">{order.trackingCompany || '---'}</p>
                    </Text>
                  </Tooltip>
                </div>
                <div className="vertical-center" style={{ width: setWidthColumns.transactionId }}>
                  <Tooltip content={order.transactionId}>
                    <Text variant="headingMd" as="h4">
                      <p className="text-truncate text-left">
                        {parse(processTransactionId(order.transactionId, order.paymentGatewayName))}
                      </p>
                    </Text>
                  </Tooltip>
                </div>
                <div className="vertical-center" style={{ width: setWidthColumns.syncStatus }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate text-center">
                      {order.addTrackingStatus === OrderStatusMapping.Complete ? (
                        <Badge status="success" progress="complete">
                          Completed
                        </Badge>
                      ) : null}
                      {order.addTrackingStatus === OrderStatusMapping.NoTracking ? (
                        <Badge status="warning">No Tracking Number</Badge>
                      ) : null}
                      {order.addTrackingStatus === OrderStatusMapping.OtherGateWays ? (
                        <Badge status="warning">Paid by other gateways</Badge>
                      ) : null}
                      {order.addTrackingStatus === OrderStatusMapping.OverQuota ? (
                        <Badge status="warning">Over quota</Badge>
                      ) : null}
                      {order.addTrackingStatus === OrderStatusMapping.NoInvalidAccount ? (
                        <Badge status="critical">No Account OR Invalid</Badge>
                      ) : null}
                      {order.addTrackingStatus === OrderStatusMapping.NoTransaction ? (
                        <Badge status="warning">Order no transaction id</Badge>
                      ) : null}
                      {order.addTrackingStatus === OrderStatusMapping.InvalidTracking ? (
                        <Badge status="warning">Invalid Tracking Number</Badge>
                      ) : null}
                      {order.addTrackingStatus === OrderStatusMapping.MissingPaypalAccount ? (
                        <Badge progress="complete" status="critical">
                          No PayPal Account
                        </Badge>
                      ) : null}
                      {order.addTrackingStatus === OrderStatusMapping.Pending ? (
                        <Badge status="info" progress="partiallyComplete">
                          Pending
                        </Badge>
                      ) : null}
                    </p>
                  </Text>
                </div>
                <div className="vertical-center" style={{ width: setWidthColumns.accPaidSynced }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate text-left">{order.addTrackingAccount || 'No info'}</p>
                  </Text>
                </div>
                <div className="vertical-center" style={{ width: setWidthColumns.date }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate text-left break-line">{moment(order.createdAt).format('D MMM YYYY, hh:mm a')}</p>
                  </Text>
                </div>
                <div className="vertical-center" style={{ width: setWidthColumns.syncedAt, marginLeft: '20px' }}>
                  <Text variant="headingMd" as="h4">
                    <p className="text-truncate text-left break-line">
                      {!order.addTrackingAt ? '---' : moment.unix(order.addTrackingAt).format('D MMM YYYY, hh:mm a')}
                    </p>
                  </Text>
                </div>
              </div>
            </Row>
          </div>
        )}
      </ResourceItem>
    );
  };
  return (
    <OrderTableStyled>
      <CustomCard title={props.title}>
        <AlphaCard padding={{ xl: '0' }}>
          {data && data.result && data.result.length > 0 && props.type !== 'home' ? (
            <ExportOrders
              search={orderTable.search}
              fromDate={orderTable.fromDate}
              toDate={orderTable.toDate}
              status={orderTable.statusSelected}
              payment={orderTable.paymentSelected}
              sort={orderTable.sort}
              sortBy={orderTable.sortBy}
            />
          ) : null}
          <div className="d-inline-block w-100">
            <ResourceList
              resourceName={resourceName}
              items={data && data.result.length > 0 ? [headerTable, ...data.result] : []}
              renderItem={renderItem}
              loading={isFetching}
              filterControl={props.type === 'home' ? null : <OrderTableFilter />}
              emptyState={emptyStateMarkup}
            />
            {props.type === 'home' ? null : (
              <div className="d-flex justify-content-center p-4">
                <Pagination
                  label={
                    data?.totalResult
                      ? `Showing ${(data?.currentPage - 1) * Number(data?.perPage) + 1} to ${Math.min(
                          data?.currentPage * Number(data?.perPage),
                          data?.totalResult,
                        )} of ${data?.totalResult} shipments`
                      : null
                  }
                  hasPrevious={Number(data?.currentPage) > 1}
                  onPrevious={() =>
                    dispatch(
                      slice.orderPageSlice.actions.handleFiltersOrderTable({
                        ...orderTable,
                        currentPage: (data?.currentPage || 1) - 1,
                      }),
                    )
                  }
                  hasNext={Number(data?.currentPage) < Number(data?.totalPage)}
                  onNext={() => {
                    dispatch(
                      slice.orderPageSlice.actions.handleFiltersOrderTable({
                        ...orderTable,
                        currentPage: Number(data?.currentPage) + 1,
                      }),
                    );
                  }}
                />
              </div>
            )}
            {data && data.result.length > 9 && props.type === 'home' ? (
              <div className="m-4">
                <LinkRouter to={PATH.ORDERS}>Show more orders</LinkRouter>
              </div>
            ) : null}
          </div>
        </AlphaCard>
      </CustomCard>
    </OrderTableStyled>
  );
};

export default memo(OrderTable);
