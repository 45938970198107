import { Plan } from '@/constants/enum';
import slice from '@/redux/slice';
import { IResponseApi } from '@/types/api/response.api';
import { IHomePage } from '@/types/components';
import { AlphaCard, Button, Icon, Text } from '@shopify/polaris';
import { AlertMinor, ChevronDownMinor, CircleTickOutlineMinor, FlagMajor, ListMajor } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { QuickHelpItemStyled } from '../styled';

interface Props extends IHomePage.IQuickHelpConfig {
  dataAnalys: number;
  dataSummary: IResponseApi.OrderSummary;
  userSetting: IResponseApi.UserSetting;
}

const getLimit = (fetchDataSettings: IResponseApi.UserSetting) => {
  switch (fetchDataSettings.plan) {
    case Plan.FREE:
      return '30';
    case Plan.BASIC:
      return '500';
    case Plan.PRO:
      return '6,000';
    case Plan.PRO_PLUS:
      return '25,000';
    case Plan.UNLIMITED:
      return 'Unlimited';
    default:
      return '30';
  }
};

const overQuotaMarkup = (dataSummary: IResponseApi.OrderSummary, fetchDataSettings?: IResponseApi.UserSetting) => {
  if (fetchDataSettings) {
    return (
      <>
        <p>
          Your plan is on <b>{fetchDataSettings.plan}</b>. This plan supports <b>{getLimit(fetchDataSettings)}</b>{' '}
          trackings/month.
        </p>
        <p>
          This month your store has <b>{dataSummary.allOrderFulfilled}</b> fulfilled orders. Therefore, our app sucessfully synced{' '}
          <b>{dataSummary.orderSubmitted}</b> for you while <b>{dataSummary.orderOverPlan}</b> orders are not supported because of
          over quota for this subscription.
        </p>
      </>
    );
  }
};

const QuickHelpMarkup = (
  item: Props,
  dataSummary: IResponseApi.OrderSummary,
  userSetting: IResponseApi.UserSetting,
): JSX.Element => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (item.keyValue === 'overQuota') {
      dispatch(slice.homePageSlice.actions.handleOpenQuickHelp(false));
    } else {
      if ($crisp) {
        $crisp.push(['do', 'chat:open']);
      }
    }
  };
  return (
    <div className="quick-help-inner">
      {item.keyValue === 'overQuota'
        ? overQuotaMarkup(dataSummary, userSetting)
        : item.content.map((text, index) => <div key={index}>{text}</div>)}
      {item.solution && (
        <>
          <div className="quick-help-heading-solution">
            <Text
              as={'h1'}
              children={
                <div className="solution-title">
                  <Icon source={CircleTickOutlineMinor} color="success" />
                  <p className="bold">Solution</p>
                </div>
              }
              variant={'headingLg'}
            />
          </div>
          {item.keyValue === 'invalidAccount' ? (
            <>
              <p>Make sure you are connecting all your PayPal account</p>
            </>
          ) : (
            item.solution.map((txt: React.ReactNode, index: number) => <div key={index}>{txt}</div>)
          )}
        </>
      )}

      {item.solution2?.map((txt2: React.ReactNode, index: number) => (
        <div key={index}>
          <div className="quick-help-heading-solution">
            <Text
              as={'h1'}
              variant={'headingLg'}
              children={
                <div className="solution-title">
                  <Icon source={CircleTickOutlineMinor} color="success" />
                  <p className="bold">Solution</p>
                </div>
              }
            />
          </div>
          {txt2}
        </div>
      ))}
      <Button icon={FlagMajor} onClick={handleClick}>
        {item.button}
      </Button>
    </div>
  );
};

export const QuickHelpItem = ({ ...props }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(props.dataAnalys > 0);

  return (
    <QuickHelpItemStyled>
      <AlphaCard>
        <div className="quick-help-content">
          <div className="quick-help-heading" onClick={() => setIsOpen(!isOpen)}>
            <Text
              as="h1"
              variant="headingLg"
              children={
                <div className="heading-title">
                  <Icon
                    source={props.dataAnalys > 0 ? AlertMinor : ListMajor}
                    color={`${props.dataAnalys > 0 ? 'critical' : 'interactive'}`}
                  />
                  <p className="bold"> {props.title}</p>
                </div>
              }
            />
            <div
              style={{
                maxHeight: '20px',
                maxWidth: '20px',
                transition: '.5s',
                transform: isOpen ? undefined : 'rotate(-90deg)',
              }}
            >
              <Icon source={ChevronDownMinor} />
            </div>
          </div>
          {isOpen && (
            <div
              style={{
                transition: '.5s',
              }}
            >
              <QuickHelpMarkup {...props} />
            </div>
          )}
        </div>
      </AlphaCard>
    </QuickHelpItemStyled>
  );
};
