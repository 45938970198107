import styled from "styled-components";
import bannerBackground from '@/assets/affiliate/bannerbackground.png'

export const AffiliateStyled = styled.div`
    font-family: 'Poppins', sans-serif !important;
    .st-affiliate-top-banner {
        position: relative;
        background-image: url(${bannerBackground});
        background-size: cover;
        border-radius: 10px;
        color: #fff;
        padding: 20px;
        margin: 50px 0;
        h1 , h2 {
            font-size: 56px;
            line-height: 67.5px;
            font-weight: 700;
            margin: 0;
        }
        h2 {
            color: #F3B634;
        }
        h6 {
            font-size: 18.5px;
            line-height: 33.3px;
            font-family: 'Poppins', sans-serif !important;
            margin-top: 18px;
        }
    }
    .st-affiliate-top-banner-img {
        position: absolute;
        top: -10%;
    }
    button {
    box-shadow: none;
    border: none;
    padding: 18px 30px;
    border-radius: 30px;
    font-weight: 700;
    color: #fff;
    font-size: 18px;
    line-height: 20px;
    background-color: transparent;
    margin-top: 30px;
    font-family: 'Poppins', sans-serif !important;
    transition: 1s;
    cursor: pointer;
    }
    .affiliate-button-primary {
        background-color: #FF6571;
        margin-right: 30px;
        border: 1px solid #FF6571;
        &:hover {
            color: #FF6571;
            background-color: white;
        }
    }
    .affiliate-button-secondary {
        border: 1px solid #fff;
        &:hover {
            background-color: #fff;
            color: black;
        }
    }

    .st-affiliate-reasons-item {
        display: flex;
        padding : 20px;
        svg {
            margin-right : 30px;
        }
        .heading {
            font-size : 24px;
            font-weight : bold;
            margin-bottom: 10px;
        }
    }

    .affiliate-button-third {
        border: 3px solid black;
        color: black;
        padding-right: 10px;
        svg {
            margin-left : 10px;
            margin-bottom: 4px;
        }
        &:hover {
            background-color: black;
             color: white;
             svg {
                color: white;
            }
        }
    }
    .bottom-right-content {
        display: flex;
        flex-direction : column;
        justify-content : center;
        align-items : center;
        height: 100%;
        .Polaris-Text--heading3xl  {
            font-weight : 400
        }
    }

    img {
        max-width : 100%
    }

    .ml-10 {
        margin-left : 10px;
    }
    .mb-5 {
        margin-bottom : 5px !important;
    }
    .mt-20 {
        margin-top : 20px;
    }
    @media screen and (max-width: 991px) {
    .st-affiliate-top-banner-img {
        display: none;
        }
    }
    @media screen and (max-width: 760px) {
        .bottom-right-content {
            margin-top : 20px;
        }
    }
`