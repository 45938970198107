import styled from 'styled-components';

export const SettingsPageStyled = styled.div`
  .settings-tab-layout {
    background: #fff;
    border: 0;
    padding: 20px 22px;
    box-shadow: 3px 3px 8px 3px rgb(0 0 0 / 5%);
    border-radius: 15px;

    font-size: 16px;
    cursor: pointer;
    margin-top: 16px;
  }

  .settings-tab-layout.active {
    background: linear-gradient(135deg, #00b98b, #008060);
    color: #fff;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .Polaris-Icon--colorBase svg {
    fill: #000;
  }
  .active {
    .Polaris-Icon--colorBase svg {
      fill: #ffffff !important;
    }
  }
  .tab-title {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
    }
    .Polaris-Icon {
      margin: 0;
      margin-right: 10px;
    }
  }
`;
