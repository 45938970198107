import { Button, ButtonGroup, Modal, OptionList } from '@shopify/polaris';
import { ImportMinor } from '@shopify/polaris-icons';
import { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { IOrderTable } from '@/types/components';
import { apiCaller } from '@/redux/query';
import { LINK } from '@/constants/link';
import moment from 'moment';

const ExportOrders = ({ ...props }: IOrderTable.IExportOrderProps) => {
  const [trigger] = apiCaller.useLazyExportOrdersQuery();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([
    'user',
    'orderId',
    'orderNumber',
    'paymentGatewayName',
    'trackingNumber',
    'trackingCompany',
    'transactionId',
    'addTrackingStatus',
    'addTrackingAccount',
    'addTrackingAt',
    'processedAt',
    'createdAt',
  ]);
  const listFields = [
    { value: 'user', label: 'User' },
    { value: 'orderId', label: 'Order Id' },
    { value: 'orderNumber', label: 'Order Number' },
    { value: 'paymentGatewayName', label: 'Payment gateway names' },
    { value: 'trackingNumber', label: 'Tracking number' },
    { value: 'trackingCompany', label: 'Tracking company' },
    { value: 'transactionId', label: 'Transaction id' },
    { value: 'addTrackingStatus', label: 'Sync Status' },
    { value: 'addTrackingAccount', label: 'Add Tracking Account' },
    { value: 'addTrackingAt', label: 'Add Tracking At' },
    { value: 'processedAt', label: 'Processed At' },
    { value: 'createdAt', label: 'Created At' },
  ];
  const handleExportOrders = () => {
    trigger({
      fields: selectedFields.toString(),
      search: props.search[0],
      fromDate: moment(props.fromDate).format(),
      toDate: moment(props.toDate).format(),
      payment: props.payment[0],
      status: props.status[0],
      sort: props.sort,
      sortBy: props.sortBy,
    }).then((res) => {
      if (res.data) {
        window.open(res.data.url);
      }
      setIsOpenModal(false);
    });
  };
  return (
    <div>
      <Row className="p-16">
        <Col style={{ display: 'flex', alignItems: 'flex-end' }} className="ml-4">
          <div>
            <a className="export-order-document" href={LINK.syncStatus} target="_blank" rel="noreferrer">
              View Order Document
            </a>
          </div>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'flex-end' }} className="mr-4">
          <ButtonGroup segmented>
            <Button icon={ImportMinor} onClick={() => setIsOpenModal(true)}>
              Export
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <div className="float-right mr-4">
        <Modal
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          title="Export Options"
          primaryAction={{
            content: 'Export',
            onAction: handleExportOrders,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => setIsOpenModal(false),
            },
          ]}
        >
          <Modal.Section>
            <OptionList
              title="Field to export:"
              onChange={setSelectedFields}
              options={listFields}
              selected={selectedFields}
              allowMultiple
            />
          </Modal.Section>
        </Modal>
      </div>
    </div>
  );
};

export default ExportOrders;
