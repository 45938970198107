import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IOrderTable } from '@/types/components';
import { getSomeDaysAgo } from '@/helpers';

// Define the initial state using that type
const initialState = {
  listStore: [] as Array<string>,
  isOpenQuickHelp: false,
  orderTable: {
    currentPage: 1,
    search: '',
    fromDate: getSomeDaysAgo(7),
    toDate: new Date(),
    statusSelected: ['all'],
    paymentSelected: ['paypal'],
    perPage: ['10'],
    sort: 'DESC',
    sortBy: 'orderNumber',
  },
  titleBtnDatePicker: 'Last 7 days',
};

const homePageSlice = createSlice({
  name: 'homePage',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleOpenQuickHelp: (state, action: PayloadAction<boolean>) => {
      state.isOpenQuickHelp = action.payload;
    },
    handleFiltersOrderTable: (state, action: PayloadAction<IOrderTable.IState>) => {
      state.orderTable = action.payload;
    },
    handleListStore: (state, action: PayloadAction<Array<string>>) => {
      state.listStore = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const isOpenQuickHelpSelector = createSelector(
  (state: RootState) => state.homePage,
  (state) => state.isOpenQuickHelp,
);
export const orderTableHomePageSelector = createSelector(
  (state: RootState) => state.homePage,
  (state) => state.orderTable,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.homePage,
  (state) => state.titleBtnDatePicker,
);

export default homePageSlice;
