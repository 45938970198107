/* eslint-disable react/jsx-key */
import React from 'react';
import { IHomePage } from '@/types/components';
import { LINK } from '@/constants/link';

export const configQuickHelp: Array<IHomePage.IQuickHelpConfig> = [
  {
    title: 'Orders Pending',
    content: [
      <p> There are 2 reasons which lead to pending orders not synced to PayPal/Stripe</p>,
      <p>
        <span className="underline">Reason 1:</span> The system is processing synchronization which takes from 1 to 3 minutes.
        Please wait for few seconds
      </p>,
      <p>
        <span className="underline">Reason 2:</span> Your tracking numbers are not in the correct format. PayPal limits the amount
        of tracking number's letters, only accepts 25 letters. Therefore, if you use tracking link instead of tracking number,
        Synctrack would notice that this order is pending.
      </p>,
    ],
    solution: [
      <p>
        In case of seeing the orders are pending after 5 minutes syncing, contact us through live chat support and send us
        Transaction ID of the order. Our expert team will help to fix this issue ASAP.
      </p>,
    ],
    button: 'Contact us',
    keyValue: 'orderPending',
  },
  {
    title: 'Over Quota',
    content: [
      <p>Your plan is on .... This plan supports ... trackings/month.</p>,
      <p>
        This month your store has ... orders. Therefore, our app sucessfully synced ..... for you while ... orders are not
        supported because of over quota for this subscription.
      </p>,
    ],
    solution: [<p>Please upgrade to the above plans to make sure syncing process happens fluently without any issues.</p>],
    button: 'Upgrade now',
    keyValue: 'overQuota',
  },
  {
    title: 'Invalid Tracking Number',
    content: [<p>Tracking number is wrong format or tracking number is too long or contains special characters</p>],
    button: 'Contact us',
    keyValue: 'invalidTrackingNumber',
  },
  {
    title: 'No Account or Invalid',
    content: [
      <p>There are 2 reasons which lead to status of "No Account or Invalid</p>,
      <p>
        <span className="underline">Reason1:</span> You have not connected any PayPal/ Stripe account with Synctrack
      </p>,
    ],
    solution: [
      <p>
        Connect your PayPal/ Stripe with Synctrack. Make sure that your account is the correct one which you use for transactions
        from your Shopify store
      </p>,
      <div className="quick-help-line"></div>,
      <p>
        <span className="underline">Reason 2:</span> You have not connected any PayPal/ Stripe account with Synctrack
      </p>,
    ],
    solution2: [
      <p>
        Add Stripe token or Replace the new token by navigating to Settings/ Stripe settings and carefully read this instruction
        of how to get Stripe token{' '}
        <a
          href={LINK.getStripeToken}
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>,
    ],
    button: 'Contact us',
    keyValue: 'unconfirmAcc',
  },
  {
    title: 'No Tracking Number',
    content: [
      <p>There are 2 reasons which lead to orders having no tracking numbers and they can not synced to PayPal/Stripe</p>,
      <p>
        <span className="underline">Reason 1:</span> Your orders are fulfilled already but the couriers are not delivering those
        orders. Therefore, those orders are not provided with tracking numbers. As soon as the orders have tracking numbers from
        couriers, Synctrack automatically real-time syncs them to PayPal.
      </p>,
      <p>
        <span className="underline">Reason 2:</span> If you are not using any fullfilment app which automatically fulfills orders
        and provides tracking numbers for you on Shopify, you have to manually add tracking number to Shopify by yourself.
      </p>,
    ],
    solution: [
      <p>
        Wait for the fulfillment party provides tracking number or add tracking number on Shopify manually, click{' '}
        <a href={LINK.addTrackingNumberToOrder} target="_blank" rel="noreferrer">
          here
        </a>{' '}
        for the instruction.
      </p>,
    ],
    button: 'Contact us',
    keyValue: 'noTracking',
  },
  {
    title: 'No Transaction ID',
    content: [
      <p>There are 2 reasons which lead to orders having no transaction ID and they can not synced to PayPal/Stripe</p>,
      <p>
        <span className="underline">Reason 1:</span> You may be using a third - party app relating to checkout issues and this
        kind of app doens't return transaction ID.{' '}
      </p>,
      <p>
        Example: If you are using Checkify, Checkify doesn't return transaction ID, therefore, Synctrack need to integrate with
        Checkify to get transaction ID and process syncing to PayPal.
      </p>,
    ],
    solution: [
      <p>
        Go to tab Integration in Synctrack, check if Synctrack supports your third party app. If Synctrack doesn't support your
        third-party app, please contact us through livechat, we do it for free.
      </p>,
      <div className="quick-help-line"></div>,
      <p>
        <span className="underline">Reason 2:</span> Your customer's credit card has not enough funds. This transaction is going
        to be cancelled.
      </p>,
    ],
    solution2: [<p>Send an email to your customers in this case to remind them.</p>],
    button: 'Contact us',
    keyValue: 'noTransaction',
  },
  {
    title: 'Missing PayPal Account',
    content: [
      <p>
        Your PayPal/ Stripe account which is used for transactions from your Shopify store is not the same as the PayPal/ Stripe
        account connected with Synctrack.
      </p>,
    ],
    solution: [<p>Make sure you are connecting this PayPal account: </p>],
    button: 'Contact us',
    keyValue: 'invalidAccount',
  },
];
